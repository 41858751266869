import React, { useState, useEffect } from "react";
import ArgonTypography from "../../../components/ArgonTypography";
import ArgonButton from "../../../components/ArgonButton";
import InputFeild from "../../../components/Modal/InputFeild";
import { apiInstance } from "../../../axios/index";

import { Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const SellerModal = ({
  newModal,
  toggleAddModal,
  handleSubmit,
  code,
  setCode,
  selectedZone,
  setSelectedZone,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  password,
  setPassword,
  email,
  setEmail,
  mobile,
  setMobile,
  panNo,
  setPanNo,
  gstNo,
  setGstNo,
  address,
  setAddress,
  contact,
  setContact,
  file,
  setFile,
}) => {
  useEffect(() => {
    fetchZones();
  }, []);

  const [zones, setZones] = useState([]);

  const sellerModalfields = [
    {
      id: "seller_code",
      label: "Code",
      type: "text",
      value: code,
      onChange: (e) => setCode(e.target.value),
      required: true,
    },
    {
      id: "seller_firstname",
      label: "First Name",
      type: "text",
      value: firstName,
      onChange: (e) => setFirstName(e.target.value),
      required: true,
    },
    {
      id: "seller_lastname",
      label: "Last Name",
      type: "text",
      value: lastName,
      onChange: (e) => setLastName(e.target.value),
      required: true,
    },
    {
      id: "seller_email",
      label: "Email / Username",
      type: "email",
      value: email,
      onChange: (e) => setEmail(e.target.value),
      required: true,
    },
    {
      id: "seller_password",
      label: "Password",
      type: "password",
      value: password,
      onChange: (e) => setPassword(e.target.value),
      required: false,
    },
    {
      id: "seller_contact",
      label: "Contact Person",
      type: "text",
      value: contact,
      onChange: (e) => setContact(e.target.value),
      required: true,
    },
    {
      id: "seller_mobile",
      label: "Mobile",
      type: "number",
      value: mobile,
      onChange: (e) => setMobile(e.target.value),
      required: true,
    },
    {
      id: "seller_pan",
      label: "PAN No.",
      type: "text",
      value: panNo,
      onChange: (e) => setPanNo(e.target.value),
      required: true,
    },
    {
      id: "seller_gst",
      label: "GST No.",
      type: "text",
      value: gstNo,
      onChange: (e) => setGstNo(e.target.value),
      required: true,
    },
    {
      id: "seller_address",
      label: "Address",
      type: "text",
      value: address,
      className: "seller_address",
      onChange: (e) => setAddress(e.target.value),
      required: true,
      multiline: true,
    },

    {
      id: "seller_file",
      label: "GST Certificate",
      type: "file",
      className: "seller_file",
      onChange: (e) => setFile(e.target.value),
      required: false,
    },
  ];

  // Retrieve zones data
  const fetchZones = async () => {
    try {
      const response = await apiInstance.post("/api/GetZone", { id: 0 });

      if (response.data.status === true) {
        const zoneData = response.data.Data.map((zone) => ({
          Id: zone.Id,
          zoneName: zone.Zone,
        }));

        setZones(zoneData);
      } else {
        console.error("Error: " + response.data.Message);
      }
    } catch (error) {
      console.error("Error fetching zone:", error);
    }
  };

  return (
    <>
      <Modal isOpen={newModal} toggle={toggleAddModal} className="modal-lg new_modal" centered>
        <ModalHeader toggle={toggleAddModal} className="userTable_header">
          <ArgonTypography color="text" fontWeight="medium" variant="d2">
            Add / Edit Seller
          </ArgonTypography>
        </ModalHeader>

        <form className="needs-validation new_form" id="form_details" onSubmit={handleSubmit}>
          <ModalBody>
            <div className="form-row seller_modal">
              <Row>
                <div className="select_con col-md-6">
                  <ArgonTypography color="text" fontWeight="medium" variant="caption">
                    Zone
                  </ArgonTypography>
                  {zones && (
                    <select
                      className="pagination_select"
                      value={selectedZone}
                      onChange={(e) => setSelectedZone(e.target.value)}
                    >
                      {zones.map((zone) => (
                        <option value={zone.Id} key={zone.Id}>
                          {zone.zoneName}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
                {sellerModalfields &&
                  sellerModalfields.map((field) => {
                    return <InputFeild field={field} key={field.id} />;
                  })}
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <ArgonButton color="success" size="medium" type="submit" className="">
              Submit
            </ArgonButton>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default SellerModal;
