import React, { useState, useEffect } from "react";
import ArgonTypography from "../../components/ArgonTypography";
import ArgonButton from "../../components/ArgonButton";
import InputFeild from "../../components/Modal/InputFeild";
import { apiInstance } from "../../axios/index";

import { Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Loader from "components/loader/Loader";

const ProductModal = ({
  newModal,
  toggleAddModal,
  handleSubmit,
  selectedCategory,
  setSelectedCategory,
  status,
  setStatus,
  skuId,
  setSkuId,
  name,
  setName,
  color,
  setColor,
  qty,
  setQty,
  buyPrice,
  setBuyPrice,
  mrpPrice,
  setMrpPrice,
}) => {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchCategories();
  }, []);

  const productModalfields = [
    {
      id: "product_sku",
      label: "Sku",
      type: "text",
      value: skuId,
      onChange: (e) => setSkuId(e.target.value),
      required: true,
    },
    {
      id: "product_name",
      label: "Name",
      type: "text",
      value: name,
      onChange: (e) => setName(e.target.value),
      required: true,
    },
    {
      id: "product_color",
      label: "Color",
      type: "text",
      value: color,
      onChange: (e) => setColor(e.target.value),
      required: true,
    },
    {
      id: "product_qty",
      label: "Qty",
      type: "number",
      value: qty,
      onChange: (e) => setQty(e.target.value),
      required: true,
    },
    {
      id: "product_buyPrice",
      label: "Buy Price",
      type: "number",
      value: buyPrice,
      onChange: (e) => setBuyPrice(e.target.value),
      required: true,
    },
    {
      id: "product_mrp",
      label: "MRP",
      type: "number",
      value: mrpPrice,
      onChange: (e) => setMrpPrice(e.target.value),
      required: true,
    },
  ];

  // Retrieve category data
  const fetchCategories = async () => {
    try {
      const response = await apiInstance.post("/api/GetCategory", { id: 0 });

      if (response.data.status === true) {
        const categoryData = response.data.Data.map((category) => ({
          Id: category.Id,
          Name: category.Name,
          Status: category.Status,
        }));

        const enableCategories = categoryData.filter((category) => category.Status === "Enable");

        setCategories(enableCategories);
      } else {
        console.error("Error: " + response.data.Message);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching Category:", error);
      setIsLoading(false);
    }
  };

  const handleCategoryChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedCategory(selectedValue);
  };

  console.log("selectedCategory" + selectedCategory);

  return (
    <>
      {isLoading && <Loader />}
      <Modal
        isOpen={newModal && !isLoading}
        toggle={toggleAddModal}
        className="modal-lg new_modal"
        centered
      >
        <ModalHeader toggle={toggleAddModal} className="userTable_header">
          <ArgonTypography color="text" fontWeight="medium" variant="d2">
            Add / Edit Product
          </ArgonTypography>
        </ModalHeader>
        <form className="needs-validation new_form" id="form_details" onSubmit={handleSubmit}>
          <ModalBody>
            <div className="form-row seller_modal">
              <Row>
                <div style={{ marginTop: 0 }} className="select_con col-md-6">
                  <label htmlFor="prdocut_status">
                    <ArgonTypography color="text" fontWeight="medium" variant="caption">
                      Status
                    </ArgonTypography>
                  </label>
                  <select
                    id="prdocut_status"
                    className="pagination_select"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="" disabled>
                      -- Select Status --
                    </option>
                    <option value="Enable">Enable</option>
                    <option value="Disable">Disable</option>
                  </select>
                </div>
                <div className="select_con col-md-6">
                  <ArgonTypography color="text" fontWeight="medium" variant="caption">
                    Category
                  </ArgonTypography>
                  <select
                    className="pagination_select"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                  >
                    <option value="" disabled>
                      -- Select Category --
                    </option>
                    {categories.map((category) => {
                      return (
                        <option value={category.Id} key={category.Id}>
                          {category.Name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {productModalfields &&
                  productModalfields.map((field) => {
                    return <InputFeild field={field} key={field.id} />;
                  })}
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <ArgonButton color="success" size="medium" type="submit" className="">
              Save
            </ArgonButton>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default ProductModal;
