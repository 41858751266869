import React from "react";
import ArgonBox from "../../components/ArgonBox";
import ArgonTypography from "../../components/ArgonTypography";
import ArgonInput from "../../components/ArgonInput";

const inputFeild = ({ field, zoneField, inwardField }) => {
  return (
    <>
      {field && (
        <div className={`col-md-6 ${field.className}`}>
          <ArgonBox mb={2}>
            <label htmlFor={field.id}>
              <ArgonTypography color="text" fontWeight="medium" variant="caption">
                {field.label}
              </ArgonTypography>
            </label>
            <ArgonInput
              size="medium"
              id={field.id}
              placeholder={field.label}
              type={field.type}
              className="form-control form-control-alternative"
              aria-invalid="false"
              value={field.value}
              multiline={field.multiline}
              onChange={field.onChange}
              required={field.required}
            />
          </ArgonBox>
        </div>
      )}

      {zoneField && (
        <div className="mb-3">
          <ArgonBox mb={2}>
            <label htmlFor={zoneField.id}>
              <ArgonTypography color="text" fontWeight="medium" variant="caption">
                {zoneField.label}
              </ArgonTypography>
            </label>
            <ArgonInput
              size="medium"
              id={zoneField.id}
              placeholder={zoneField.label}
              type={zoneField.type}
              className="form-control form-control-alternative"
              aria-invalid="false"
              value={zoneField.value}
              onChange={zoneField.onChange}
              required={zoneField.required}
            />
          </ArgonBox>
        </div>
      )}

      {inwardField && (
        <div className="col-md-6">
          <ArgonBox mb={2}>
            <label htmlFor={inwardField.id}>
              <ArgonTypography color="text" fontWeight="medium" variant="caption">
                {inwardField.label}
              </ArgonTypography>
            </label>
            <ArgonInput
              size="large"
              id={inwardField.id}
              placeholder={inwardField.label}
              type={inwardField.type}
              className="form-control form-control-alternative"
              aria-invalid="false"
              value={inwardField.value}
              onChange={inwardField.onChange}
              required={inwardField.required}
            />
          </ArgonBox>
        </div>
      )}
    </>
  );
};

export default inputFeild;
