import React, { useState, useEffect } from "react";
import ArgonTypography from "../../../components/ArgonTypography";

import { Modal, ModalHeader, ModalBody, Table, Card, CardBody } from "reactstrap";
import TableHead from "../../../components/Table/TableHead";

const tableHeadData = [
  {
    id: 1,
    header: "No.",
  },
  {
    id: 4,
    header: "Sku",
  },
  {
    id: 5,
    header: "Product Name",
  },
  {
    id: 6,
    header: "Qty",
  },
  {
    id: 7,
    header: "Price",
  },
  {
    id: 8,
    header: "Amount",
  },
  {
    id: 9,
    header: "ApprovedQty",
  },
];

const OrderModal = ({ selectedOrderNumber, modal, closeModal, selectedDate, orderProductData }) => {
  const handleCloseModal = () => {
    closeModal();
  };

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={handleCloseModal}
        className="modal-xl inward_modal"
        centered
        style={{ minWidth: "90%" }}
      >
        <Card>
          <ModalHeader toggle={handleCloseModal} className="inwardModal_header">
            <ArgonTypography color="dark" variant="button" fontWeight="medium">
              1 Number: {selectedOrderNumber}
            </ArgonTypography>
            <ArgonTypography color="dark" variant="button" fontWeight="medium">
              Date: {selectedDate}
            </ArgonTypography>
          </ModalHeader>
          <CardBody className="inward_modal_card_body">
            <ModalBody>
              <Table className="table-flush new_table" responsive hover>
                {orderProductData.length > 0 ? (
                  <>
                    <TableHead tableHeadData={tableHeadData} />
                    <tbody style={{ maxHeight: "80vh", overflowY: "auto" }}>
                      {orderProductData.map((order, index) => {
                        return (
                          <tr key={order.Id} className="inward_modal_table">
                            <td>
                              <ArgonTypography color="dark" variant="caption">
                                {index + 1}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography color="dark" variant="caption">
                                {order.Sku}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography color="dark" variant="caption">
                                {order.ProductName}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography color="dark" variant="caption">
                                {order.Qty}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography color="dark" variant="caption">
                                {order.Price}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography color="dark" variant="caption">
                                {order.Amount}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography color="dark" variant="caption">
                                {order.ApprovedQty}
                              </ArgonTypography>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </>
                ) : (
                  <thead>
                    <tr>
                      <td style={{ textAlign: "center" }}>
                        <ArgonTypography variant="button" color="dark" fontWeight="bold">
                          No Data Available
                        </ArgonTypography>
                      </td>
                    </tr>
                  </thead>
                )}
              </Table>
            </ModalBody>
          </CardBody>
        </Card>
      </Modal>
    </>
  );
};

export default OrderModal;
