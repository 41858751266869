import React, { useState, useEffect, useCallback, useRef } from "react";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import NewPagination from "../../../components/Pagination/NewPagination";
import Loader from "../../../components/loader/Loader";
import { apiInstance } from "../../../axios/index";
import Notification, { notify } from "../../../components/notification/Notification";

import { Card, Container, CardBody, CardFooter } from "reactstrap";
import CardHead from "../../../components/Card/CardHead";
import AdminOrderTable from "./AdminOrderTable";
import AdminOrderModal from "./AdminOrderModal";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

const AdminOrder = () => {
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [modal, setModal] = useState(false);
  const [selectedModalId, setSelectedModalId] = useState(null);
  const [selectedOrderNumber, setSelectedOrderNumber] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderProductData, setOrderProductData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const openFilter = Boolean(anchorEl);
  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    fetchOrderData();
  }, []);

  const navigate = useNavigate();

  // search functionality
  const handleSearch = (e) => {
    const searchString = e.target.value.toLowerCase();
    const searchResults = orderData.filter(
      ({
        Seller,
        OrderNumber,
        OrderDate,
        Qty,
        Amount,
        CreatedByUser,
        CustomerName,
        CustomerMobile,
        CustomerAddress,
        UserType,
        Warehouse,
        Status,
      }) =>
        (Seller ?? "").toLowerCase().includes(searchString) ||
        (OrderNumber ? OrderNumber.toString() : "").includes(searchString) ||
        (OrderDate ? OrderDate.toString() : "").includes(searchString) ||
        (Qty ? Qty.toString() : "").includes(searchString) ||
        (Amount ? Amount.toString() : "").includes(searchString) ||
        (CreatedByUser ?? "").toLowerCase().includes(searchString) ||
        (CustomerName ?? "").toLowerCase().includes(searchString) ||
        (CustomerMobile ? CustomerMobile.toString() : "").includes(searchString) ||
        (CustomerAddress ?? "").toLowerCase().includes(searchString) ||
        (UserType ?? "").toLowerCase().includes(searchString) ||
        (Warehouse ?? "").toLowerCase().includes(searchString) ||
        (Status ?? "").toLowerCase().includes(searchString)
    );
    setFilterData(searchResults);
    setCurrentPage(1);
  };

  // Fetch Order Data
  const fetchOrderData = async () => {
    setLoading(true);
    try {
      const response = await apiInstance.post("/SaleOrder/Get", {
        Id: 0,
      });
      if (response.data.status === true) {
        const orderData = response.data.Data.map((fetchOrder) => ({
          Id: fetchOrder.Id,
          Seller: fetchOrder.Seller,
          OrderNumber: fetchOrder.OrderNumber,
          OrderDate: fetchOrder.OrderDate,
          Qty: fetchOrder.Qty,
          Amount: fetchOrder.Amount,
          CreatedByUser: fetchOrder.CreatedByUser,
          CustomerAddress: fetchOrder.CustomerAddress,
          CustomerMobile: fetchOrder.CustomerMobile,
          CustomerName: fetchOrder.CustomerName,
          Status: fetchOrder.Status,
          UserType: fetchOrder.UserType,
          Warehouse: fetchOrder.Warehouse,
        }));
        setFilterData(orderData);
        setOrderData(orderData);
      } else {
        console.error("Error: " + response.data.Message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notify("Your session just expired!", "error");
        setTimeout(() => {
          navigate("/authentication/logout");
        }, 5000);
      } else {
        console.error(error);
      }
    }
    setLoading(false);
  };

  const toggleDetailsModal = useCallback((Id, OrderNumber, OrderDate) => {
    setSelectedModalId(Id);
    setSelectedOrderNumber(OrderNumber);
    setSelectedDate(OrderDate);
    setModal((prevModal) => !prevModal);
  });

  const closeModal = useCallback(() => {
    setModal(false);
    setSelectedModalId(null);
    setSelectedOrderNumber(null);
    setSelectedDate(null);
  }, []);

  useEffect(() => {
    fetchOrderProductData();
  }, [selectedModalId]);

  // Fetch Order Product Data
  const fetchOrderProductData = async () => {
    setLoading(true);
    try {
      const response = await apiInstance.post("/SaleOrder/GetSalesOrderProduct", {
        orderId: selectedModalId,
      });
      if (response.data.status === true) {
        const orderProductData = response.data.Data.map((orderProduct) => ({
          Id: orderProduct.Id,
          ProductId: orderProduct.ProductId,
          Sku: orderProduct.Sku,
          ProductName: orderProduct.ProductName,
          Qty: orderProduct.Qty,
          Price: orderProduct.Price,
          Amount: orderProduct.Amount,
          ApprovedQty: orderProduct.ApprovedQty,
        }));
        setOrderProductData(orderProductData);
      } else {
        console.error("Error: " + response.data.Message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notify("Your session just expired!", "error");
        setTimeout(() => {
          navigate("/authentication/logout");
        }, 5000);
      } else {
        console.error(error);
      }
    }
    setLoading(false);
  };

  // for Pagination
  const totalItems = filterData ? filterData.length : 0;

  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);
  const orderFilterData = filterData.slice(startIndex - 1, endIndex);

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(parseInt(value));
    setCurrentPage(1);
  };

  const productPdf = useRef();

  const generateProductPdf = useReactToPrint({
    content: () => productPdf.current,
    documentTitle: "Product Data",
  });

  return (
    <>
      <Notification />
      {loading && <Loader />}
      <DashboardLayout>
        <DashboardNavbar />
        <Container className="mt--7 adminOrder_con con" fluid>
          <Card className="shadow">
            <CardHead
              cardHeader="Order List"
              handleSearch={handleSearch}
              openFilter={openFilter}
              handleFilterClick={handleFilterClick}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              filterData={filterData}
              setFilterData={setFilterData}
              fetchOrderData={fetchOrderData}
            />
            <CardBody className="table_card_body">
              <div ref={productPdf}>
                <AdminOrderTable
                  orderFilterData={orderFilterData}
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  toggleDetailsModal={toggleDetailsModal}
                  fetchOrderData={fetchOrderData}
                  orderProductData={orderProductData}
                  generateProductPdf={generateProductPdf}
                />
              </div>
            </CardBody>
            <CardFooter>
              <NewPagination
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                handleItemsPerPageChange={handleItemsPerPageChange}
                itemsPerPageLabel="Order Items Per Page:"
              />
            </CardFooter>
          </Card>
        </Container>
      </DashboardLayout>
      <AdminOrderModal
        modal={modal}
        orderProductData={orderProductData}
        closeModal={closeModal}
        selectedOrderNumber={selectedOrderNumber}
        selectedDate={selectedDate}
        fetchOrderProductData={fetchOrderProductData}
        productPdf={productPdf}
      />
    </>
  );
};

export default AdminOrder;
