import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    Cookies.remove("theKey", { path: "/" });
    Cookies.remove("FullName", { path: "/" });
    Cookies.remove("Type", { path: "/" });
    Cookies.remove("Id", { path: "/" });
    navigate("/authentication/sign-in");
  }, [navigate]);

  return null;
};

export default Logout;
