import React from "react";
import ArgonTypography from "../../../components/ArgonTypography";
import TableHead from "../../../components/Table/TableHead";
import AdminOrderTableRow from "./AdminOrderTableRow";
import { Table } from "reactstrap";

const tableHeadData = [
  {
    id: 1,
    header: "No.",
  },
  {
    id: 3,
    header: "Seller",
  },
  {
    id: 4,
    header: "Order Number",
  },
  {
    id: 5,
    header: "Order Date",
  },
  {
    id: 6,
    header: "Qty",
  },
  {
    id: 7,
    header: "Amount",
  },
  {
    id: 8,
    header: "Created By",
  },
  {
    id: 9,
    header: "Customer Name",
  },
  {
    id: 10,
    header: "Customer Mobile",
  },
  {
    id: 11,
    header: "Customer Address",
  },
  {
    id: 12,
    header: "User Type",
  },
  {
    id: 13,
    header: "Warehouse",
  },
  {
    id: 14,
    header: "Status",
  },
  {
    id: 15,
    header: "",
  },
];

const AdminOrderTable = ({
  orderFilterData,
  currentPage,
  itemsPerPage,
  toggleDetailsModal,
  fetchOrderData,
  orderProductData,
  generateProductPdf,
}) => {
  return (
    <>
      <Table className="table-flush new_table" responsive hover>
        {orderFilterData.length > 0 ? (
          <>
            <TableHead tableHeadData={tableHeadData} />
            <tbody>
              {orderFilterData.map((order, index) => {
                const tableIndex = (currentPage - 1) * itemsPerPage + index;
                return (
                  <AdminOrderTableRow
                    key={order.Id}
                    tableIndex={tableIndex}
                    order={order}
                    toggleDetailsModal={toggleDetailsModal}
                    fetchOrderData={fetchOrderData}
                    generateProductPdf={generateProductPdf}
                  />
                );
              })}
            </tbody>
          </>
        ) : (
          <thead>
            <tr>
              <th>
                <ArgonTypography variant="caption" color="secondary" fontWeight="bold">
                  No Data Available
                </ArgonTypography>
              </th>
            </tr>
          </thead>
        )}
      </Table>
    </>
  );
};

export default AdminOrderTable;
