import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import NewPagination from "../../../components/Pagination/NewPagination";
import Loader from "../../../components/loader/Loader";
import LoadingSkeleton from "../../../components/loader/LoadingSkeleton";
import SellerModal from "./SellerModal";
import Form_Modal from "../../../components/Modal/Form_Modal";

import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import { green, red } from "@mui/material/colors";
import { apiInstance } from "../../../axios/index";
import Notification, { notify } from "../../../components/notification/Notification";
import ArgonTypography from "../../../components/ArgonTypography";

import { Card, CardFooter, Container, Table, CardBody } from "reactstrap";
import TableHead from "../../../components/Table/TableHead";
import CardHead from "../../../components/Card/CardHead";
import { useNavigate } from "react-router-dom";

const tableHeadData = [
  {
    id: 0,
    header: "No.",
  },
  {
    id: 1,
    header: "Code",
  },
  {
    id: 2,
    header: "First Name",
  },
  {
    id: 3,
    header: "Last Name",
  },
  {
    id: 5,
    header: "Email / Username ",
  },
  {
    id: 6,
    header: "Mobile",
  },
  {
    id: 8,
    header: "GstNo.",
  },
  {
    id: 10,
    header: "Contact",
  },
  {
    id: 11,
    header: "GST Certificate",
  },
  {
    id: 12,
    header: "",
  },
];

const Seller = () => {
  const [loading, setLoading] = useState(false);
  const [newModal, setNewModal] = useState(false);
  const [sellers, setSellers] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [editSeller, setEditSeller] = useState(null);
  const [code, setCode] = useState("");
  const [selectedZone, setSelectedZone] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [panNo, setPanNo] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [address, setAddress] = useState("");
  const [contact, setContact] = useState("");
  const [file, setFile] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  useEffect(() => {
    fetchSellers();
  }, []);

  const navigate = useNavigate();

  // search functionality
  const handleSearch = (e) => {
    const searchString = e.target.value.toLowerCase();
    const searchResults = sellers.filter(
      ({ Firstname, Lastname, Email, GstNo, Mobile, ContactPerson }) =>
        (Firstname ?? "").toLowerCase().includes(searchString) ||
        (Lastname ?? "").toLowerCase().includes(searchString) ||
        (Email ?? "").toLowerCase().includes(searchString) ||
        (GstNo ?? "").toLowerCase().includes(searchString) ||
        (Mobile ?? "").toLowerCase().includes(searchString) ||
        (ContactPerson ?? "").toLowerCase().includes(searchString)
    );
    setFilterData(searchResults);
    setCurrentPage(1);
  };

  // Delete Modal
  const toggleDeleteModal = (Id) => {
    setDeleteModalOpen(!deleteModalOpen);
    Id && setDeleteItemId(Id);
    console.log(Id);
  };

  // opening or closeing a modal
  const toggleAddModal = () => {
    setCode("");
    setFirstName("");
    setLastName("");
    setPassword("");
    setEmail("");
    setMobile("");
    setPanNo("");
    setGstNo("");
    setAddress("");
    setContact("");
    setFile("");
    setSelectedZone("");
    setNewModal(!newModal);
    setEditSeller(null);
  };

  // submitting a form
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if mobile number is valid
    const isValidMobile = /^\d{10}$/.test(mobile);
    if (!isValidMobile) {
      notify("Invalid Mobile Number", "error");
      console.error("Invalid mobile number");
      return;
    }

    const isValidPAN = /^([A-Z]{3})([ABCFGHLJPTK][A-Z])(\d{4})([A-Z])$/.test(panNo);
    if (!isValidPAN) {
      notify("Invalid PAN Number", "error");
      console.error("Invalid PAN number");
      return;
    }

    const isValidGST =
      /^([0-9]{2})([A-Z]{5})([0-9]{4})([A-Z]{1})([0-9]{1})([A-Z]{1})([A-Z\d]{1})$/.test(gstNo);
    if (!isValidGST) {
      notify("Invalid GST Number", "error");
      console.error("Invalid GST number");
      return;
    }

    // create user object
    const seller = {
      Id: 0,
      Code: code,
      Firstname: firstName,
      Lastname: lastName,
      Password: password,
      Email: email,
      Mobile: mobile,
      PanNo: panNo,
      GstNo: gstNo,
      Address: address,
      ContactPerson: contact,
      GstCertificateFile: file,
      Zone: selectedZone,
    };

    try {
      if (editSeller) {
        await apiInstance.post(`/api/SaveSeller`, {
          Id: editSeller.Id,
          Code: code,
          Firstname: firstName,
          Lastname: lastName,
          Password: password,
          Email: email,
          Mobile: mobile,
          PanNo: panNo,
          GstNo: gstNo,
          Address: address,
          ContactPerson: contact,
          GstCertificateFile: file,
          Zone: selectedZone,
        });
        notify("Seller Updated successfully", "success");
      } else {
        await apiInstance.post(`/api/SaveSeller`, {
          Id: seller.Id,
          Code: seller.Code,
          Firstname: seller.Firstname,
          Lastname: seller.Lastname,
          Password: seller.Password,
          Email: seller.Email,
          Mobile: seller.Mobile,
          PanNo: seller.PanNo,
          GstNo: seller.GstNo,
          Address: seller.Address,
          ContactPerson: seller.ContactPerson,
          GstCertificateFile: seller.GstCertificateFile,
        });
        notify("Seller Created successfully", "success");
      }

      // Clear form fields and close modal
      setCode("");
      setFirstName("");
      setLastName("");
      setPassword("");
      setEmail("");
      setMobile("");
      setPanNo("");
      setGstNo("");
      setAddress("");
      setContact("");
      setFile("");
      setSelectedZone("");
      setEditSeller(null);
      setNewModal(false);

      // Fetch updated user data
      fetchSellers();
    } catch (error) {
      console.error("Error saving seller:", error.response);
    }
  };

  // Retrieve sellers data
  const fetchSellers = async () => {
    setLoading(true);
    try {
      const response = await apiInstance.post("/api/GetSeller", {
        id: 0,
      });

      if (response.data.status === true) {
        const sellerData = response.data.Data.map((seller) => ({
          Id: seller.Id,
          Code: seller.Code,
          Firstname: seller.Firstname,
          Lastname: seller.Lastname,
          Password: seller.Password,
          Email: seller.Email,
          Mobile: seller.Mobile,
          PanNo: seller.PanNo,
          GstNo: seller.GstNo,
          Address: seller.Address,
          ContactPerson: seller.ContactPerson,
          GstCertificateFile: seller.GstCertificateFile,
        }));
        setSellers(sellerData);
        setFilterData(sellerData);
      } else {
        console.error("Error: " + response.data.Message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notify("Your session just expired!", "error");
        setTimeout(() => {
          navigate("/authentication/logout");
        }, 5000);
      } else {
        console.error(error);
      }
    }
    setLoading(false);
  };

  // editing a data
  const handleEdit = (editSeller) => {
    setFirstName(editSeller.Firstname);
    setCode(editSeller.Code);
    setLastName(editSeller.Lastname);
    setPassword(editSeller.Password);
    setEmail(editSeller.Email);
    setMobile(editSeller.Mobile);
    setPanNo(editSeller.PanNo);
    setGstNo(editSeller.GstNo);
    setAddress(editSeller.Address);
    setContact(editSeller.ContactPerson);
    setFile(editSeller.GstCertificateFile);
    setEditSeller(editSeller);
    setNewModal(true);
  };

  // deleting a data
  const handleDelete = async (e) => {
    e.preventDefault();
    const sellerId = deleteItemId;
    console.log(sellerId);
    try {
      await apiInstance.post(`/api/DeleteSeller`, {
        id: sellerId,
      });
      notify("Seller Deleted successfully", "success");
    } catch (error) {
      console.error("Error deleting Seller:", error);
      notify("Error Deleting Seller", "error");
    }
    setDeleteModalOpen(!deleteModalOpen);
    fetchSellers();
    setDeleteItemId(null);
  };

  // for Pagination
  const totalItems = filterData ? filterData.length : 0;

  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);
  const displayedSeller = filterData.slice(startIndex - 1, endIndex);

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(parseInt(value));
    setCurrentPage(1);
  };

  return (
    <>
      <Notification />
      {loading && <Loader />}
      <DashboardLayout>
        <DashboardNavbar />
        <Container className="mt--7 seller_con con" fluid>
          <Card className="shadow">
            <CardHead
              toggleAddModal={toggleAddModal}
              handleSearch={handleSearch}
              cardHeader="Seller"
            />
            <CardBody className="table_card_body">
              {loading ? (
                <LoadingSkeleton />
              ) : (
                <Table className="align-items-center table-flush new_table" hover responsive>
                  <TableHead tableHeadData={tableHeadData} />
                  <tbody>
                    {displayedSeller &&
                      displayedSeller.map((tableSeller, i) => {
                        const index3 = (currentPage - 1) * itemsPerPage + i;
                        return (
                          <tr key={index3}>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {index3 + 1}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableSeller.Code}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableSeller.Firstname}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableSeller.Lastname}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableSeller.Email}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableSeller.Mobile}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableSeller.GstNo}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableSeller.ContactPerson}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableSeller.GstCertificateFile}
                              </ArgonTypography>
                            </td>
                            <td className="usertable_buttons">
                              <button className="btn edit_btn">
                                <CreateIcon
                                  onClick={() => handleEdit(tableSeller)}
                                  fontSize="small"
                                  sx={{ color: green[500] }}
                                />
                              </button>
                              <button className="btn delete_btn">
                                <DeleteIcon
                                  onClick={() => toggleDeleteModal(tableSeller.Id)}
                                  fontSize="small"
                                  sx={{ color: red[600] }}
                                />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              )}
            </CardBody>
            <CardFooter>
              <NewPagination
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                handleItemsPerPageChange={handleItemsPerPageChange}
                itemsPerPageLabel="Sellers Per Page:"
              />
            </CardFooter>
          </Card>
        </Container>
      </DashboardLayout>
      {deleteModalOpen && (
        <Form_Modal
          handleDelete={handleDelete}
          deleteModalOpen={deleteModalOpen}
          toggleDeleteModal={toggleDeleteModal}
          modalDesc="Are you sure you want to Delete?"
          callToAction="Delete"
        />
      )}
      {newModal && (
        <SellerModal
          newModal={newModal}
          toggleAddModal={toggleAddModal}
          handleSubmit={handleSubmit}
          code={code}
          setCode={setCode}
          selectedZone={selectedZone}
          setSelectedZone={setSelectedZone}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          password={password}
          setPassword={setPassword}
          email={email}
          setEmail={setEmail}
          mobile={mobile}
          setMobile={setMobile}
          panNo={panNo}
          setPanNo={setPanNo}
          gstNo={gstNo}
          setGstNo={setGstNo}
          address={address}
          setAddress={setAddress}
          contact={contact}
          setContact={setContact}
          file={file}
          setFile={setFile}
        />
      )}
    </>
  );
};

export default Seller;
