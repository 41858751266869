import React, { useState } from "react";
import ArgonTypography from "../../../components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

import { Modal, ModalHeader, ModalBody, Table, Card, CardBody } from "reactstrap";
import TableHead from "../../../components/Table/TableHead";
import AdminOrderModalRow from "./AdminOrderModalRow";

const tableHeadData = [
  {
    id: 1,
    header: "No.",
  },
  {
    id: 4,
    header: "Sku",
  },
  {
    id: 5,
    header: "Product Name",
  },
  {
    id: 6,
    header: "Qty",
  },
  {
    id: 7,
    header: "Price",
  },
  {
    id: 8,
    header: "Amount",
  },
  {
    id: 9,
    header: "ApprovedQty",
  },
];

const AdminOrderModal = ({
  selectedOrderNumber,
  modal,
  closeModal,
  selectedDate,
  orderProductData,
  fetchOrderProductData,
  productPdf,
}) => {
  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const [qtyinput, setQtyinput] = useState(Array(orderProductData.length).fill(""));

  const verifyConfirmModal = () => {
    setSubmitModalOpen(!submitModalOpen);
  };
  const handleCloseModal = () => {
    closeModal();
  };

  const handleInputChange = (e, index, order) => {
    const newValue = e.target.value;
    const updatedOrderQtys = [...qtyinput];
    const maxQty = order.Qty;

    if (newValue === "" || (Number(newValue) >= 0 && Number(newValue) <= maxQty)) {
      updatedOrderQtys[index] = newValue === "" ? "" : Number(newValue);
    }

    setQtyinput(updatedOrderQtys);
  };

  const allInputsFilled = qtyinput.every((value) => value === "");

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={handleCloseModal}
        className="modal-xl inward_modal"
        centered
        style={{ minWidth: "90%" }}
      >
        <Card>
          <ModalHeader toggle={handleCloseModal} className="inwardModal_header">
            <ArgonTypography color="dark" variant="button" fontWeight="medium">
              Order Number: {selectedOrderNumber}
            </ArgonTypography>
            <ArgonTypography color="dark" variant="button" fontWeight="medium">
              Date: {selectedDate}
            </ArgonTypography>
          </ModalHeader>
          <CardBody className="inward_modal_card_body">
            <ModalBody>
              <Table className="table-flush new_table" responsive hover>
                {orderProductData.length > 0 ? (
                  <>
                    <TableHead tableHeadData={tableHeadData} />
                    <tbody style={{ maxHeight: "80vh", overflowY: "auto" }}>
                      {orderProductData.map((order, index) => {
                        return (
                          <AdminOrderModalRow
                            key={order.Id}
                            order={order}
                            index={index}
                            fetchOrderProductData={fetchOrderProductData}
                            orderProductData={orderProductData}
                            verifyConfirmModal={verifyConfirmModal}
                            submitModalOpen={submitModalOpen}
                            setSubmitModalOpen={setSubmitModalOpen}
                            handleInputChange={(e) => handleInputChange(e, index, order)}
                            qtyinput={qtyinput}
                            setQtyinput={setQtyinput}
                          />
                        );
                      })}
                    </tbody>
                  </>
                ) : (
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "center" }}>
                        <ArgonTypography variant="button" color="dark" fontWeight="bold">
                          No Data Available
                        </ArgonTypography>
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <ArgonButton
                  color="success"
                  type="button"
                  onClick={verifyConfirmModal}
                  disabled={allInputsFilled}
                >
                  Approve
                </ArgonButton>
              </div>
            </ModalBody>
          </CardBody>
        </Card>
      </Modal>
    </>
  );
};

export default AdminOrderModal;
