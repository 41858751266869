import React, { useState, useEffect } from "react";
import Notification, { notify } from "../../../components/notification/Notification";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import NewPagination from "../../../components/Pagination/NewPagination";
import Loader from "../../../components/loader/Loader";
import LoadingSkeleton from "../../../components/loader/LoadingSkeleton";
import TableHead from "../../../components/Table/TableHead";
import CardHead from "../../../components/Card/CardHead";
import { apiInstance } from "../../../axios/index";

import { Card, CardFooter, Container, Table, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";

import { MdIndeterminateCheckBox } from "react-icons/md";
import { FaTruckArrowRight } from "react-icons/fa6";
import AddOrderTableRow from "./AddOrderTableRow";
import AdminSellModal from "./AdminSellModal";

const AdminAddOrder = () => {
  const [loading, setLoading] = useState(false);
  const [adminProducts, setAdminPRoducts] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [checkvalues, setCheckValues] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [sellModal, setSellModal] = useState(false);

  useEffect(() => {
    fetchAdminProducts();
  }, []);

  const navigate = useNavigate();

  // search Functionality
  const handleSearch = (e) => {
    const searchString = e.target.value.toLowerCase();
    const searchResults = adminProducts.filter(
      ({ Sku, Name, Color, CategoryName, Qty, BuyPrice, Mrp }) =>
        (Sku ?? "").toLowerCase().includes(searchString) ||
        (Name ?? "").toLowerCase().includes(searchString) ||
        (Color ?? "").toLowerCase().includes(searchString) ||
        (CategoryName ?? "").toLowerCase().includes(searchString) ||
        (Qty ? Qty.toString() : "").includes(searchString) ||
        (BuyPrice ? BuyPrice.toString() : "").includes(searchString) ||
        (Mrp ? Mrp.toString() : "").includes(searchString)
    );
    setFilterData(searchResults);
    setCurrentPage(1);
  };

  // sell Modal
  const sellProductsModal = () => {
    setSellModal(!sellModal);
  };

  // checkbox value
  const handleOrderchange = (Id, Sku, Mrp, Qty, Color, CategoryName, BuyPrice, checked) => {
    const newItem = {
      Id,
      Sku,
      Mrp,
      Qty,
      Color,
      CategoryName,
      BuyPrice,
    };

    if (checked) {
      setCheckValues([...checkvalues, newItem]);
    } else {
      setCheckValues(checkvalues.filter((item) => item.Id !== newItem.Id));
    }
  };

  const resetAllCheckboxes = () => {
    setCheckValues([]);
  };

  // Retrieve Admin Product data
  const fetchAdminProducts = async () => {
    setLoading(true);
    try {
      const response = await apiInstance.post("/api/GetProduct", { id: 0 });

      if (response.data.status === true) {
        const warehouseStockData = response.data.Data.map((fetchProduct) => ({
          Id: fetchProduct.Id,
          Sku: fetchProduct.Sku,
          Name: fetchProduct.Name,
          Color: fetchProduct.Color,
          Qty: fetchProduct.Qty,
          BuyPrice: fetchProduct.BuyPrice,
          Mrp: fetchProduct.Mrp,
          Status: fetchProduct.Status,
          CategoryName: fetchProduct.CategoryName,
        }));

        setAdminPRoducts(warehouseStockData);
        setFilterData(warehouseStockData);
      } else {
        console.error("Error: " + response.data.Message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notify("Your session just expired!", "error");
        setTimeout(() => {
          navigate("/authentication/logout");
        }, 5000);
      } else {
        console.error("Error fetching zone:", error);
      }
    }
    setLoading(false);
  };

  // for Pagination
  const totalItems = filterData ? filterData.length : 0;

  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);
  const displayProducts = filterData.slice(startIndex - 1, endIndex);

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(parseInt(value));
    setCurrentPage(1);
  };

  const tableHeadData = [
    {
      id: 0,
      header: (
        <MdIndeterminateCheckBox
          size="1.6rem"
          style={{ cursor: "pointer", marginLeft: "-2.4px" }}
          onClick={resetAllCheckboxes}
        />
      ),
    },
    {
      id: 1,
      header: "No.",
    },
    {
      id: 2,
      header: "Sku",
    },
    {
      id: 3,
      header: "Name",
    },
    {
      id: 4,
      header: "Color",
    },
    {
      id: 5,
      header: "Category",
    },
    {
      id: 6,
      header: "Qty.",
    },
    {
      id: 7,
      header: "Buy Price",
    },
    {
      id: 8,
      header: "MRP",
    },
  ];

  return (
    <>
      <Notification />
      {loading && <Loader />}
      <DashboardLayout>
        <DashboardNavbar />
        <Container className="mt--7 admin_addOrder_con con" fluid>
          <Card className="shadow">
            <CardHead
              sellProductsModal={sellProductsModal}
              handleSearch={handleSearch}
              cardHeader="Add Admin Order"
              checkvalues={checkvalues}
              buttonTitle2={
                <>
                  Sell <FaTruckArrowRight size="1.4rem" className="warehouse_send_icon" />
                </>
              }
            />
            <CardBody className="table_card_body">
              {loading ? (
                <LoadingSkeleton />
              ) : (
                <Table className="align-items-center table-flush new_table" hover responsive>
                  <TableHead tableHeadData={tableHeadData} />
                  <tbody>
                    {displayProducts &&
                      displayProducts.map((tableProducts, i) => {
                        const tableIndex = (currentPage - 1) * itemsPerPage + i;
                        return (
                          <AddOrderTableRow
                            tableIndex={tableIndex}
                            tableProducts={tableProducts}
                            key={tableProducts.Id}
                            handleOrderchange={handleOrderchange}
                            checkvalues={checkvalues}
                          />
                        );
                      })}
                  </tbody>
                </Table>
              )}
            </CardBody>
            <CardFooter>
              <NewPagination
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                handleItemsPerPageChange={handleItemsPerPageChange}
                itemsPerPageLabel="Products Per Page:"
              />
            </CardFooter>
          </Card>
        </Container>
      </DashboardLayout>
      <AdminSellModal
        sellModal={sellModal}
        setSellModal={setSellModal}
        sellProductsModal={sellProductsModal}
        checkvalues={checkvalues}
        setCheckValues={setCheckValues}
        fetchAdminProducts={fetchAdminProducts}
      />
    </>
  );
};

export default AdminAddOrder;
