// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";

import Logo from "../../assets/images/logo.png";

function Default() {
  return (
    <DashboardLayout className="navbar_con">
      <DashboardNavbar />
      <ArgonBox py={3} mt={3}>
        <Grid style={{ marginInline: "auto", width: "50%" }}>
          <Card
            sx={{
              py: 3,
              px: 2,
              alignItems: "center",
              boxShadow: ({ boxShadows: { md } }) => md,
            }}
          >
            <ArgonTypography
              variant="h5"
              fontWeight="regular"
              color="text"
              display="flex"
              alignItems="center"
            >
              Welcome to{" "}
              <img src={Logo} alt="Skmei" style={{ width: "100px", marginInline: "10px" }} />{" "}
              Dashboard
            </ArgonTypography>
          </Card>
        </Grid>
      </ArgonBox>
    </DashboardLayout>
  );
}

export default Default;
