import React, { useState, useEffect } from "react";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import NewPagination from "../../components/Pagination/NewPagination";
import Loader from "../../components/loader/Loader";
import LoadingSkeleton from "../../components/loader/LoadingSkeleton";
import ProductModal from "./ProductModal";
import Form_Modal from "../../components/Modal/Form_Modal";

import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import { green, red } from "@mui/material/colors";
import { apiInstance } from "../../axios/index";
import Notification, { notify } from "../../components/notification/Notification";

import ArgonBox from "../../components/ArgonBox";
import ArgonTypography from "../../components/ArgonTypography";
import ArgonBadge from "../../components/ArgonBadge";

import { Card, CardFooter, Container, Table, CardBody } from "reactstrap";
import TableHead from "components/Table/TableHead";
import CardHead from "../../components/Card/CardHead";
import { useNavigate } from "react-router-dom";

const tableHeadData = [
  {
    id: 1,
    header: "No.",
  },
  {
    id: 2,
    header: "Sku",
  },
  {
    id: 3,
    header: "Name",
  },
  {
    id: 4,
    header: "Color",
  },
  {
    id: 5,
    header: "Category",
  },
  {
    id: 6,
    header: "Qty",
  },
  {
    id: 7,
    header: "BuyPrice",
  },
  {
    id: 8,
    header: "MRP",
  },
  {
    id: 9,
    header: "Status",
  },
  {
    id: 10,
    header: "",
  },
];

const Product = () => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [editProduct, setEditProduct] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [skuId, setSkuId] = useState("");
  const [name, setName] = useState("");
  const [color, setColor] = useState("");
  const [qty, setQty] = useState("");
  const [buyPrice, setBuyPrice] = useState("");
  const [mrpPrice, setMrpPrice] = useState("");
  const [status, setStatus] = useState("");
  const [newModal, setNewModal] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  useEffect(() => {
    fetchProducts();
  }, []);

  const navigate = useNavigate();

  // search functionality
  const handleSearch = (e) => {
    const searchString = e.target.value.toLowerCase();
    const searchResults = products.filter(
      ({ Sku, Name, Color, Qty, BuyPrice, Mrp, Status, CategoryName }) =>
        (Sku ?? "").toLowerCase().includes(searchString) ||
        (Name ?? "").toLowerCase().includes(searchString) ||
        (Color ?? "").toLowerCase().includes(searchString) ||
        (Status ?? "").toLowerCase().includes(searchString) ||
        (CategoryName ?? "").toLowerCase().includes(searchString) ||
        (Qty ? Qty.toString() : "").includes(searchString) ||
        (BuyPrice ? BuyPrice.toString() : "").includes(searchString) ||
        (Mrp ? Mrp.toString() : "").includes(searchString)
    );
    setFilterData(searchResults);
    setCurrentPage(1);
  };

  // Delete Modal
  const toggleDeleteModal = (Id) => {
    setDeleteModalOpen(!deleteModalOpen);
    Id && setDeleteItemId(Id);
    console.log(Id);
  };

  // opening or closeing a modal
  const toggleAddModal = () => {
    setSkuId("");
    setName("");
    setColor("");
    setQty("");
    setBuyPrice("");
    setMrpPrice("");
    setStatus("");
    setSelectedCategory("");
    setNewModal(!newModal);
    setEditProduct(null);
  };

  // submitting a form
  const handleSubmit = async (e) => {
    e.preventDefault();

    // create product object
    const product = {
      Id: 0,
      Sku: skuId,
      Name: name,
      Color: color,
      Qty: qty,
      BuyPrice: buyPrice,
      Mrp: mrpPrice,
      Status: status,
      Category: selectedCategory,
    };

    try {
      if (editProduct) {
        await apiInstance.post(`/api/SaveProduct`, {
          Id: editProduct.Id,
          Sku: skuId,
          Name: name,
          Color: color,
          Qty: qty,
          BuyPrice: buyPrice,
          Mrp: mrpPrice,
          Status: status,
          Category: selectedCategory,
        });
        notify("Product Updated successfully", "success");
      } else {
        await apiInstance.post(`/api/SaveProduct`, {
          Id: product.Id,
          Sku: product.Sku,
          Name: product.Name,
          Color: product.Color,
          Qty: product.Qty,
          BuyPrice: product.BuyPrice,
          Mrp: product.Mrp,
          Status: product.Status,
          Category: product.Category,
        });
        notify("Product Created successfully", "success");
      }

      // Clear form fields and close modal
      setSkuId("");
      setName("");
      setColor("");
      setQty("");
      setBuyPrice("");
      setMrpPrice("");
      setStatus("");
      setSelectedCategory("");
      setEditProduct(null);
      setNewModal(false);

      // Fetch updated products data
      fetchProducts();
    } catch (error) {
      console.error("Error saving Product:", error.response);
    }
  };

  // Retrieve product data
  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await apiInstance.post("/api/GetProduct", {
        id: 0,
      });

      if (response.data.status === true) {
        const productData = response.data.Data.map((fetchProduct) => ({
          Id: fetchProduct.Id,
          Sku: fetchProduct.Sku,
          Name: fetchProduct.Name,
          Color: fetchProduct.Color,
          Qty: fetchProduct.Qty,
          BuyPrice: fetchProduct.BuyPrice,
          Mrp: fetchProduct.Mrp,
          Status: fetchProduct.Status,
          Category: fetchProduct.Category,
          CategoryName: fetchProduct.CategoryName,
        }));
        setProducts(productData);
        setFilterData(productData);
      } else {
        console.error("Error: " + response.data.Message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notify("Your session just expired!", "error");
        setTimeout(() => {
          navigate("/authentication/logout");
        }, 5000);
      } else {
        console.error(error);
      }
    }
    setLoading(false);
  };

  // editing a data
  const handleEdit = (editProduct) => {
    setSkuId(editProduct.Sku);
    setName(editProduct.Name);
    setColor(editProduct.Color);
    setQty(editProduct.Qty);
    setBuyPrice(editProduct.BuyPrice);
    setMrpPrice(editProduct.Mrp);
    setStatus(editProduct.Status);
    setSelectedCategory(editProduct.Category);
    setEditProduct(editProduct);
    setNewModal(true);
  };

  // deleting a data
  const handleDelete = async (e) => {
    e.preventDefault();
    const productId = deleteItemId;
    console.log(productId);
    try {
      await apiInstance.post(`/api/DeleteProduct`, {
        id: productId,
      });
      notify("Product Deleted successfully", "success");
    } catch (error) {
      console.error("Error deleting Product:", error);
      notify("Error Deleting Product", "error");
    }
    setDeleteModalOpen(!deleteModalOpen);
    fetchProducts();
    setDeleteItemId(null);
  };

  // for Pagination
  const totalItems = filterData ? filterData.length : 0;

  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);
  const displayedProducts = filterData.slice(startIndex - 1, endIndex);

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(parseInt(value));
    setCurrentPage(1);
  };

  return (
    <>
      <Notification />
      {loading && <Loader />}
      <DashboardLayout>
        <DashboardNavbar />
        <Container className="mt--7 zone_con con product_con" fluid>
          <Card className="shadow">
            <CardHead
              toggleAddModal={toggleAddModal}
              handleSearch={handleSearch}
              cardHeader="Product"
            />
            <CardBody className="table_card_body">
              {loading ? (
                <LoadingSkeleton />
              ) : (
                <Table className="align-items-center table-flush new_table" hover responsive>
                  <TableHead tableHeadData={tableHeadData} />
                  <tbody>
                    {displayedProducts &&
                      displayedProducts.map((tableProduct, i) => {
                        const index4 = (currentPage - 1) * itemsPerPage + i;
                        return (
                          <tr key={index4}>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {index4 + 1}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableProduct.Sku}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableProduct.Name}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableProduct.Color}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonBox>
                                <ArgonTypography
                                  variant="button"
                                  color="info"
                                  fontWeight="bold"
                                  textGradient
                                >
                                  {tableProduct.CategoryName}
                                </ArgonTypography>
                              </ArgonBox>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableProduct.Qty}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableProduct.BuyPrice}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableProduct.Mrp}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonBox className="order_badge">
                                {tableProduct.Status === "Enable" && (
                                  <ArgonBadge
                                    variant="gradient"
                                    badgeContent="Enable"
                                    color="success"
                                    size="xs"
                                    container
                                  />
                                )}
                                {tableProduct.Status === "Disable" && (
                                  <ArgonBadge
                                    variant="gradient"
                                    badgeContent="Disable"
                                    color="error"
                                    size="xs"
                                    container
                                  />
                                )}
                              </ArgonBox>
                            </td>
                            <td className="usertable_buttons">
                              <button className="btn edit_btn">
                                <CreateIcon
                                  onClick={() => handleEdit(tableProduct)}
                                  fontSize="small"
                                  sx={{ color: green[500] }}
                                />
                              </button>
                              <button className="btn delete_btn">
                                <DeleteIcon
                                  onClick={() => toggleDeleteModal(tableProduct.Id)}
                                  fontSize="small"
                                  sx={{ color: red[600] }}
                                />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              )}
            </CardBody>
            <CardFooter>
              <NewPagination
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                handleItemsPerPageChange={handleItemsPerPageChange}
                itemsPerPageLabel="Products Per Page:"
              />
            </CardFooter>
          </Card>
        </Container>
      </DashboardLayout>
      {deleteModalOpen && (
        <Form_Modal
          handleDelete={handleDelete}
          deleteModalOpen={deleteModalOpen}
          toggleDeleteModal={toggleDeleteModal}
          modalDesc="Are you sure you want to Delete?"
          callToAction="Delete"
        />
      )}
      {newModal && (
        <ProductModal
          newModal={newModal}
          toggleAddModal={toggleAddModal}
          handleSubmit={handleSubmit}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          status={status}
          setStatus={setStatus}
          skuId={skuId}
          setSkuId={setSkuId}
          name={name}
          setName={setName}
          color={color}
          setColor={setColor}
          qty={qty}
          setQty={setQty}
          buyPrice={buyPrice}
          setBuyPrice={setBuyPrice}
          mrpPrice={mrpPrice}
          setMrpPrice={setMrpPrice}
        />
      )}
    </>
  );
};

export default Product;
