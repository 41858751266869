import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import NewPagination from "../../../components/Pagination/NewPagination";
import Loader from "../../../components/loader/Loader";
import LoadingSkeleton from "../../../components/loader/LoadingSkeleton";
import ArgonBox from "../../../components/ArgonBox";
import ArgonTypography from "../../../components/ArgonTypography";
import ArgonBadge from "../../../components/ArgonBadge";
import Form_Modal from "../../../components/Modal/Form_Modal";
import TableHead from "../../../components/Table/TableHead";
import CardHead from "../../../components/Card/CardHead";
import Notification, { notify } from "../../../components/notification/Notification";
import { apiInstance } from "../../../axios/index";

import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import { green, red } from "@mui/material/colors";
import { Card, CardFooter, Container, Table, CardBody } from "reactstrap";
import CategoryModal from "./CategoryModal";
import { useNavigate } from "react-router-dom";

const tableHeadData = [
  {
    id: 1,
    header: "No.",
  },
  {
    id: 2,
    header: "Name",
  },
  {
    id: 3,
    header: "Status",
  },
  {
    id: 4,
    header: "",
  },
];

const Category = () => {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [editCategory, setEditCategory] = useState(null);
  const [name, setName] = useState("");
  const [status, setStatus] = useState("Enable");
  const [newModal, setNewModal] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  const navigate = useNavigate();

  // search Functionality
  const handleSearch = (e) => {
    const searchString = e.target.value.toLowerCase();
    const searchResults = categories.filter(
      ({ Name, Status }) =>
        (Name ?? "").toLowerCase().includes(searchString) ||
        (Status ?? "").toLowerCase().includes(searchString)
    );
    setFilterData(searchResults);
    setCurrentPage(1);
  };

  // Delete Modal
  const toggleDeleteModal = (id) => {
    setDeleteModalOpen(!deleteModalOpen);
    id && setDeleteItemId(id);
  };

  // opening or closeing a modal
  const toggleAddModal = () => {
    setName("");
    setStatus("");
    setNewModal(!newModal);
    setEditCategory(null);
  };

  // submitting a form
  const handleSubmit = async (e) => {
    e.preventDefault();

    // create Category object
    const category = {
      id: 0,
      Name: name,
      Status: status,
    };

    try {
      if (editCategory) {
        await apiInstance.post(`/api/SaveCategory`, {
          id: editCategory.id,
          Name: name,
          Status: status,
        });
        notify("Category Edited successfully", "success");
      } else {
        await apiInstance.post(`/api/SaveCategory`, {
          id: category.id,
          Name: category.Name,
          Status: category.Status,
        });
        notify("Category Created successfully", "success");
      }

      // Clear form fields and close modal
      setName("");
      setStatus("");
      setEditCategory(null);
      setNewModal(false);

      // Fetch updated category data
      fetchCategories();
    } catch (error) {
      console.error("Error saving Category:", error.response);
      notify(error.response, "error");
    }
  };

  // Retrieve category data
  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await apiInstance.post("/api/GetCategory", { id: 0 });

      if (response.data.status === true) {
        const categoryData = response.data.Data.map((category) => ({
          id: category.Id,
          Name: category.Name,
          Status: category.Status,
        }));

        setCategories(categoryData);
        setFilterData(categoryData);
      } else {
        console.error("Error: " + response.data.Message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notify("Your session just expired!", "error");
        setTimeout(() => {
          navigate("/authentication/logout");
        }, 5000);
      } else {
        console.error(error);
      }
    }
    setLoading(false);
  };

  // edit category
  const handleEditCategory = (editCategory) => {
    setName(editCategory.Name);
    setStatus(editCategory.Status);
    setEditCategory(editCategory);
    setNewModal(true);
  };

  // deleting a data
  const handleDeleteCategory = async (e) => {
    e.preventDefault();
    const categoryId = deleteItemId;
    await apiInstance
      .post("/api/DeleteCategory", { id: categoryId })
      .then((response) => {
        if (response.data.Status === true) {
          notify("Category deleted successfully", "success");
        } else {
          notify("Failed to delete Category data", "error");
        }
      })
      .catch((error) => {
        notify("Failed to delete Category data", "error");
        console.error("Error occurred while deleting Category data:", error);
      });
    setDeleteModalOpen(!deleteModalOpen);
    fetchCategories();
    setDeleteItemId(null);
  };

  // for Pagination
  const totalItems = filterData ? filterData.length : 0;

  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);
  const displayedCategories = filterData.slice(startIndex - 1, endIndex);

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(parseInt(value));
    setCurrentPage(1);
  };

  return (
    <>
      <Notification />
      {loading && <Loader />}
      <DashboardLayout>
        <DashboardNavbar />
        <Container className="mt--7 zone_con con" fluid>
          <Card className="shadow">
            <CardHead
              toggleAddModal={toggleAddModal}
              handleSearch={handleSearch}
              cardHeader="Category"
            />
            <CardBody className="table_card_body">
              {loading ? (
                <LoadingSkeleton />
              ) : (
                <Table className="align-items-center table-flush new_table" hover responsive>
                  <TableHead tableHeadData={tableHeadData} />
                  <tbody>
                    {displayedCategories &&
                      displayedCategories.map((tableCategory, i) => {
                        const tableIndex = (currentPage - 1) * itemsPerPage + i;
                        return (
                          <tr key={tableIndex}>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableIndex + 1}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableCategory.Name}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonBox className="order_badge">
                                {tableCategory.Status === "Enable" && (
                                  <ArgonBadge
                                    variant="gradient"
                                    badgeContent="Enable"
                                    color="success"
                                    size="xs"
                                    container
                                  />
                                )}
                                {tableCategory.Status === "Disable" && (
                                  <ArgonBadge
                                    variant="gradient"
                                    badgeContent="Disable"
                                    color="error"
                                    size="xs"
                                    container
                                  />
                                )}
                              </ArgonBox>
                            </td>
                            <td className="usertable_buttons">
                              <button className="btn edit_btn">
                                <CreateIcon
                                  onClick={() => handleEditCategory(tableCategory)}
                                  fontSize="small"
                                  sx={{ color: green[500] }}
                                />
                              </button>
                              <button className="btn delete_btn">
                                <DeleteIcon
                                  onClick={() => toggleDeleteModal(tableCategory.id)}
                                  fontSize="small"
                                  sx={{ color: red[500] }}
                                />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              )}
            </CardBody>
            <CardFooter>
              <NewPagination
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                handleItemsPerPageChange={handleItemsPerPageChange}
                itemsPerPageLabel="Categories Per Page:"
              />
            </CardFooter>
          </Card>
        </Container>
      </DashboardLayout>
      {deleteModalOpen && (
        <Form_Modal
          handleDelete={handleDeleteCategory}
          deleteModalOpen={deleteModalOpen}
          toggleDeleteModal={toggleDeleteModal}
          modalDesc="Are you sure you want to Delete?"
          callToAction="Delete"
        />
      )}
      {newModal && (
        <CategoryModal
          newModal={newModal}
          toggleAddModal={toggleAddModal}
          handleSubmit={handleSubmit}
          name={name}
          setName={setName}
          status={status}
          setStatus={setStatus}
        />
      )}
    </>
  );
};

export default Category;
