import React, { useEffect, useState } from "react";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import TableHead from "../../components/Table/TableHead";
import Loader from "../../components/loader/Loader";
import { Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";
import Cookies from "js-cookie";
import { notify } from "../../components/notification/Notification";
import { apiInstance } from "../../axios/index";
import InputFeild from "../../components/Modal/InputFeild";

const tableHeadData = [
  {
    id: 1,
    header: "No.",
  },
  {
    id: 3,
    header: "SKU",
  },
  {
    id: 4,
    header: "Name",
  },
  {
    id: 5,
    header: "Qty",
  },
  {
    id: 6,
    header: "Amount",
  },
  {
    id: 7,
    header: "Order Qty.",
  },
  {
    id: 8,
    header: "Total",
  },
];

const OrderItemsModal = ({
  orderModal,
  orderProductsModal,
  checkvalues,
  setOrderModal,
  setCheckValues,
}) => {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState("");
  const [orderQtys, setOrderQtys] = useState(Array(checkvalues.length).fill(""));

  useEffect(() => {
    setOrderQtys(Array(checkvalues.length).fill(""));
  }, [orderModal]);

  const warehouseModalfields = [
    {
      id: "warehouse_sell_date",
      label: "Date",
      type: "date",
      value: date,
      onChange: (e) => setDate(e.target.value),
      required: true,
    },
  ];

  // order Quantity
  const handleOrderQtyChange = (e, index) => {
    const newValue = e.target.value;
    const updatedOrderQtys = [...orderQtys];
    updatedOrderQtys[index] = newValue === "" ? "" : Number(newValue);
    setOrderQtys(updatedOrderQtys);
  };

  const orderItems = checkvalues.map((orderItem, index) => ({
    Id: orderItem.Id,
    Qty: orderQtys[index],
    Amount: orderQtys[index] ? orderItem.Mrp * orderQtys[index] : 0,
    index: index,
  }));

  useEffect(() => {
    orderItems;
  }, [orderItems]);

  const sellerId = Cookies.get("Id");
  const totalOrderQty = orderQtys.reduce((sum, orderQty) => sum + Number(orderQty), 0);
  const totalAmount = orderItems.reduce((sum, orderItem) => sum + orderItem.Amount, 0);

  // sending order Items
  const orderProducts = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const products = checkvalues.map((orderItem, index) => ({
        ProductId: orderItem.Id,
        Qty: orderQtys[index],
      }));
      console.log(products);

      await apiInstance.post("/SaleOrder/Save", {
        SellerId: sellerId,
        Qty: totalOrderQty,
        Amount: totalAmount,
        UserType: "Seller",
        OrderDate: date,
        Products: products,
      });
      notify("Order placed successfully", "success");
    } catch (error) {
      console.error("Error fetching seller Products:", error);
      notify("Error occured while order", "error");
    }
    setLoading(false);
    setCheckValues([]);
    setOrderQtys([]);
    setOrderModal(!orderModal);
  };

  const allInputsFilled = orderQtys.every((value) => value === "" || value == undefined);

  return (
    <>
      {loading && <Loader />}
      <Modal
        isOpen={orderModal}
        toggle={orderProductsModal}
        className="modal-xl warehouse_add_modal"
        centered
      >
        <ModalHeader toggle={orderProductsModal} className="userTable_header">
          <ArgonTypography color="text" fontWeight="medium" variant="d3">
            Order Ids
          </ArgonTypography>
          <div className="select_con col-md-6">
            <div>
              <div className="warehouse_date">
                {warehouseModalfields &&
                  warehouseModalfields.map((inwardField) => {
                    return <InputFeild inwardField={inwardField} key={inwardField.id} />;
                  })}
              </div>
            </div>
          </div>
        </ModalHeader>
        <form className="needs-validation new_form" id="form_details" onSubmit={orderProducts}>
          <ModalBody>
            <Table className="align-items-center table-flush new_table" hover responsive>
              <TableHead tableHeadData={tableHeadData} />
              <tbody>
                {checkvalues &&
                  checkvalues.map((orderItem, index) => {
                    const orderQty = orderQtys[index];
                    const amount = orderQty ? orderItem.Mrp * orderQty : 0;
                    return (
                      <tr key={orderItem.Id}>
                        <td>
                          <ArgonBox mb={2}>
                            <ArgonTypography color="text" fontWeight="medium" variant="caption">
                              {index + 1}
                            </ArgonTypography>
                          </ArgonBox>
                        </td>
                        <td>
                          <ArgonBox mb={2}>
                            <ArgonTypography color="text" fontWeight="medium" variant="caption">
                              {orderItem.Sku}
                            </ArgonTypography>
                          </ArgonBox>
                        </td>
                        <td>
                          <ArgonBox mb={2}>
                            <ArgonTypography color="text" fontWeight="medium" variant="caption">
                              {orderItem.Name}
                            </ArgonTypography>
                          </ArgonBox>
                        </td>
                        <td>
                          <ArgonBox mb={2}>
                            <ArgonTypography color="text" fontWeight="medium" variant="caption">
                              {orderItem.Qty}
                            </ArgonTypography>
                          </ArgonBox>
                        </td>
                        <td>
                          <ArgonBox mb={2}>
                            <ArgonTypography color="text" fontWeight="medium" variant="caption">
                              {orderItem.Mrp}
                            </ArgonTypography>
                          </ArgonBox>
                        </td>
                        <td style={{ width: "200px" }}>
                          <ArgonBox mb={2}>
                            <ArgonInput
                              size="medium"
                              id={"order_input" + index}
                              placeholder="Order Qty."
                              type="number"
                              className="form-control form-control-alternative"
                              aria-invalid="false"
                              value={orderQtys[index] || ""}
                              onChange={(e) => handleOrderQtyChange(e, index)}
                              required
                            />
                          </ArgonBox>
                        </td>
                        <td>
                          <ArgonBox mb={2}>
                            <ArgonTypography color="text" fontWeight="medium" variant="caption">
                              {amount}
                            </ArgonTypography>
                          </ArgonBox>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
              <tbody>
                <tr>
                  <td>
                    <ArgonTypography color="text" fontWeight="medium" variant="button">
                      Total:
                    </ArgonTypography>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <ArgonTypography color="text" fontWeight="medium" variant="button">
                      {totalOrderQty}
                    </ArgonTypography>
                  </td>
                  <td>
                    <ArgonTypography color="text" fontWeight="medium" variant="button">
                      {totalAmount}
                    </ArgonTypography>
                  </td>
                </tr>
              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <ArgonButton
              color="success"
              size="medium"
              type="submit"
              disabled={!date || allInputsFilled}
            >
              Save
            </ArgonButton>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default OrderItemsModal;
