import React from "react";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import { PaginationItem } from "reactstrap";
import "./pagination.css";

const NewPagination = ({
  totalItems,
  itemsPerPage,
  currentPage,
  setCurrentPage,
  inwardCurrentPage,
  setInwardCurrentPage,
  handleItemsPerPageChange,
  itemsPerPageLabel,
  modalFilterData,
  setReceivedQtyinput,
  setDemageQtyinput,
  setMissingQtyinput,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const inwardPrevPage = () => {
    if (inwardCurrentPage > 1) {
      setInwardCurrentPage(inwardCurrentPage - 1);
      // setReceivedQtyinput(Array(modalFilterData.length).fill(""));
      // setDemageQtyinput(Array(modalFilterData.length).fill(""));
      // setMissingQtyinput(Array(modalFilterData.length).fill(""));
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const inwardModalNextPage = () => {
    if (inwardCurrentPage < totalPages) {
      setInwardCurrentPage(inwardCurrentPage + 1);
      // setReceivedQtyinput(Array(modalFilterData.length).fill(""));
      // setDemageQtyinput(Array(modalFilterData.length).fill(""));
      // setMissingQtyinput(Array(modalFilterData.length).fill(""));
    }
  };

  const handleSelectChange = (event) => {
    const { value } = event.target;
    handleItemsPerPageChange(parseInt(value));
  };

  return (
    <>
      <div>
        <ArgonTypography color="secondary" fontWeight="medium" variant="button">
          Total: {totalItems}
        </ArgonTypography>
        <div className="select_con">
          <ArgonTypography color="secondary" fontWeight="medium" variant="button">
            {itemsPerPageLabel}
          </ArgonTypography>
          <select
            value={itemsPerPage}
            onChange={handleSelectChange}
            className="pagination_select"
            id="demo-simple-select"
            label="Pagination"
          >
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
          </select>
        </div>
      </div>
      <div className="pagination_upper_con">
        <div className="pagination mb-0 pagination_con">
          <div className="pagination_inner">
            {currentPage && (
              <PaginationItem className={`${currentPage === 1 ? "disabled" : ""} pagination_item`}>
                <ArgonButton
                  color="white"
                  size="medium"
                  className="New_btn_2"
                  onClick={(e) => {
                    e.preventDefault();
                    prevPage();
                  }}
                  tabIndex={-1}
                  disabled={currentPage === 1}
                >
                  <NavigateBeforeIcon />
                </ArgonButton>
              </PaginationItem>
            )}

            {currentPage && (
              <PaginationItem className="pagination_item">
                <ArgonButton
                  onClick={(e) => e.preventDefault()}
                  className="New_btn_3"
                  color="white"
                  size="medium"
                  style={{ height: "100%" }}
                >
                  {currentPage}
                </ArgonButton>
              </PaginationItem>
            )}

            {currentPage && (
              <PaginationItem
                className={`${currentPage < totalPages ? "" : "disabled"} pagination_item`}
              >
                <ArgonButton
                  color="white"
                  size="medium"
                  className="New_btn_2"
                  onClick={(e) => {
                    e.preventDefault();
                    nextPage();
                  }}
                  disabled={currentPage === totalPages}
                >
                  <NavigateNextIcon />
                </ArgonButton>
              </PaginationItem>
            )}

            {inwardCurrentPage && (
              <PaginationItem
                className={`${inwardCurrentPage === 1 ? "disabled" : ""} pagination_item`}
              >
                <ArgonButton
                  color="white"
                  size="medium"
                  className="New_btn_2"
                  onClick={(e) => {
                    e.preventDefault();
                    inwardPrevPage();
                  }}
                  tabIndex={-1}
                  disabled={inwardCurrentPage === 1}
                >
                  <NavigateBeforeIcon />
                </ArgonButton>
              </PaginationItem>
            )}

            {inwardCurrentPage && (
              <PaginationItem className="pagination_item">
                <ArgonButton
                  onClick={(e) => e.preventDefault()}
                  className="New_btn_3"
                  color="white"
                  size="medium"
                  style={{ height: "100%" }}
                >
                  {inwardCurrentPage}
                </ArgonButton>
              </PaginationItem>
            )}

            {inwardCurrentPage && (
              <PaginationItem
                className={`${inwardCurrentPage < totalPages ? "" : "disabled"} pagination_item`}
              >
                <ArgonButton
                  color="white"
                  size="medium"
                  className="New_btn_2"
                  onClick={(e) => {
                    e.preventDefault();
                    inwardModalNextPage();
                  }}
                  disabled={inwardCurrentPage === totalPages}
                >
                  <NavigateNextIcon />
                </ArgonButton>
              </PaginationItem>
            )}

            <br />
          </div>
          <div>
            <ArgonTypography color="secondary" fontWeight="bold" variant="caption">
              <span>{currentPage}</span> of <span>{totalPages}</span>
            </ArgonTypography>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPagination;
