import React, { useState } from "react";
import ArgonBox from "../../components/ArgonBox";
import ArgonTypography from "../../components/ArgonTypography";
import { Checkbox } from "@mui/material";

import { Modal, ModalHeader, ModalBody } from "reactstrap";

const ProductSellerTableRow = ({ index, tableProduct, handleOrderchange, checkvalues }) => {
  const [imageModal, setImageModal] = useState(false);

  const imagePopUp = (Id) => {
    setImageModal(Id);
    setImageModal(!imageModal);
  };

  const isChecked = checkvalues.some((item) => item.Id === tableProduct.Id);
  const isQtyZero = tableProduct.Qty === 0;

  return (
    <>
      <tr
        key={tableProduct.Id}
        className={`${isChecked ? "rowSelected" : ""} ${isQtyZero ? "rowOpacity" : ""}`}
      >
        <td style={{ width: "70px" }}>
          <Checkbox
            label="Outlined"
            variant="outlined"
            value={tableProduct.Id}
            disabled={isQtyZero}
            checked={isChecked}
            onChange={(e) =>
              handleOrderchange(
                tableProduct.Id,
                tableProduct.Sku,
                tableProduct.Name,
                tableProduct.Qty,
                tableProduct.Mrp,
                e.target.checked
              )
            }
          />
        </td>
        <td>
          <ArgonTypography variant="caption" color="dark">
            {index + 1}
          </ArgonTypography>
        </td>
        <td>
          <ArgonTypography variant="caption" color="dark">
            {tableProduct.Sku}
          </ArgonTypography>
        </td>
        <td>
          <ArgonTypography variant="caption" color="dark">
            {tableProduct.Name}
          </ArgonTypography>
        </td>
        <td>
          <ArgonTypography variant="caption" color="dark">
            {tableProduct.Color}
          </ArgonTypography>
        </td>
        <td>
          <ArgonTypography variant="caption" color="dark">
            {tableProduct.Qty}
          </ArgonTypography>
        </td>
        <td>
          <ArgonTypography variant="caption" color="dark">
            {tableProduct.Mrp}
          </ArgonTypography>
        </td>
        <td className="product_seller_image_id">
          <ArgonBox className="seller_product_image" onClick={() => imagePopUp(tableProduct.Id)}>
            <img src={tableProduct.Image} alt="." className="small_image" />
            <Modal
              isOpen={imageModal}
              toggle={imagePopUp}
              className="image_modal"
              centered
              backdrop
              fade
            >
              <ModalHeader toggle={imagePopUp}></ModalHeader>
              <ModalBody>
                <ArgonTypography variant="caption" color="dark">
                  <img src={tableProduct.Image} alt="." className="big_image" />
                </ArgonTypography>
              </ModalBody>
            </Modal>
          </ArgonBox>
        </td>
      </tr>
    </>
  );
};

export default ProductSellerTableRow;
