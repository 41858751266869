import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import NewPagination from "../../../components/Pagination/NewPagination";
import Loader from "../../../components/loader/Loader";
import LoadingSkeleton from "../../../components/loader/LoadingSkeleton";
import Form_Modal from "../../../components/Modal/Form_Modal";
import ZoneModal from "./ZoneModal";

import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import { green, red } from "@mui/material/colors";
import { apiInstance } from "../../../axios/index";
import Notification, { notify } from "../../../components/notification/Notification";
import ArgonTypography from "components/ArgonTypography";

import { Card, CardFooter, Container, Table, CardBody } from "reactstrap";
import TableHead from "../../../components/Table/TableHead";
import CardHead from "../../../components/Card/CardHead";
import { useNavigate } from "react-router-dom";

const tableHeadData = [
  {
    id: 1,
    header: "No.",
  },
  {
    id: 2,
    header: "Name",
  },
  {
    id: 3,
    header: "",
  },
];

const Zone = () => {
  const [loading, setLoading] = useState(false);
  const [zones, setZones] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [editZone, setEditZone] = useState(null);
  const [name, setName] = useState("");
  const [newModal, setNewModal] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  useEffect(() => {
    fetchZones();
  }, []);

  const navigate = useNavigate();

  // search Functionality
  const handleSearch = (e) => {
    const searchString = e.target.value.toLowerCase();
    const searchResults = zones.filter(({ zoneName }) =>
      (zoneName ?? "").toLowerCase().includes(searchString)
    );
    setFilterData(searchResults);
    setCurrentPage(1);
  };

  // Delete Modal
  const toggleDeleteModal = (id) => {
    setDeleteModalOpen(!deleteModalOpen);
    id && setDeleteItemId(id);
  };

  // opening or closeing a modal
  const toggleAddModal = () => {
    setName("");
    setNewModal(!newModal);
    setEditZone(null);
  };

  // submitting a form
  const handleSubmit = async (e) => {
    e.preventDefault();

    // create Zone object
    const zone = {
      id: 0,
      zoneName: name,
    };

    try {
      if (editZone) {
        await apiInstance.post(`/api/SaveZone`, {
          id: editZone.id,
          zoneName: name,
        });
        notify("Zone Edited successfully", "success");
      } else {
        await apiInstance.post(`/api/SaveZone`, {
          id: zone.id,
          zoneName: zone.zoneName,
        });
        notify("Zone Created successfully", "success");
      }

      // Clear form fields and close modal
      setName("");
      setEditZone(null);
      setNewModal(false);

      // Fetch updated user data
      fetchZones();
    } catch (error) {
      console.error("Error saving zone:", error.response);
      notify("An error occurred while Saving the Zone", "error");
    }
  };

  // Retrieve zones data
  const fetchZones = async () => {
    setLoading(true);
    try {
      const response = await apiInstance.post("/api/GetZone", { id: 0 });

      if (response.data.status === true) {
        const zoneData = response.data.Data.map((zone) => ({
          id: zone.Id,
          zoneName: zone.Zone,
        }));

        setZones(zoneData);
        setFilterData(zoneData);
      } else {
        console.error("Error: " + response.data.Message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notify("Your session just expired!", "error");
        setTimeout(() => {
          navigate("/authentication/logout");
        }, 5000);
      } else {
        console.error("Error fetching zone:", error);
      }
    }
    setLoading(false);
  };

  // editing a data
  const handleEdit = (editZone) => {
    setName(editZone.zoneName);
    setEditZone(editZone);
    setNewModal(true);
  };

  // deleting a data
  const handleDelete = async (e) => {
    e.preventDefault();
    const zoneId = deleteItemId;
    await apiInstance
      .post("/api/DeleteZone", { id: zoneId })
      .then((response) => {
        if (response.data.Status === true) {
          notify("Zone deleted successfully", "success");
        } else {
          notify("Failed to delete Zone data", "error");
        }
      })
      .catch((error) => {
        notify("Failed to delete Zone data", "error");
        console.error("Error occurred while deleting Zone data:", error);
      });
    setDeleteModalOpen(!deleteModalOpen);
    fetchZones();
    setDeleteItemId(null);
  };

  // for Pagination
  const totalItems = filterData ? filterData.length : 0;

  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);
  const displayedZones = filterData.slice(startIndex - 1, endIndex);

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(parseInt(value));
    setCurrentPage(1);
  };

  return (
    <>
      <Notification />
      {loading && <Loader />}
      <DashboardLayout>
        <DashboardNavbar />
        <Container className="mt--7 zone_con con" fluid>
          <Card className="shadow">
            <CardHead
              toggleAddModal={toggleAddModal}
              handleSearch={handleSearch}
              cardHeader="Zone"
            />
            <CardBody className="table_card_body">
              {loading ? (
                <LoadingSkeleton />
              ) : (
                <Table className="align-items-center table-flush new_table" hover responsive>
                  <TableHead tableHeadData={tableHeadData} />
                  <tbody>
                    {displayedZones &&
                      displayedZones.map((tableZone, i) => {
                        const tableIndex = (currentPage - 1) * itemsPerPage + i;
                        return (
                          <tr key={tableIndex}>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableIndex + 1}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableZone.zoneName}
                              </ArgonTypography>
                            </td>
                            <td className="usertable_buttons">
                              <button className="btn edit_btn">
                                <CreateIcon
                                  onClick={() => handleEdit(tableZone)}
                                  fontSize="small"
                                  sx={{ color: green[500] }}
                                />
                              </button>
                              <button className="btn delete_btn">
                                <DeleteIcon
                                  onClick={() => toggleDeleteModal(tableZone.id)}
                                  fontSize="small"
                                  sx={{ color: red[500] }}
                                />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              )}
            </CardBody>
            <CardFooter>
              <NewPagination
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                handleItemsPerPageChange={handleItemsPerPageChange}
                itemsPerPageLabel="Zones Per Page:"
              />
            </CardFooter>
          </Card>
        </Container>
      </DashboardLayout>
      {deleteModalOpen && (
        <Form_Modal
          handleDelete={handleDelete}
          deleteModalOpen={deleteModalOpen}
          toggleDeleteModal={toggleDeleteModal}
          modalDesc="Are you sure you want to Delete?"
          callToAction="Delete"
        />
      )}
      {newModal && (
        <ZoneModal
          newModal={newModal}
          toggleAddModal={toggleAddModal}
          handleSubmit={handleSubmit}
          name={name}
          setName={setName}
        />
      )}
    </>
  );
};

export default Zone;
