import React, { useState, useEffect } from "react";
import Notification, { notify } from "../../../components/notification/Notification";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import NewPagination from "../../../components/Pagination/NewPagination";
import Loader from "../../../components/loader/Loader";
import LoadingSkeleton from "../../../components/loader/LoadingSkeleton";
import TableHead from "../../../components/Table/TableHead";
import CardHead from "../../../components/Card/CardHead";
import { apiInstance } from "../../../axios/index";
import WarehouseTableRow from "./WarehouseTableRow";

import { Card, CardFooter, Container, Table, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import WarehouseReturnModal from "./WarehouseReturnModal";
import WarehouseSellModal from "./WarehouseSellModal";

import { MdIndeterminateCheckBox } from "react-icons/md";
import { FaTruckArrowRight } from "react-icons/fa6";

const Warehouse_Stock = () => {
  const [loading, setLoading] = useState(false);
  const [warehouseStocks, setWarehouseStocks] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [checkvalues, setCheckValues] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [returnModal, setReturnModal] = useState(false);
  const [sellModal, setSellModal] = useState(false);

  useEffect(() => {
    fetchwarehouseStocks();
  }, []);

  const navigate = useNavigate();

  // search Functionality
  const handleSearch = (e) => {
    const searchString = e.target.value.toLowerCase();
    const searchResults = warehouseStocks.filter(
      ({ Sku, WarehouseName, Amount, Qty, ReturnQty, SaleQty }) =>
        (Sku ?? "").toLowerCase().includes(searchString) ||
        (Amount ? Amount.toString() : "").includes(searchString) ||
        (WarehouseName ?? "").toLowerCase().includes(searchString) ||
        (Qty ? Qty.toString() : "").includes(searchString) ||
        (ReturnQty ? ReturnQty.toString() : "").includes(searchString) ||
        (SaleQty ? SaleQty.toString() : "").includes(searchString)
    );
    setFilterData(searchResults);
    setCurrentPage(1);
  };

  // Return Modal
  const returnProductsModal = () => {
    setReturnModal(!returnModal);
  };

  // sell Modal
  const sellProductsModal = () => {
    setSellModal(!sellModal);
  };

  // checkbox value
  const handleOrderchange = (
    Id,
    ProductId,
    Sku,
    Amount,
    Qty,
    ReturnQty,
    SaleQty,
    WarehouseId,
    WarehouseName,
    checked
  ) => {
    const newItem = {
      Id,
      ProductId,
      Sku,
      Amount,
      Qty,
      ReturnQty,
      SaleQty,
      WarehouseId,
      WarehouseName,
    };

    if (checked) {
      const hasDifferentWarehouse = checkvalues.some((item) => item.WarehouseId !== WarehouseId);

      if (hasDifferentWarehouse) {
        return;
      } else {
        setCheckValues([...checkvalues, newItem]);
      }
    } else {
      setCheckValues(checkvalues.filter((item) => item.Id !== newItem.Id));
    }
  };

  const resetAllCheckboxes = () => {
    setCheckValues([]);
  };

  // Retrieve Warehouse Stock data
  const fetchwarehouseStocks = async () => {
    setLoading(true);
    try {
      const response = await apiInstance.post("/Warehouse/GetProduct", { id: 0 });

      if (response.data.status === true) {
        const warehouseStockData = response.data.Data.map((stock) => ({
          Id: stock.Id,
          ProductId: stock.ProductId,
          Sku: stock.Sku,
          Amount: stock.Amount,
          Qty: stock.Qty,
          ReturnQty: stock.ReturnQty,
          SaleQty: stock.SaleQty,
          WarehouseName: stock.WarehouseName,
          WarehouseId: stock.WarehouseId,
          WarehouseStockId: stock.WarehouseStockId,
        }));

        setWarehouseStocks(warehouseStockData);
        setFilterData(warehouseStockData);
      } else {
        console.error("Error: " + response.data.Message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notify("Your session just expired!", "error");
        setTimeout(() => {
          navigate("/authentication/logout");
        }, 5000);
      } else {
        console.error("Error fetching Warehouse stock:", error);
      }
    }
    setLoading(false);
  };

  // for Pagination
  const totalItems = filterData ? filterData.length : 0;

  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);
  const displayedstocks = filterData.slice(startIndex - 1, endIndex);

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(parseInt(value));
    setCurrentPage(1);
  };

  const tableHeadData = [
    {
      id: 0,
      header: (
        <MdIndeterminateCheckBox
          size="1.6rem"
          style={{ cursor: "pointer", marginLeft: "-2.4px" }}
          onClick={resetAllCheckboxes}
        />
      ),
    },
    {
      id: 1,
      header: "No.",
    },
    {
      id: 2,
      header: "Sku",
    },
    {
      id: 3,
      header: "Amount",
    },
    {
      id: 4,
      header: "Qty.",
    },
    {
      id: 5,
      header: "Return Qty.",
    },
    {
      id: 6,
      header: "Sell Qty.",
    },
    {
      id: 7,
      header: "Warehouse",
    },
  ];

  return (
    <>
      <Notification />
      {loading && <Loader />}
      <DashboardLayout>
        <DashboardNavbar />
        <Container className="mt--7 warehouse_stock_con con" fluid>
          <Card className="shadow">
            <CardHead
              returnProductsModal={returnProductsModal}
              sellProductsModal={sellProductsModal}
              handleSearch={handleSearch}
              cardHeader="Warehouse Stock"
              checkvalues={checkvalues}
              buttonTitle={
                <>
                  Return <FaTruckArrowRight size="1.4rem" className="warehouse_send_icon" />
                </>
              }
              buttonTitle2={
                <>
                  Sell <FaTruckArrowRight size="1.4rem" className="warehouse_send_icon" />
                </>
              }
            />
            <CardBody className="table_card_body">
              {loading ? (
                <LoadingSkeleton />
              ) : (
                <Table className="align-items-center table-flush new_table" hover responsive>
                  <TableHead tableHeadData={tableHeadData} />
                  <tbody>
                    {displayedstocks &&
                      displayedstocks.map((tableStock, i) => {
                        const tableIndex = (currentPage - 1) * itemsPerPage + i;
                        return (
                          <WarehouseTableRow
                            tableIndex={tableIndex}
                            tableStock={tableStock}
                            key={tableStock.Id}
                            handleOrderchange={handleOrderchange}
                            checkvalues={checkvalues}
                          />
                        );
                      })}
                  </tbody>
                </Table>
              )}
            </CardBody>
            <CardFooter>
              <NewPagination
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                handleItemsPerPageChange={handleItemsPerPageChange}
                itemsPerPageLabel="Transactions Per Page:"
              />
            </CardFooter>
          </Card>
        </Container>
      </DashboardLayout>
      <WarehouseReturnModal
        returnModal={returnModal}
        setReturnModal={setReturnModal}
        returnProductsModal={returnProductsModal}
        checkvalues={checkvalues}
        setCheckValues={setCheckValues}
        fetchwarehouseStocks={fetchwarehouseStocks}
      />
      <WarehouseSellModal
        sellModal={sellModal}
        setSellModal={setSellModal}
        sellProductsModal={sellProductsModal}
        checkvalues={checkvalues}
        setCheckValues={setCheckValues}
        fetchwarehouseStocks={fetchwarehouseStocks}
      />
    </>
  );
};

export default Warehouse_Stock;
