import React from "react";
import { Spinner } from "reactstrap";
import "./loader.css";

const Loader = () => {
  return (
    <>
      <div className="spinner_con">
        <Spinner className="spinner" color="info">
          Loading...
        </Spinner>
      </div>
    </>
  );
};

export default Loader;
