import React, { useEffect, useState } from "react";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import TableHead from "../../../components/Table/TableHead";
import Loader from "../../../components/loader/Loader";
import { Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";
import { notify } from "../../../components/notification/Notification";
import { apiInstance } from "../../../axios/index";
import InputFeild from "../../../components/Modal/InputFeild";
import Form_Modal from "components/Modal/Form_Modal";

const tableHeadData = [
  {
    id: 1,
    header: "No.",
  },
  {
    id: 2,
    header: "SKU",
  },
  {
    id: 3,
    header: "Name",
  },
  {
    id: 4,
    header: "Qty",
  },
  {
    id: 5,
    header: "Amount",
  },
  {
    id: 6,
    header: "Send Qty.",
  },
  {
    id: 7,
    header: "Total",
  },
];

const WarehouseSendModal = ({
  warehouseModal,
  orderProductsModal,
  checkvalues,
  setWarehouseModal,
  setCheckValues,
  selectedWarehouse,
  setSelectedWarehouse,
  fetchProducts,
}) => {
  const [loading, setLoading] = useState(false);
  const [sendModalOpen, setSendModalOpen] = useState(false);
  const [date, setDate] = useState("");
  const [shipmentNo, setShipmentNo] = useState("");
  const [warehouses, setWarehouses] = useState([]);
  const [orderQtys, setOrderQtys] = useState(Array(checkvalues.length).fill(""));

  useEffect(() => {
    setOrderQtys(Array(checkvalues.length).fill(""));
  }, [warehouseModal]);

  const warehouseModalfields = [
    {
      id: "warehouse_add_date",
      label: "Date",
      type: "date",
      value: date,
      onChange: (e) => setDate(e.target.value),
      required: true,
    },
    {
      id: "warehouse_add_shipment",
      label: "Shipment Number",
      type: "text",
      value: shipmentNo,
      onChange: (e) => setShipmentNo(e.target.value),
      required: true,
    },
  ];

  useEffect(() => {
    fetchWarehouses();
  }, []);

  const verifyConfirmModal = () => {
    if (!selectedWarehouse) {
      notify("Please select a Warehouse!", "error");
    } else if (!date) {
      notify("Please select a Date!", "error");
    } else {
      setSendModalOpen(!sendModalOpen);
    }
  };

  // Retrieve Warehouse data
  const fetchWarehouses = async () => {
    try {
      const response = await apiInstance.post("/Warehouse/Get", { id: 0 });

      if (response.data.status === true) {
        const warehouseData = response.data.Data.map((warehouse) => ({
          Id: warehouse.Id,
          Name: warehouse.Name,
        }));

        setWarehouses(warehouseData);
      } else {
        console.error("Error: " + response.data.Message);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching Warehouse:", error);
      setLoading(false);
    }
  };

  // order Quantity
  const handleOrderQtyChange = (e, index) => {
    const newValue = e.target.value;
    const maxQty = checkvalues[index].Qty;
    const updatedOrderQtys = [...orderQtys];

    if (newValue === "" || (Number(newValue) >= 0 && Number(newValue) <= maxQty)) {
      updatedOrderQtys[index] = newValue === "" ? "" : Number(newValue);
      setOrderQtys(updatedOrderQtys);
    }
  };

  const orderItems = checkvalues.map((orderItem, index) => ({
    Id: orderItem.Id,
    Qty: orderQtys[index],
    Amount: orderQtys[index] ? orderItem.Mrp * orderQtys[index] : 0,
    index: index,
  }));

  useEffect(() => {
    orderItems;
  }, [orderItems]);

  // Sending order Items
  const sendProducts = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const products = checkvalues.map((sendItem, index) => ({
        ProductId: sendItem.Id,
        Qty: orderQtys[index],
      }));

      await apiInstance.post("/Warehouse/Send", {
        WarehouseId: selectedWarehouse,
        Date: date,
        Products: products,
        ShipmentNumber: shipmentNo,
      });
      notify("Products sent successfully", "success");
    } catch (error) {
      console.error("Error sending products", error);
      notify("Error occured while sending", "error");
    }
    setLoading(false);
    setCheckValues([]);
    setOrderQtys([]);
    setWarehouseModal(false);
    setSendModalOpen(false);
    fetchProducts();
  };

  const totalOrderQty = orderQtys.reduce((sum, orderQty) => sum + Number(orderQty), 0);
  const totalAmount = orderItems.reduce((sum, orderItem) => sum + orderItem.Amount, 0);

  const allInputsFilled = orderQtys.every((value) => value === "" || value == undefined);

  return (
    <>
      {loading && <Loader />}
      <Modal
        isOpen={warehouseModal}
        toggle={orderProductsModal}
        className="modal-xl warehouse_add_modal"
        centered
      >
        <ModalHeader toggle={orderProductsModal} className="userTable_header">
          <ArgonTypography color="text" fontWeight="medium" variant="d3">
            Sending Products
          </ArgonTypography>
          <div className="select_con col-md-6">
            <div>
              <div>
                {warehouses && (
                  <>
                    <label htmlFor="warehouse_select">
                      <ArgonTypography color="text" fontWeight="medium" variant="caption">
                        Warehouse
                      </ArgonTypography>
                    </label>
                    <select
                      className="pagination_select"
                      id="warehouse_select"
                      value={selectedWarehouse}
                      onChange={(e) => {
                        setSelectedWarehouse(e.target.value);
                      }}
                      required
                    >
                      <option value="" disabled>
                        -- Select Warehouse --
                      </option>
                      {warehouses.map((warehouse) => (
                        <option value={warehouse.Id} key={warehouse.Id}>
                          {warehouse.Name}
                        </option>
                      ))}
                    </select>
                  </>
                )}
              </div>
              <div className="warehouse_date" style={{ display: "flex", gap: "20px" }}>
                {warehouseModalfields &&
                  warehouseModalfields.map((inwardField) => {
                    return <InputFeild inwardField={inwardField} key={inwardField.id} />;
                  })}
              </div>
            </div>
          </div>
        </ModalHeader>
        <form className="needs-validation new_form" id="form_details">
          <ModalBody>
            <Table className="align-items-center table-flush new_table" hover responsive>
              <TableHead tableHeadData={tableHeadData} />
              <tbody>
                {checkvalues &&
                  checkvalues.map((orderItem, index) => {
                    const orderQty = orderQtys[index];
                    const amount = orderQty ? orderItem.Mrp * orderQty : 0;
                    return (
                      <tr key={orderItem.Id}>
                        <td>
                          <ArgonBox mb={2}>
                            <ArgonTypography color="text" fontWeight="medium" variant="caption">
                              {index + 1}
                            </ArgonTypography>
                          </ArgonBox>
                        </td>
                        <td>
                          <ArgonBox mb={2}>
                            <ArgonTypography color="text" fontWeight="medium" variant="caption">
                              {orderItem.Sku}
                            </ArgonTypography>
                          </ArgonBox>
                        </td>
                        <td>
                          <ArgonBox mb={2}>
                            <ArgonTypography color="text" fontWeight="medium" variant="caption">
                              {orderItem.Name}
                            </ArgonTypography>
                          </ArgonBox>
                        </td>
                        <td>
                          <ArgonBox mb={2}>
                            <ArgonTypography color="text" fontWeight="medium" variant="caption">
                              {orderItem.Qty}
                            </ArgonTypography>
                          </ArgonBox>
                        </td>
                        <td>
                          <ArgonBox mb={2}>
                            <ArgonTypography color="text" fontWeight="medium" variant="caption">
                              {orderItem.Mrp}
                            </ArgonTypography>
                          </ArgonBox>
                        </td>
                        <td style={{ width: "200px" }}>
                          <ArgonBox mb={2}>
                            <ArgonInput
                              size="medium"
                              id={"send_input" + index}
                              placeholder="Send Qty."
                              type="number"
                              className={`form-control form-control-alternative ${
                                orderQtys[index] > checkvalues[index].Qty ? "invalid" : ""
                              }`}
                              aria-invalid={
                                orderQtys[index] > checkvalues[index].Qty ? "true" : "false"
                              }
                              value={orderQtys[index] || ""}
                              onChange={(e) => handleOrderQtyChange(e, index)}
                              required
                            />
                          </ArgonBox>
                        </td>
                        <td>
                          <ArgonBox mb={2}>
                            <ArgonTypography color="text" fontWeight="medium" variant="caption">
                              {amount}
                            </ArgonTypography>
                          </ArgonBox>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
              <tbody>
                <tr>
                  <td>
                    <ArgonTypography color="text" fontWeight="medium" variant="button">
                      Total:
                    </ArgonTypography>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <ArgonTypography color="text" fontWeight="medium" variant="button">
                      {totalOrderQty}
                    </ArgonTypography>
                  </td>
                  <td>
                    <ArgonTypography color="text" fontWeight="medium" variant="button">
                      {totalAmount}
                    </ArgonTypography>
                  </td>
                </tr>
              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <ArgonButton
              color="success"
              size="medium"
              type="button"
              onClick={verifyConfirmModal}
              disabled={allInputsFilled}
            >
              Send
            </ArgonButton>
          </ModalFooter>
        </form>
      </Modal>
      <Form_Modal
        handleVerify={sendProducts}
        deleteModalOpen={sendModalOpen}
        toggleDeleteModal={verifyConfirmModal}
        modalDesc="Are you sure about Send?"
        callToAction="Send"
      />
    </>
  );
};

export default WarehouseSendModal;
