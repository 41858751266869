import ArgonTypography from "../../../components/ArgonTypography";
import { Checkbox } from "@mui/material";

const WarehouseTableRow = ({ tableIndex, tableStock, handleOrderchange, checkvalues }) => {
  const isChecked = checkvalues.some((item) => item.Id == tableStock.Id);
  const isQtyZero = tableStock.Qty === 0;

  return (
    <>
      <tr
        key={tableStock.Id}
        className={`${isChecked ? "rowSelected" : ""} ${isQtyZero ? "rowOpacity" : ""}`}
      >
        <td style={{ width: "70px" }}>
          <Checkbox
            label="Outlined"
            variant="outlined"
            value={tableStock.Id}
            checked={isChecked}
            disabled={isQtyZero}
            onChange={(e) =>
              handleOrderchange(
                tableStock.Id,
                tableStock.ProductId,
                tableStock.Sku,
                tableStock.Amount,
                tableStock.Qty,
                tableStock.ReturnQty,
                tableStock.SaleQty,
                tableStock.WarehouseId,
                tableStock.WarehouseName,
                e.target.checked
              )
            }
          />
        </td>
        <td>
          <ArgonTypography variant="caption" color="dark">
            {tableIndex + 1}
          </ArgonTypography>
        </td>
        <td>
          <ArgonTypography variant="caption" color="dark">
            {tableStock.Sku}
          </ArgonTypography>
        </td>
        <td>
          <ArgonTypography variant="caption" color="dark">
            {tableStock.Amount}
          </ArgonTypography>
        </td>
        <td>
          <ArgonTypography variant="caption" color="dark">
            {tableStock.Qty}
          </ArgonTypography>
        </td>
        <td>
          <ArgonTypography variant="caption" color="dark">
            {tableStock.ReturnQty}
          </ArgonTypography>
        </td>
        <td>
          <ArgonTypography variant="caption" color="dark">
            {tableStock.SaleQty}
          </ArgonTypography>
        </td>
        <td>
          <ArgonTypography variant="button" color="info" fontWeight="bold" textGradient>
            {tableStock.WarehouseName}
          </ArgonTypography>
        </td>
      </tr>
    </>
  );
};

export default WarehouseTableRow;
