// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

function Breadcrumbs({ icon, title, light }) {
  return (
    <ArgonBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: ({ palette: grey }) => grey[600],
          },
        }}
      >
        <Link to="/dashboard">
          <ArgonTypography component="span" variant="body2" color="dark" sx={{ lineHeight: 0 }}>
            <Icon>{icon}</Icon>
          </ArgonTypography>
        </Link>
        <ArgonTypography
          variant="button"
          fontWeight="bold"
          textTransform="capitalize"
          color="dark"
          sx={{ lineHeight: 0 }}
        >
          {title.replace("-", " ")}
        </ArgonTypography>
      </MuiBreadcrumbs>
    </ArgonBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
