import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import NewPagination from "../../../components/Pagination/NewPagination";
import Loader from "../../../components/loader/Loader";
import LoadingSkeleton from "../../../components/loader/LoadingSkeleton";
import Form_Modal from "components/Modal/Form_Modal";
import UserModal from "./UserModal";

import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import { green, red } from "@mui/material/colors";
import { apiInstance } from "../../../axios/index";
import Notification, { notify } from "../../../components/notification/Notification";
import ArgonTypography from "components/ArgonTypography";

import { Card, CardFooter, Container, Table, CardBody } from "reactstrap";
import TableHead from "../../../components/Table/TableHead";
import CardHead from "../../../components/Card/CardHead";
import { useNavigate } from "react-router-dom";

const tableHeadData = [
  {
    id: 0,
    header: "No.",
  },
  {
    id: 2,
    header: "First Name",
  },
  {
    id: 3,
    header: "Last Name",
  },
  {
    id: 4,
    header: "Email",
  },
  {
    id: 5,
    header: "Type",
  },
  {
    id: 6,
    header: "Mobile",
  },
  {
    id: 7,
    header: "",
  },
];

const User = () => {
  const [loading, setLoading] = useState(false);
  const [newModal, setNewModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [editUser, setEditUser] = useState(null);
  const [type, setType] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const navigate = useNavigate();

  const handleSearch = (e) => {
    const searchString = e.target.value.toLowerCase();
    const searchResults = users.filter(
      ({ Firstname, Lastname, Email, Mobile }) =>
        (Firstname ?? "").toLowerCase().includes(searchString) ||
        (Lastname ?? "").toLowerCase().includes(searchString) ||
        (Email ?? "").toLowerCase().includes(searchString) ||
        (Mobile ?? "").toLowerCase().includes(searchString)
    );
    setFilterData(searchResults);
    setCurrentPage(1);
  };

  // Delete Modal
  const toggleDeleteModal = (Id) => {
    setDeleteModalOpen(!deleteModalOpen);
    Id && setDeleteItemId(Id);
    console.log(Id);
  };

  // opening or closeing a modal
  const toggleAddModal = () => {
    setType("");
    setFirstName("");
    setLastName("");
    setPassword("");
    setEmail("");
    setMobile("");
    setNewModal(!newModal);
    setEditUser(null);
  };

  // submitting a form
  const handleSubmit = async (e) => {
    e.preventDefault();

    // create user object
    const user = {
      Id: 0,
      Firstname: firstName,
      Lastname: lastName,
      Password: password,
      Email: email,
      Mobile: mobile,
      Type: type,
    };

    try {
      if (editUser) {
        await apiInstance.post(`/api/SaveUser`, {
          Id: editUser.Id,
          Firstname: firstName,
          Lastname: lastName,
          Email: email,
          Mobile: mobile,
          Type: type,
        });
        notify("User Updated successfully", "success");
      } else {
        await apiInstance.post(`/api/SaveUser`, {
          Id: user.Id,
          Firstname: user.Firstname,
          Lastname: user.Lastname,
          Password: user.Password,
          Email: user.Email,
          Mobile: user.Mobile,
          Type: user.Type,
        });
        notify("User Created successfully", "success");
      }

      // Clear form fields and close modal
      setFirstName("");
      setLastName("");
      setPassword("");
      setEmail("");
      setMobile("");
      setEditUser(null);
      setNewModal(false);

      // Fetch updated user data
      fetchUsers();
    } catch (error) {
      console.error("Error saving user:", error.response);
    }
  };

  // Retrieve users data
  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await apiInstance.post("/api/GetUser", {
        id: 0,
      });

      if (response.data.status === true) {
        const usersData = response.data.Data.map((fetchUser) => ({
          Id: fetchUser.Id,
          Firstname: fetchUser.Firstname,
          Lastname: fetchUser.Lastname,
          Type: fetchUser.Type,
          Password: fetchUser.Password,
          Email: fetchUser.Email,
          Mobile: fetchUser.Mobile,
        }));
        setUsers(usersData);
        setFilterData(usersData);
      } else {
        console.error("Error: " + response.data.Message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notify("Your session just expired!", "error");
        setTimeout(() => {
          navigate("/authentication/logout");
        }, 5000);
      } else {
        console.error(error);
      }
    }
    setLoading(false);
  };

  // editing a data
  const handleEdit = (editUser) => {
    setFirstName(editUser.Firstname);
    setLastName(editUser.Lastname);
    setEmail(editUser.Email);
    setMobile(editUser.Mobile);
    setType(editUser.Type);
    setEditUser(editUser);
    setNewModal(true);
  };

  // deleting a data
  const handleDelete = async (e) => {
    e.preventDefault();
    const userId = deleteItemId;
    console.log(userId);
    try {
      await apiInstance.post(`/api/DeleteUser`, {
        id: userId,
      });
      notify("User Deleted successfully", "success");
    } catch (error) {
      console.error("Error deleting user:", error);
      notify("Error Deleting User", "error");
    }
    setDeleteModalOpen(!deleteModalOpen);
    fetchUsers();
    setDeleteItemId(null);
  };

  // for Pagination
  const totalItems = filterData ? filterData.length : 0;

  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);
  const displayedUsers = filterData.slice(startIndex - 1, endIndex);

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(parseInt(value));
    setCurrentPage(1);
  };

  return (
    <>
      <Notification />
      {loading && <Loader />}
      <DashboardLayout>
        <DashboardNavbar />
        <Container className="mt--7 zone_con con" fluid>
          <Card className="shadow">
            <CardHead
              toggleAddModal={toggleAddModal}
              handleSearch={handleSearch}
              cardHeader="User"
            />
            <CardBody className="table_card_body">
              {loading ? (
                <LoadingSkeleton />
              ) : (
                <Table className="align-items-center table-flush new_table" hover responsive>
                  <TableHead tableHeadData={tableHeadData} />
                  <tbody>
                    {displayedUsers &&
                      displayedUsers.map((tableUser, i) => {
                        const index2 = (currentPage - 1) * itemsPerPage + i;
                        return (
                          <tr key={index2}>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {index2 + 1}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableUser.Firstname}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableUser.Lastname}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableUser.Email}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography
                                variant="button"
                                color="info"
                                fontWeight="bold"
                                textGradient
                              >
                                {tableUser.Type}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableUser.Mobile}
                              </ArgonTypography>
                            </td>
                            <td className="usertable_buttons">
                              <button className="btn edit_btn">
                                <CreateIcon
                                  onClick={() => handleEdit(tableUser)}
                                  fontSize="small"
                                  sx={{ color: green[500] }}
                                />
                              </button>
                              <button className="btn delete_btn">
                                <DeleteIcon
                                  onClick={() => toggleDeleteModal(tableUser.Id)}
                                  fontSize="small"
                                  sx={{ color: red[600] }}
                                />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              )}
            </CardBody>
            <CardFooter>
              <NewPagination
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                handleItemsPerPageChange={handleItemsPerPageChange}
                itemsPerPageLabel="Users Per Page:"
              />
            </CardFooter>
          </Card>
        </Container>
      </DashboardLayout>
      {deleteModalOpen && (
        <Form_Modal
          handleDelete={handleDelete}
          deleteModalOpen={deleteModalOpen}
          toggleDeleteModal={toggleDeleteModal}
          modalDesc="Are you sure you want to Delete?"
          callToAction="Delete"
        />
      )}
      {newModal && (
        <UserModal
          newModal={newModal}
          toggleAddModal={toggleAddModal}
          handleSubmit={handleSubmit}
          type={type}
          setType={setType}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          password={password}
          setPassword={setPassword}
          email={email}
          setEmail={setEmail}
          mobile={mobile}
          setMobile={setMobile}
        />
      )}
    </>
  );
};

export default User;
