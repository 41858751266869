import React from "react";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import InputFeild from "../../../components/Modal/InputFeild";

import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const WarehouseModal = ({ newModal, toggleAddModal, handleSubmit, name, setName }) => {
  const warehouseModalfields = [
    {
      id: "warehouse_name",
      label: "Warehouse Name",
      type: "text",
      value: name,
      onChange: (e) => setName(e.target.value),
      required: true,
    },
  ];
  return (
    <>
      <Modal isOpen={newModal} toggle={toggleAddModal} className="modal-md" centered>
        <ModalHeader toggle={toggleAddModal} className="userTable_header">
          <ArgonTypography color="text" fontWeight="medium" variant="d2">
            Add / Edit Warehouse
          </ArgonTypography>
        </ModalHeader>
        <form className="needs-validation new_form" id="form_details" onSubmit={handleSubmit}>
          <ModalBody>
            <div className="form-row">
              {warehouseModalfields &&
                warehouseModalfields.map((warehouseField) => {
                  return <InputFeild zoneField={warehouseField} key={warehouseField.id} />;
                })}
            </div>
          </ModalBody>
          <ModalFooter>
            <ArgonButton color="success" size="medium" type="submit" className="">
              Save
            </ArgonButton>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default WarehouseModal;
