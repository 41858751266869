import React, { useState } from "react";
import ArgonTypography from "../../../components/ArgonTypography";
import { apiInstance } from "../../../axios";
import { Menu, MenuItem, IconButton } from "@mui/material";
import { notify } from "../../../components/notification/Notification";
import CheckTwoToneIcon from "@mui/icons-material/CheckTwoTone";
import AccessTimeTwoToneIcon from "@mui/icons-material/AccessTimeTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import Form_Modal from "../../../components/Modal/Form_Modal";
import ArgonBadge from "../../../components/ArgonBadge";
import ArgonBox from "../../../components/ArgonBox";

import { MdPictureAsPdf, MdRemoveRedEye, MdMoreVert } from "react-icons/md";

const AdminOrderTableRow = ({
  tableIndex,
  order,
  toggleDetailsModal,
  fetchOrderData,
  generateProductPdf,
}) => {
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [pendingModalOpen, setPendingModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleApproveModal = () => {
    setApproveModalOpen(!approveModalOpen);
  };
  const togglePendingModal = () => {
    setPendingModalOpen(!pendingModalOpen);
  };
  const toggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };

  const verifyConfirmModal = (event) => {
    console.log(order.Id);
    const menuOption = event.currentTarget.getAttribute("data-menu-option");

    if (menuOption === "Complete") {
      setApproveModalOpen(true);
    } else if (menuOption === "Pending") {
      setPendingModalOpen(true);
    } else if (menuOption === "Delete") {
      setDeleteModalOpen(true);
    }
    setAnchorEl(null);
  };

  const handleApproveOrder = (orderId) => {
    apiInstance
      .post("/SaleOrder/UpdateOrderStatus", {
        Id: orderId,
        Status: "Complete",
      })
      .then((response) => {
        if (response.data.Status === true) {
          console.log(response.data.Message);
          notify(response.data.Message, "success");
          fetchOrderData();
        } else {
          console.log("Verification failed: " + response.data.Message);
          notify(response.data.Message, "error");
        }
      })
      .catch((error) => {
        console.error(error);
        notify(response.data.Message, "error");
      });

    setApproveModalOpen(!approveModalOpen);
    setAnchorEl(null);
  };

  const handlePendingOrder = (orderId) => {
    apiInstance
      .post("/SaleOrder/UpdateOrderStatus", {
        id: orderId,
        Status: "Pending",
      })
      .then((response) => {
        if (response.data.Status === true) {
          console.log(response.data.Message);
          notify(response.data.Message, "success");
          fetchOrderData();
        } else {
          console.log("Verification failed: " + response.data.Message);
          notify(response.data.Message, "error");
        }
      })
      .catch((error) => {
        console.error(error);
        notify(response.data.Message, "error");
      });
    setPendingModalOpen(!pendingModalOpen);
    setAnchorEl(null);
  };

  const handleDeleteOrder = (orderId) => {
    apiInstance
      .post("/SaleOrder/UpdateOrderStatus", {
        id: orderId,
        Status: "Cancel",
      })
      .then((response) => {
        if (response.data.Status === true) {
          console.log(response.data.Message);
          notify(response.data.Message, "success");
          fetchOrderData();
        } else {
          console.log("Verification failed: " + response.data.Message);
          notify(response.data.Message, "error");
        }
      })
      .catch((error) => {
        console.error(error);
        notify(response.data.Message, "error");
      });
    setDeleteModalOpen(!deleteModalOpen);
    setAnchorEl(null);
  };

  return (
    <tr key={order.Id}>
      <td>
        <ArgonTypography color="dark" variant="caption">
          {tableIndex + 1}
        </ArgonTypography>
      </td>
      <td>
        <ArgonTypography color="dark" variant="caption">
          {order.Seller}
        </ArgonTypography>
      </td>
      <td>
        <ArgonTypography color="dark" variant="caption">
          {order.OrderNumber}
        </ArgonTypography>
      </td>
      <td>
        <ArgonTypography color="dark" variant="caption">
          {order.OrderDate}
        </ArgonTypography>
      </td>
      <td>
        <ArgonTypography color="dark" variant="caption">
          {order.Qty}
        </ArgonTypography>
      </td>
      <td>
        <ArgonTypography color="dark" variant="caption">
          {order.Amount}
        </ArgonTypography>
      </td>
      <td>
        <ArgonTypography color="dark" variant="caption">
          {order.CreatedByUser}
        </ArgonTypography>
      </td>
      <td>
        <ArgonTypography color="dark" variant="caption">
          {order.CustomerName}
        </ArgonTypography>
      </td>
      <td>
        <ArgonTypography color="dark" variant="caption">
          {order.CustomerMobile}
        </ArgonTypography>
      </td>
      <td>
        <ArgonTypography color="dark" variant="caption">
          {order.CustomerAddress}
        </ArgonTypography>
      </td>
      <td>
        <ArgonTypography color="dark" variant="caption">
          {order.UserType}
        </ArgonTypography>
      </td>
      <td>
        <ArgonTypography color="dark" variant="caption">
          {order.Warehouse}
        </ArgonTypography>
      </td>
      <td>
        <ArgonBox className="order_badge">
          {order.Status === "Complete" && (
            <ArgonBadge
              variant="gradient"
              badgeContent="Completed"
              color="success"
              size="xs"
              container
            />
          )}
          {order.Status === "Pending" && (
            <ArgonBadge
              variant="gradient"
              badgeContent="Pending"
              color="warning"
              size="xs"
              container
            />
          )}
          {order.Status === "Cancel" && (
            <ArgonBadge
              variant="gradient"
              badgeContent="Canceled"
              color="error"
              size="xs"
              container
            />
          )}
        </ArgonBox>
      </td>
      <td className="admin_addOrder_btn_con">
        <div className="admin_addOrder_status_btn">
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MdMoreVert color="black" />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            className="order_dropdown_menu"
          >
            <MenuItem onClick={verifyConfirmModal} data-menu-option="Pending">
              <AccessTimeTwoToneIcon />
              Pending
            </MenuItem>
            <MenuItem onClick={verifyConfirmModal} data-menu-option="Complete">
              <CheckTwoToneIcon />
              Complete
            </MenuItem>
            <MenuItem onClick={verifyConfirmModal} data-menu-option="Delete">
              <DeleteTwoToneIcon />
              Cancel
            </MenuItem>
          </Menu>
        </div>
        <div className="admin_addOrder_btn">
          <button onClick={() => toggleDetailsModal(order.Id, order.OrderNumber, order.OrderDate)}>
            <MdRemoveRedEye />
          </button>
        </div>
        <div className="admin_addOrder_btn">
          <button onClick={() => generateProductPdf(order.Id)}>
            <MdPictureAsPdf />
          </button>
        </div>
      </td>
      <Form_Modal
        toggleDeleteModal={toggleApproveModal}
        handleApproveOrder={handleApproveOrder}
        deleteModalOpen={approveModalOpen}
        modalDesc="Are you sure about Complete?"
        orderId={order.Id}
      />
      <Form_Modal
        toggleDeleteModal={togglePendingModal}
        handlePendingOrder={handlePendingOrder}
        deleteModalOpen={pendingModalOpen}
        modalDesc="Are you sure about Pending?"
        orderId={order.Id}
      />
      <Form_Modal
        toggleDeleteModal={toggleDeleteModal}
        handleDeleteOrder={handleDeleteOrder}
        deleteModalOpen={deleteModalOpen}
        modalDesc="Are you sure about Cancel?"
        orderId={order.Id}
      />
    </tr>
  );
};

export default AdminOrderTableRow;
