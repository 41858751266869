import React from "react";
import ArgonTypography from "../../../components/ArgonTypography";
import ArgonBadge from "../../../components/ArgonBadge";
import ArgonBox from "../../../components/ArgonBox";
import { Table } from "reactstrap";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TableHead from "../../../components/Table/TableHead";

const tableHeadData = [
  {
    id: 1,
    header: "No.",
  },
  {
    id: 3,
    header: "Order Number",
  },
  {
    id: 4,
    header: "Order Date",
  },
  {
    id: 5,
    header: "Qty",
  },
  {
    id: 6,
    header: "Amount",
  },
  {
    id: 7,
    header: "Status",
  },
  {
    id: 8,
    header: "",
  },
];

const OrderTable = ({ orderFilterData, currentPage, itemsPerPage, toggleDetailsModal }) => {
  return (
    <Table className="table-flush new_table" responsive hover>
      {orderFilterData.length > 0 ? (
        <>
          <TableHead tableHeadData={tableHeadData} />
          <tbody>
            {orderFilterData.map((order, index) => {
              const tableIndex = (currentPage - 1) * itemsPerPage + index;
              return (
                <tr key={order.Id}>
                  <td>
                    <ArgonTypography color="dark" variant="caption">
                      {tableIndex + 1}
                    </ArgonTypography>
                  </td>
                  <td>
                    <ArgonTypography color="dark" variant="caption">
                      {order.OrderNumber}
                    </ArgonTypography>
                  </td>
                  <td>
                    <ArgonTypography color="dark" variant="caption">
                      {order.OrderDate}
                    </ArgonTypography>
                  </td>
                  <td>
                    <ArgonTypography color="dark" variant="caption">
                      {order.Qty}
                    </ArgonTypography>
                  </td>
                  <td>
                    <ArgonTypography color="dark" variant="caption">
                      {order.Amount}
                    </ArgonTypography>
                  </td>
                  <td>
                    <ArgonBox className="order_badge">
                      {order.Status === "Complete" && (
                        <ArgonBadge
                          variant="gradient"
                          badgeContent="Completed"
                          color="success"
                          size="xs"
                          container
                        />
                      )}
                      {order.Status === "Pending" && (
                        <ArgonBadge
                          variant="gradient"
                          badgeContent="Pending"
                          color="warning"
                          size="xs"
                          container
                        />
                      )}
                      {order.Status === "Cancel" && (
                        <ArgonBadge
                          variant="gradient"
                          badgeContent="Canceled"
                          color="error"
                          size="xs"
                          container
                        />
                      )}
                    </ArgonBox>
                  </td>
                  <td className="inward_verify_btn">
                    <button
                      onClick={() =>
                        toggleDetailsModal(order.Id, order.OrderNumber, order.OrderDate)
                      }
                    >
                      <VisibilityIcon style={{ marginTop: "2px" }} />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </>
      ) : (
        <thead>
          <tr>
            <th>
              <ArgonTypography variant="caption" color="secondary" fontWeight="bold">
                No Data Available
              </ArgonTypography>
            </th>
          </tr>
        </thead>
      )}
    </Table>
  );
};

export default OrderTable;
