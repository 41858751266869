import React, { useState } from "react";
import ArgonTypography from "../../../components/ArgonTypography";
import ArgonButton from "../../../components/ArgonButton";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Card,
  CardBody,
  CardFooter,
} from "reactstrap";
import TableHead from "../../../components/Table/TableHead";
import ReturnVerifyModal from "./ReturnVerifyModal";
import { notify } from "../../../components/notification/Notification";
import { apiInstance } from "../../../axios/index";
import { VscVerifiedFilled } from "react-icons/vsc";
import NewPagination from "../../../components/Pagination/NewPagination";
import ArgonInput from "components/ArgonInput";
import { FcSearch } from "react-icons/fc";

const tableHeadData = [
  {
    id: 1,
    header: "No.",
  },
  {
    id: 3,
    header: "Request Date",
  },
  {
    id: 4,
    header: "OrderId",
  },
  {
    id: 5,
    header: "Shipment Date",
  },
  {
    id: 6,
    header: "Sku",
  },
  {
    id: 7,
    header: "Fnsku",
  },
  {
    id: 8,
    header: "Disposition",
  },

  {
    id: 10,
    header: "Carrier",
  },
  {
    id: 11,
    header: "Tracking No.",
  },
  {
    id: 9,
    header: "Qty",
  },
  {
    id: 12,
    header: "Return Qty.",
  },
  {
    id: 13,
    header: "Verify Type",
  },
  {
    id: 14,
    header: "Verify By",
  },
  {
    id: 15,
    header: "Verify Date",
  },
  {
    id: 16,
    header: "Status",
  },
];

const ReturnEntryModal = ({
  toggleModal,
  modal,
  entryModalData,
  fetchEntryModalData,
  modalFilterData,
  setModalFilterData,
  selectedModalReturnNo,
}) => {
  const [verifyModal, setVerifyModal] = useState(false);
  const [selectedVerifyModalId, setSelectedVerifyModalId] = useState(0);
  const [selectedVerifyModalSku, setSelectedVerifyModalSku] = useState(0);
  const [selectedVerifyModalQty, setSelectedVerifyModalQty] = useState(0);
  const [selectedAction, setSelectedAction] = useState("");
  const [verifyQty, setVerifyQty] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);

  // search functionality
  const handleSearch = (e) => {
    const searchString = e.target.value.toLowerCase();
    const searchResults = entryModalData.filter(
      ({
        RequestDate,
        OrderId,
        Sku,
        ShipmentDate,
        Fnsku,
        Disposition,
        Qty,
        Carrier,
        TrackingNumber,
        VerifyType,
        VerifyByName,
        VerifyDatetime,
      }) =>
        (RequestDate ?? "").includes(searchString) ||
        (ShipmentDate ?? "").includes(searchString) ||
        (TrackingNumber ?? "").includes(searchString) ||
        (VerifyDatetime ?? "").includes(searchString) ||
        (OrderId ?? "").toLowerCase().includes(searchString) ||
        (Disposition ?? "").toLowerCase().includes(searchString) ||
        (Sku ?? "").toLowerCase().includes(searchString) ||
        (Carrier ?? "").toLowerCase().includes(searchString) ||
        (VerifyType ?? "").toLowerCase().includes(searchString) ||
        (VerifyByName ?? "").toLowerCase().includes(searchString) ||
        (Qty ? Qty.toString() : "").includes(searchString) ||
        (Fnsku ?? "").toLowerCase().includes(searchString)
    );
    setModalFilterData(searchResults);
    setCurrentPage(1);
  };

  const toggleVerifyModal = (Id, Sku, Qty) => {
    setSelectedVerifyModalId(Id);
    setSelectedVerifyModalSku(Sku);
    setSelectedVerifyModalQty(Qty);
    setVerifyModal(!verifyModal);
    setVerifyQty(0);
    setSelectedAction("");
  };

  // Update Warehouse Return Product
  const handleUpdateProduct = async (e) => {
    e.preventDefault();
    if (verifyQty > selectedVerifyModalQty) {
      notify("Return Qty. shouldn't be more than Qty.", "error");
    } else {
      try {
        await apiInstance.post("/Warehouse/UpdateWarehouseReturnProduct", {
          id: selectedVerifyModalId,
          sku: selectedVerifyModalSku,
          qty: verifyQty,
          action: selectedAction,
        });
        notify("Return Products updated successfully", "success");
      } catch (error) {
        console.error("Error sending products", error);
        notify("Error updating returned products", "error");
      }
      setVerifyModal(!verifyModal);
      fetchEntryModalData();
    }
  };

  // for Pagination
  const totalItems = modalFilterData ? modalFilterData.length : 0;

  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);
  const returnModalFilterData = modalFilterData.slice(startIndex - 1, endIndex);

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(parseInt(value));
    setCurrentPage(1);
  };

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggleModal}
        className="modal-xl inward_modal return_entry_modal"
        centered
        style={{ minWidth: "90%" }}
      >
        <Card>
          <ModalHeader toggle={toggleModal} className="warehouse_return_modal">
            <div style={{ display: "flex", gap: "50px" }}>
              <ArgonTypography color="text" fontWeight="medium" variant="d3">
                Warehouse Retrun Product List
              </ArgonTypography>
              <ArgonTypography color="dark" variant="button" fontWeight="medium">
                Return No: {selectedModalReturnNo}
              </ArgonTypography>
            </div>
            <ArgonInput
              size="small"
              placeholder="Search"
              type="search"
              name="Search"
              className="heading_searchbar heading_input_child"
              aria-invalid="false"
              onChange={handleSearch}
              startAdornment={<FcSearch style={{ marginRight: "10px" }} size="1.5rem" />}
            />
          </ModalHeader>
          <CardBody className="inward_modal_card_body">
            <ModalBody>
              {returnModalFilterData.length > 0 ? (
                <Table className="table-flush new_table" responsive hover>
                  <TableHead tableHeadData={tableHeadData} />
                  <>
                    <tbody>
                      {returnModalFilterData &&
                        returnModalFilterData.map((entry, index) => {
                          return (
                            <tr key={entry.Id}>
                              <td>
                                <ArgonTypography color="dark" variant="caption">
                                  {index + 1}
                                </ArgonTypography>
                              </td>
                              <td>
                                <ArgonTypography color="dark" variant="caption">
                                  {entry.RequestDate.split("T")[0]}
                                </ArgonTypography>
                              </td>
                              <td>
                                <ArgonTypography color="dark" variant="caption">
                                  {entry.OrderId}
                                </ArgonTypography>
                              </td>
                              <td>
                                <ArgonTypography color="dark" variant="caption">
                                  {entry.ShipmentDate.split("T")[0]}
                                </ArgonTypography>
                              </td>
                              <td>
                                <ArgonTypography
                                  color="info"
                                  fontWeight="medium"
                                  variant="caption"
                                  textGradient
                                >
                                  {entry.Sku}
                                </ArgonTypography>
                              </td>
                              <td>
                                <ArgonTypography color="dark" variant="caption">
                                  {entry.Fnsku}
                                </ArgonTypography>
                              </td>
                              <td>
                                <ArgonTypography color="dark" variant="caption">
                                  {entry.Disposition}
                                </ArgonTypography>
                              </td>
                              <td>
                                <ArgonTypography color="dark" variant="caption">
                                  {entry.Carrier}
                                </ArgonTypography>
                              </td>
                              <td>
                                <ArgonTypography color="dark" variant="caption">
                                  {entry.TrackingNumber}
                                </ArgonTypography>
                              </td>
                              <td>
                                <ArgonTypography color="dark" variant="caption">
                                  {entry.Qty}
                                </ArgonTypography>
                              </td>
                              <td>
                                <ArgonTypography color="dark" variant="caption">
                                  {entry.VerifyQty !== null
                                    ? entry.VerifyQty
                                    : entry.DemageQty !== null
                                    ? entry.DemageQty
                                    : entry.MissingQty !== null
                                    ? entry.MissingQty
                                    : ""}
                                </ArgonTypography>
                              </td>
                              <td>
                                <ArgonTypography
                                  color="info"
                                  fontWeight="medium"
                                  variant="caption"
                                  textGradient
                                >
                                  {entry.VerifyType}
                                </ArgonTypography>
                              </td>
                              <td>
                                <ArgonTypography color="dark" variant="caption">
                                  {entry.VerifyByName}
                                </ArgonTypography>
                              </td>
                              <td>
                                <ArgonTypography color="dark" variant="caption">
                                  {entry.VerifyDatetime}
                                </ArgonTypography>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {entry.isVerify === 1 ? (
                                  <span>
                                    <VscVerifiedFilled size="1.6rem" color="green" />
                                  </span>
                                ) : (
                                  <ArgonButton
                                    color="info"
                                    size="small"
                                    type="button"
                                    style={{ width: "60px" }}
                                    onClick={() =>
                                      toggleVerifyModal(entry.Id, entry.Sku, entry.Qty)
                                    }
                                  >
                                    Verify
                                  </ArgonButton>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </>
                </Table>
              ) : (
                <div style={{ textAlign: "center" }}>
                  <ArgonTypography variant="button" color="dark" fontWeight="bold">
                    No Data Available
                  </ArgonTypography>
                </div>
              )}
            </ModalBody>
          </CardBody>
          <CardFooter>
            <NewPagination
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
              itemsPerPageLabel="Return Entries Per Page:"
            />
          </CardFooter>
        </Card>
      </Modal>
      <ReturnVerifyModal
        toggleVerifyModal={toggleVerifyModal}
        verifyModal={verifyModal}
        selectedAction={selectedAction}
        setSelectedAction={setSelectedAction}
        verifyQty={verifyQty}
        setVerifyQty={setVerifyQty}
        handleUpdateProduct={handleUpdateProduct}
        selectedVerifyModalSku={selectedVerifyModalSku}
        selectedVerifyModalQty={selectedVerifyModalQty}
      />
    </>
  );
};

export default ReturnEntryModal;
