import { useEffect, useState } from "react";
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import ArgonBox from "components/ArgonBox";
import { useArgonController, setMiniSidenav } from "context";
import Form_Modal from "../../components/Modal/Form_Modal";
import SidenavRoot from "./SidenavRoot";
import PropTypes from "prop-types";
import Divider from "@mui/material/Divider";
import "./sidenav.css";

import {
  PiMonitor,
  PiFolderNotchMinusThin,
  PiArchiveBoxThin,
  PiListChecks,
  PiWarehouseLight,
  PiWarehouseDuotone,
} from "react-icons/pi";
import { CiImport, CiViewList, CiLogout, CiUser } from "react-icons/ci";
import { TbCategory } from "react-icons/tb";
import { BsSmartwatch, BsJournalCheck, BsCartPlus, BsCart3, BsCart4 } from "react-icons/bs";
import { LuPackagePlus, LuPackageCheck } from "react-icons/lu";
import { IoLocationOutline } from "react-icons/io5";
import { IoMdListBox } from "react-icons/io";
import { TbUserCheck, TbUserDollar, TbFileAlert } from "react-icons/tb";
import { ImFileText2 } from "react-icons/im";
import { FaTruckArrowRight } from "react-icons/fa6";
import { MdOutlineAssignmentReturned } from "react-icons/md";

import { Sidebar, Menu, MenuItem, SubMenu, menuClasses } from "react-pro-sidebar";

const themes = {
  light: {
    sidebar: {
      backgroundColor: "#ffffff",
      color: "#607489",
    },
    menu: {
      menuContent: "#fbfcfd",
      icon: "#0098e5",
      hover: {
        backgroundColor: "#c5e4ff",
        color: "#44596e",
      },
      disabled: {
        color: "#9fb6cf",
      },
    },
  },
};

const hexToRgba = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

function Sidenav({ color, brand, routes, userType, ...rest }) {
  const [controller, dispatch] = useArgonController();
  const { miniSidenav, layout } = controller;
  const [modalOpen1, setModalOpen1] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [hasImage, setHasImage] = useState(false);
  const [theme, setTheme] = useState("light");

  const Navigate = useNavigate();
  const handleLogout = () => {
    Navigate("/authentication/logout");
  };
  const location = useLocation();

  // modal
  const toggleModal1 = () => {
    setModalOpen1(!modalOpen1);
  };

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1400);
    }

    function handleResize() {
      handleMiniSidenav();
    }

    handleMiniSidenav();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch, setMiniSidenav]);

  // sidebar styles
  const menuItemStyles = {
    root: {
      fontSize: "14px",
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(themes[theme].menu.menuContent, hasImage && !collapsed ? 0.4 : 1)
          : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      "&:hover": {
        backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, hasImage ? 0.8 : 1),
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  const active = (path) => {
    return location.pathname === path;
  };

  const linearGradientBackground =
    "linear-gradient(21deg, rgb(219 235 245) 0%, rgba(29, 243, 253, 0.5) 50%, rgba(69, 124, 252, 0.5) 100%)";

  return (
    <>
      <SidenavRoot
        {...rest}
        variant="permanent"
        ownerState={{ miniSidenav, layout }}
        className={`sidebar ${miniSidenav ? "sidebar_close" : "sidebar_open"}`}
      >
        <ArgonBox pt={3} pb={1} px={4} textAlign="center">
          <ArgonBox
            component={NavLink}
            to="/dashboard"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {brand && (
              <ArgonBox
                component="img"
                src={brand}
                alt="Argon Logo"
                width="2rem"
                mr={0.25}
                className="sidebar_logo"
              />
            )}
          </ArgonBox>
        </ArgonBox>
        <Divider />
        <div style={{ display: "flex", height: "100%" }}>
          <Sidebar
            onBackdropClick={() => setMiniSidenav(dispatch, true)}
            backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, hasImage ? 0.9 : 1)}
            rootStyles={{
              color: themes[theme].sidebar.color,
            }}
            width={"230px"}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <div style={{ flex: 1, marginBottom: "32px" }}>
                <Menu menuItemStyles={menuItemStyles} className="sidebar_menu_con">
                  <MenuItem
                    icon={<PiMonitor size="1.5rem" />}
                    component={<Link to="/dashboard" />}
                    className="sidebar_menu"
                    style={{ background: active("/dashboard") ? linearGradientBackground : "" }}
                  >
                    Dashboard
                  </MenuItem>
                  {(userType === "admin" || userType === "warehouse") && (
                    <SubMenu
                      label="Master"
                      icon={<PiFolderNotchMinusThin size="1.5rem" />}
                      className="sidebar_menu"
                      style={{
                        transition: "all 0.3s ease",
                        background:
                          active("/master/zone") ||
                          active("/master/user") ||
                          active("/master/seller") ||
                          active("/master/product-category") ||
                          active("/master/warehouse")
                            ? linearGradientBackground
                            : "",
                      }}
                    >
                      {userType === "admin" && (
                        <>
                          <MenuItem
                            icon={<IoLocationOutline size="1.5rem" />}
                            component={<Link to="/master/zone" />}
                            style={{
                              background: active("/master/zone") ? linearGradientBackground : "",
                              display: miniSidenav ? "none" : "flex",
                              transition: "all 0.3s ease",
                            }}
                          >
                            Zone
                          </MenuItem>
                          <MenuItem
                            icon={<TbUserCheck size="1.5rem" />}
                            component={<Link to="/master/user" />}
                            style={{
                              background: active("/master/user") ? linearGradientBackground : "",
                              display: miniSidenav ? "none" : "flex",
                              transition: "all 0.3s ease",
                            }}
                          >
                            User
                          </MenuItem>
                          <MenuItem
                            icon={<TbUserDollar size="1.5rem" />}
                            component={<Link to="/master/seller" />}
                            style={{
                              background: active("/master/seller") ? linearGradientBackground : "",
                              display: miniSidenav ? "none" : "flex",
                              transition: "all 0.3s ease",
                            }}
                          >
                            Sellers
                          </MenuItem>
                          <MenuItem
                            icon={<TbCategory size="1.3rem" />}
                            component={<Link to="/master/product-category" />}
                            style={{
                              background: active("/master/product-category")
                                ? linearGradientBackground
                                : "",
                              display: miniSidenav ? "none" : "flex",
                              transition: "all 0.3s ease",
                            }}
                          >
                            Category
                          </MenuItem>
                        </>
                      )}
                      {(userType === "admin" || userType === "warehouse") && (
                        <MenuItem
                          icon={<PiWarehouseLight size="1.5rem" />}
                          component={<Link to="/master/warehouse" />}
                          style={{
                            background: active("/master/warehouse") ? linearGradientBackground : "",
                            display: miniSidenav ? "none" : "flex",
                            transition: "all 0.3s ease",
                          }}
                        >
                          Warehouse
                        </MenuItem>
                      )}
                    </SubMenu>
                  )}
                  {userType === "admin" && (
                    <SubMenu
                      label="Product"
                      icon={<BsSmartwatch size="1.4rem" />}
                      className="sidebar_menu"
                      style={{
                        transition: "all 0.3s ease",
                        background:
                          active("/product") || active("/import-product")
                            ? linearGradientBackground
                            : "",
                      }}
                    >
                      <MenuItem
                        icon={<PiListChecks size="1.5rem" />}
                        component={<Link to="/product" />}
                        style={{
                          background: active("/product") ? linearGradientBackground : "",
                          display: miniSidenav ? "none" : "flex",
                          transition: "all 0.3s ease",
                        }}
                      >
                        List
                      </MenuItem>
                      <MenuItem
                        icon={<PiArchiveBoxThin size="1.5rem" />}
                        component={<Link to="/import-product" />}
                        style={{
                          background: active("/import-product") ? linearGradientBackground : "",
                          display: miniSidenav ? "none" : "flex",
                          transition: "all 0.3s ease",
                        }}
                      >
                        Import Product
                      </MenuItem>
                    </SubMenu>
                  )}
                  {/* inward list */}
                  {(userType === "admin" || userType === "warehouse") && (
                    <SubMenu
                      label="Inward"
                      icon={<BsJournalCheck size="1.3rem" />}
                      className="sidebar_menu"
                      style={{
                        transition: "all 0.3s ease",
                        background:
                          active("/inward") || active("/import-file")
                            ? linearGradientBackground
                            : "",
                      }}
                    >
                      {(userType === "admin" || userType === "warehouse") && (
                        <MenuItem
                          icon={<CiViewList size="1.5rem" />}
                          component={<Link to="/inward" />}
                          style={{
                            background: active("/inward") ? linearGradientBackground : "",
                            display: miniSidenav ? "none" : "flex",
                            transition: "all 0.3s ease",
                          }}
                        >
                          List
                        </MenuItem>
                      )}

                      {userType === "admin" && (
                        <MenuItem
                          icon={<CiImport size="1.5rem" />}
                          component={<Link to="/import-file" />}
                          style={{
                            background: active("/import-file") ? linearGradientBackground : "",
                            display: miniSidenav ? "none" : "flex",
                            transition: "all 0.3s ease",
                          }}
                        >
                          Import File
                        </MenuItem>
                      )}
                    </SubMenu>
                  )}
                  {/* seller product list */}
                  {userType === "seller" && (
                    <SubMenu
                      label="Product"
                      icon={<BsSmartwatch size="1.4rem" />}
                      className="sidebar_menu"
                      style={{
                        transition: "all 0.3s ease",
                        background: active("/seller-products") ? linearGradientBackground : "",
                      }}
                    >
                      <MenuItem
                        icon={<PiListChecks size="1.5rem" />}
                        component={<Link to="/seller-products" />}
                        style={{
                          background: active("/seller-products") ? linearGradientBackground : "",
                          display: miniSidenav ? "none" : "flex",
                          transition: "all 0.3s ease",
                        }}
                      >
                        List
                      </MenuItem>
                    </SubMenu>
                  )}
                  {/* Warehouse add & stock */}
                  {(userType === "admin" || userType === "warehouse") && (
                    <SubMenu
                      label="Warehouse"
                      icon={<PiWarehouseDuotone size="1.3rem" />}
                      className="sidebar_menu"
                      style={{
                        transition: "all 0.3s ease",
                        background:
                          active("/warehouse-add") ||
                          active("/warehouse-stock") ||
                          active("/warehouse-entry")
                            ? linearGradientBackground
                            : "",
                      }}
                    >
                      <MenuItem
                        icon={<LuPackagePlus size="1.4rem" />}
                        component={<Link to="/warehouse-add" />}
                        style={{
                          background: active("/warehouse-add") ? linearGradientBackground : "",
                          display: miniSidenav ? "none" : "flex",
                          transition: "all 0.3s ease",
                        }}
                      >
                        Send To
                      </MenuItem>
                      <MenuItem
                        icon={<IoMdListBox size="1.6rem" />}
                        component={<Link to="/warehouse-entry" />}
                        style={{
                          background: active("/warehouse-entry") ? linearGradientBackground : "",
                          display: miniSidenav ? "none" : "flex",
                          transition: "all 0.3s ease",
                        }}
                      >
                        Entry
                      </MenuItem>
                      <MenuItem
                        icon={<LuPackageCheck size="1.4rem" />}
                        component={<Link to="/warehouse-stock" />}
                        style={{
                          background: active("/warehouse-stock") ? linearGradientBackground : "",
                          display: miniSidenav ? "none" : "flex",
                          transition: "all 0.3s ease",
                        }}
                      >
                        Stock
                      </MenuItem>
                    </SubMenu>
                  )}
                  {/* Warehouse Return */}
                  {(userType === "admin" || userType === "warehouse") && (
                    <SubMenu
                      label="Warehouse Return"
                      icon={<FaTruckArrowRight size="1.3rem" className="warehouse_return_icon" />}
                      className="sidebar_menu"
                      style={{
                        transition: "all 0.3s ease",
                        background:
                          active("/warehouse-return") || active("/warehouse-return-entry")
                            ? linearGradientBackground
                            : "",
                      }}
                    >
                      <MenuItem
                        icon={<MdOutlineAssignmentReturned size="1.6rem" />}
                        component={<Link to="/warehouse-return" />}
                        style={{
                          background: active("/warehouse-return") ? linearGradientBackground : "",
                          display: miniSidenav ? "none" : "flex",
                          transition: "all 0.3s ease",
                        }}
                      >
                        Return
                      </MenuItem>
                      <MenuItem
                        icon={<IoMdListBox size="1.6rem" />}
                        component={<Link to="/warehouse-return-entry" />}
                        style={{
                          background: active("/warehouse-return-entry")
                            ? linearGradientBackground
                            : "",
                          display: miniSidenav ? "none" : "flex",
                          transition: "all 0.3s ease",
                        }}
                      >
                        Entry
                      </MenuItem>
                    </SubMenu>
                  )}
                  {/* Report */}
                  {(userType === "admin" || userType === "warehouse") && (
                    <SubMenu
                      label="Report"
                      icon={<ImFileText2 size="1.3rem" />}
                      className="sidebar_menu"
                      style={{
                        transition: "all 0.3s ease",
                        background: active("/min-qty-alert") ? linearGradientBackground : "",
                      }}
                    >
                      <MenuItem
                        icon={<TbFileAlert size="1.5rem" />}
                        component={<Link to="/min-qty-alert" />}
                        style={{
                          background: active("/min-qty-alert") ? linearGradientBackground : "",
                          display: miniSidenav ? "none" : "flex",
                          transition: "all 0.3s ease",
                        }}
                        className="min_qty_alert_menu"
                      >
                        Min Qty. Alert
                      </MenuItem>
                    </SubMenu>
                  )}
                  {/* seller Order */}
                  {userType === "seller" && (
                    <MenuItem
                      icon={<BsCartPlus size="1.5rem" />}
                      component={<Link to="/order" />}
                      className="sidebar_menu"
                      style={{ background: active("/order") ? linearGradientBackground : "" }}
                    >
                      Order
                    </MenuItem>
                  )}
                  {/* admin order */}
                  {userType === "admin" && (
                    <SubMenu
                      label="Orders"
                      icon={<BsCart3 size="1.3rem" />}
                      className="sidebar_menu"
                      style={{
                        transition: "all 0.3s ease",
                        background:
                          active("/order/add-order") || active("/order/order-list")
                            ? linearGradientBackground
                            : "",
                      }}
                    >
                      <MenuItem
                        icon={<BsCartPlus size="1.4rem" />}
                        component={<Link to="/order/add-order" />}
                        style={{
                          background: active("/order/add-order") ? linearGradientBackground : "",
                          display: miniSidenav ? "none" : "flex",
                          transition: "all 0.3s ease",
                        }}
                      >
                        Add Order
                      </MenuItem>
                      <MenuItem
                        icon={<BsCart4 size="1.4rem" />}
                        component={<Link to="/order/order-list" />}
                        style={{
                          background: active("/order/order-list") ? linearGradientBackground : "",
                          display: miniSidenav ? "none" : "flex",
                          transition: "all 0.3s ease",
                        }}
                      >
                        Order List
                      </MenuItem>
                    </SubMenu>
                  )}

                  <MenuItem
                    icon={<CiUser size="1.5rem" />}
                    component={<Link to="/profile" />}
                    className="sidebar_menu"
                    style={{ background: active("/profile") ? linearGradientBackground : "" }}
                  >
                    Profile
                  </MenuItem>
                  <MenuItem
                    icon={<CiLogout size="1.5rem" />}
                    to="#"
                    className="sidebar_menu logout_link"
                    onClick={toggleModal1}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </Sidebar>
        </div>
      </SidenavRoot>
      <Form_Modal
        deleteModalOpen={modalOpen1}
        toggleDeleteModal={toggleModal1}
        handleLogout={handleLogout}
        modalDesc="Are you sure you want to Logout?"
        callToAction="Logout"
      />
    </>
  );
}

Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
};

export default Sidenav;
