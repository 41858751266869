import React, { useState } from "react";
import { Menu, MenuItem, IconButton, Checkbox, Button } from "@mui/material";
import { MdFilterList } from "react-icons/md";
import ArgonTypography from "components/ArgonTypography";

const OrderFilter = ({
  handleFilterClick,
  openFilter,
  anchorEl,
  setAnchorEl,
  filterData,
  setFilterData,
  fetchOrderData,
}) => {
  const [selectedOptions, setSelectedOptions] = useState({
    User: false,
    Seller: false,
    Warehouse: false,
  });

  const handleFilterClose = () => {
    setAnchorEl(null);
    if (!selectedOptions.User && !selectedOptions.Seller && !selectedOptions.Warehouse) {
      setFilterData(filterData);
      fetchOrderData();
    }
  };

  const handleCheckboxChange = (option) => (event) => {
    fetchOrderData();
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [option]: event.target.checked,
    }));
  };

  const applyFilter = () => {
    const filteredData = filterData.filter(({ UserType }) => {
      if (selectedOptions.User && UserType === "User") return true;
      if (selectedOptions.Seller && UserType === "Seller") return true;
      if (selectedOptions.Warehouse && UserType === "Warehouse") return true;
      return false;
    });

    setFilterData(filteredData);
    handleFilterClose();
  };

  return (
    <>
      <div className="admin_addOrder_status_btn admin_order_filter_button">
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={openFilter ? "long-menu" : undefined}
          aria-expanded={openFilter ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleFilterClick}
        >
          <ArgonTypography variant="d3">
            Filter by User Type <MdFilterList color="black" />
          </ArgonTypography>
        </IconButton>
        <Menu
          id="admin-order-filter-menu"
          anchorEl={anchorEl}
          open={openFilter}
          onClose={handleFilterClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          className="order_dropdown_menu adminOrder"
        >
          <MenuItem data-menu-option="User">
            <Checkbox
              label="Outlined"
              variant="outlined"
              checked={selectedOptions.User}
              onChange={handleCheckboxChange("User")}
            />
            User
          </MenuItem>
          <MenuItem data-menu-option="Seller">
            <Checkbox
              label="Outlined"
              variant="outlined"
              checked={selectedOptions.Seller}
              onChange={handleCheckboxChange("Seller")}
            />
            Seller
          </MenuItem>
          <MenuItem data-menu-option="Warehouse">
            <Checkbox
              label="Outlined"
              variant="outlined"
              checked={selectedOptions.Warehouse}
              onChange={handleCheckboxChange("Warehouse")}
            />
            Warehouse
          </MenuItem>
          <MenuItem>
            <Button onClick={applyFilter}>Apply Filter</Button>
          </MenuItem>
        </Menu>
      </div>
    </>
  );
};

export default OrderFilter;
