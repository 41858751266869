import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { apiInstance } from "../../../axios/index";

// Argon Dashboard 2 MUI components
import ArgonBox from "../../../components/ArgonBox";
import ArgonInput from "../../../components/ArgonInput";
import ArgonButton from "../../../components/ArgonButton";

// Authentication layout components
import IllustrationLayout from "../components/IllustrationLayout";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import dashboardImage from "../../../assets/images/logo.png";
import Notification, { notify } from "../../../components/notification/Notification";
import Loader from "../../../components/loader/Loader";
import Cookies from "js-cookie";
import "../signin.css";

function SignIn() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [passwordType, setPasswordType] = useState("password");

  useEffect(() => {
    const theKey = Cookies.get("theKey");

    if (!theKey) {
      navigate("/authentication/sign-in");
    }
  }, [navigate]);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    setError("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const apiUrl = "/Api/Login";
    const requestData = {
      username,
      password,
    };

    apiInstance
      .post(apiUrl, requestData)
      .then((response) => {
        if (response.data.Status === true) {
          const { AccessToken, Data } = response.data;
          const { Firstname, Lastname, Type, Id } = Data;

          const fullName = `${Firstname} ${Lastname}`;

          Cookies.set("Type", Type);
          Cookies.set("Id", Id);
          Cookies.set("theKey", AccessToken);
          Cookies.set("FullName", fullName);
          navigate("/dashboard");
          window.location.reload(true);
        } else {
          setError("Invalid username or password");
          notify("Invalid Username or Password, Please try again", "error");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setError("An error occurred during login");
        notify("Error during Login, Please try again", "error");
        setLoading(false);
      });
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <>
      <Notification />
      {loading && <Loader />}
      <IllustrationLayout
        title="SIGN IN"
        illustration={{
          title: (
            <>
              Welcome to{" "}
              <img
                src={dashboardImage}
                alt="Dashboard"
                style={{ width: "100px", marginInline: "8px" }}
              />
              Dashboard
            </>
          ),
        }}
      >
        <ArgonBox component="form" role="form">
          <ArgonBox mb={2} className="signin_email_input">
            <ArgonInput
              type="email"
              placeholder="Email"
              size="large"
              id="login_email"
              name="Email"
              onChange={handleUsernameChange}
              error={error ? true : undefined}
              required
            />
          </ArgonBox>
          <ArgonBox mb={2} className="signin_password_input">
            <ArgonInput
              type={passwordType}
              placeholder="Password"
              size="large"
              name="Password"
              id="login_password"
              onChange={handlePasswordChange}
              error={error ? true : undefined}
              required
            />
            <ArgonButton
              onClick={togglePassword}
              color="white"
              size="medium"
              className="signin_password_toggle"
            >
              {passwordType === "password" ? (
                <VisibilityOffIcon className="togglepass_btn" fontSize="medium" color="dark" />
              ) : (
                <VisibilityIcon className="togglepass_btn" fontSize="medium" color="dark" />
              )}
            </ArgonButton>
          </ArgonBox>
          <ArgonBox mt={4} mb={1}>
            <ArgonButton
              color={error ? "error" : "info"}
              size="large"
              type="submit"
              disabled={!password || !username}
              onClick={handleSubmit}
              fullWidth
            >
              SIGN IN
            </ArgonButton>
          </ArgonBox>
        </ArgonBox>
      </IllustrationLayout>
    </>
  );
}

export default SignIn;
