import React, { useState } from "react";
import ArgonTypography from "../../components/ArgonTypography";
import ArgonBadge from "../../components/ArgonBadge";
import ArgonBox from "../../components/ArgonBox";
import { Table } from "reactstrap";
import TableHead from "components/Table/TableHead";
import { IoMdTrash, IoMdEye } from "react-icons/io";
import { RiFileExcel2Line } from "react-icons/ri";
import { apiInstance } from "../../axios/index";
import { notify } from "../../components/notification/Notification";
import Form_Modal from "../../components/Modal/Form_Modal";
import Cookies from "js-cookie";

const tableHeadData = [
  {
    id: 1,
    header: "No.",
  },
  {
    id: 3,
    header: "Import Number",
  },
  {
    id: 4,
    header: "Description",
  },
  {
    id: 5,
    header: "Date",
  },
  {
    id: 6,
    header: "Status",
  },
  {
    id: 7,
    header: "",
  },
];

const InwardTable = ({
  inwardFilterData,
  toggleVerifyModal,
  currentPage,
  itemsPerPage,
  fetchInwardData,
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  // Delete Modal
  const toggleDeleteModal = (Id) => {
    setDeleteModalOpen(!deleteModalOpen);
    Id && setDeleteItemId(Id);
  };

  // deleting a data
  const handleDelete = async (e) => {
    e.preventDefault();
    const inwardId = deleteItemId;
    await apiInstance
      .post("/Inward/DeleteInward", { id: inwardId })
      .then((response) => {
        if (response.data.Status === true) {
          notify("Inward deleted successfully", "success");
        } else {
          notify("Failed to delete Inward data", "error");
        }
      })
      .catch((error) => {
        notify("Failed to delete Inward data", "error");
        console.error("Error occurred while deleting Inward data:", error);
      });
    setDeleteModalOpen(!deleteModalOpen);
    fetchInwardData();
    setDeleteItemId(null);
  };

  const handleExportClick = (Id) => {
    const baseApiUrl = apiInstance.defaults.baseURL;
    const exportPath = `/Inward/CartonBoxExport?inwardId=${Id}`;

    const fullUrl = `${baseApiUrl}${exportPath}`;

    window.location.href = fullUrl;
  };

  // User roles
  const userCookieType = Cookies.get("Type");
  const [userType] = useState(userCookieType);

  return (
    <>
      <Table className="table-flush new_table" responsive hover>
        {inwardFilterData.length > 0 ? (
          <>
            <TableHead tableHeadData={tableHeadData} />
            <tbody>
              {inwardFilterData.map((inward, index) => {
                const tableIndex = (currentPage - 1) * itemsPerPage + index;
                return (
                  <tr key={inward.Id}>
                    <td>
                      <ArgonTypography color="dark" variant="caption">
                        {tableIndex + 1}
                      </ArgonTypography>
                    </td>
                    <td>
                      <ArgonTypography color="dark" variant="caption">
                        {inward.ImportNumber}
                      </ArgonTypography>
                    </td>
                    <td>
                      <ArgonTypography color="dark" variant="caption">
                        {inward.Description}
                      </ArgonTypography>
                    </td>
                    <td>
                      <ArgonTypography color="dark" variant="caption">
                        {inward.InwardDate}
                      </ArgonTypography>
                    </td>
                    <td>
                      <ArgonBox className="order_badge">
                        {inward.Status === "Completed" && (
                          <ArgonBadge
                            variant="gradient"
                            badgeContent="Completed"
                            color="success"
                            size="xs"
                            container
                          />
                        )}
                        {inward.Status === "Pending" && (
                          <ArgonBadge
                            variant="gradient"
                            badgeContent="Pending"
                            color="warning"
                            size="xs"
                            container
                          />
                        )}
                      </ArgonBox>
                    </td>
                    <td className="inward_verify_btn">
                      <button
                        onClick={() =>
                          toggleVerifyModal(inward.Id, inward.ImportNumber, inward.InwardDate)
                        }
                        className="btn delete_btn"
                      >
                        <IoMdEye size="1.5rem" />
                      </button>
                      {userType === "admin" && (
                        <button className="btn delete_btn">
                          <IoMdTrash
                            onClick={() => toggleDeleteModal(inward.Id)}
                            color="red"
                            size="1.4rem"
                          />
                        </button>
                      )}
                      <button
                        onClick={() => handleExportClick(inward.Id)}
                        className="btn delete_btn"
                      >
                        <RiFileExcel2Line size="1.5rem" color="green" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </>
        ) : (
          <thead>
            <tr>
              <th>
                <ArgonTypography variant="caption" color="secondary" fontWeight="bold">
                  No Data Available
                </ArgonTypography>
              </th>
            </tr>
          </thead>
        )}
      </Table>
      {deleteModalOpen && (
        <Form_Modal
          handleDelete={handleDelete}
          deleteModalOpen={deleteModalOpen}
          toggleDeleteModal={toggleDeleteModal}
          modalDesc="Are you sure you want to Delete?"
          callToAction="Delete"
        />
      )}
    </>
  );
};

export default InwardTable;
