import React from "react";
import ArgonTypography from "../../../components/ArgonTypography";

import { Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";
import TableHead from "../../../components/Table/TableHead";

const tableHeadData = [
  {
    id: 1,
    header: "No.",
  },
  {
    id: 2,
    header: "Sku",
  },
  {
    id: 3,
    header: "Qty",
  },
  {
    id: 4,
    header: "Warehouse Name",
  },
  {
    id: 5,
    header: "Amount",
  },
  {
    id: 6,
    header: "Color",
  },
];

const EntryModal = ({ toggleModal, modal, entryModalData }) => {
  return (
    <Modal isOpen={modal} toggle={toggleModal} className="modal-xl inward_modal" centered>
      <ModalHeader toggle={toggleModal}>
        <ArgonTypography color="text" fontWeight="medium" variant="d3">
          Warehouse Product List
        </ArgonTypography>
      </ModalHeader>
      <ModalBody>
        {entryModalData.length > 0 ? (
          <Table className="table-flush new_table" responsive hover>
            <TableHead tableHeadData={tableHeadData} />

            <>
              <tbody>
                {entryModalData &&
                  entryModalData.map((entry, index) => {
                    return (
                      <tr key={entry.Id}>
                        <td>
                          <ArgonTypography color="dark" variant="caption">
                            {index}
                          </ArgonTypography>
                        </td>
                        <td>
                          <ArgonTypography color="dark" variant="caption">
                            {entry.Sku}
                          </ArgonTypography>
                        </td>
                        <td>
                          <ArgonTypography color="dark" variant="caption">
                            {entry.Qty}
                          </ArgonTypography>
                        </td>
                        <td>
                          <ArgonTypography color="dark" variant="caption">
                            {entry.WarehouseName}
                          </ArgonTypography>
                        </td>
                        <td>
                          <ArgonTypography color="dark" variant="caption">
                            {entry.Amount}
                          </ArgonTypography>
                        </td>
                        <td>
                          <ArgonTypography color="dark" variant="caption">
                            {entry.Color}
                          </ArgonTypography>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </>
          </Table>
        ) : (
          <div style={{ textAlign: "center" }}>
            <ArgonTypography variant="button" color="dark" fontWeight="bold">
              No Data Available
            </ArgonTypography>
          </div>
        )}
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  );
};

export default EntryModal;
