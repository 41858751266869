import React, { useEffect, useState } from "react";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import TableHead from "../../../components/Table/TableHead";
import Loader from "../../../components/loader/Loader";
import { Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";
import { notify } from "../../../components/notification/Notification";
import { apiInstance } from "../../../axios/index";
import Form_Modal from "components/Modal/Form_Modal";
import InputFeild from "../../../components/Modal/InputFeild";

const tableHeadData = [
  {
    id: 1,
    header: "No.",
  },
  {
    id: 2,
    header: "SKU",
  },
  {
    id: 3,
    header: "Warehouse",
  },
  {
    id: 4,
    header: "Amount",
  },
  {
    id: 5,
    header: "Qty",
  },
  {
    id: 6,
    header: "Sell Qty.",
  },
  {
    id: 7,
    header: "Total",
  },
];

const WarehouseSellModal = ({
  sellModal,
  setSellModal,
  checkvalues,
  setCheckValues,
  sellProductsModal,
  fetchwarehouseStocks,
}) => {
  const [loading, setLoading] = useState(false);
  const [sellModalOpen, setSellModalOpen] = useState(false);
  const [date, setDate] = useState("");
  const [orderQtys, setOrderQtys] = useState(Array(checkvalues.length).fill(""));

  useEffect(() => {
    setOrderQtys(Array(checkvalues.length).fill(""));
  }, [sellModal]);

  const verifyConfirmModal = () => {
    if (!date) {
      notify("Please select a Date!", "error");
    } else {
      setSellModalOpen(!sellModalOpen);
    }
  };

  const warehouseModalfields = [
    {
      id: "warehouse_sell_date",
      label: "Date",
      type: "date",
      value: date,
      onChange: (e) => setDate(e.target.value),
      required: true,
    },
  ];

  // sell Quantity
  const handleOrderQtyChange = (e, index) => {
    const newValue = e.target.value;
    const maxQty = checkvalues[index].Qty;
    const updatedOrderQtys = [...orderQtys];

    if (newValue === "" || (Number(newValue) >= 0 && Number(newValue) <= maxQty)) {
      updatedOrderQtys[index] = newValue === "" ? "" : Number(newValue);
      setOrderQtys(updatedOrderQtys);
    }
  };

  const orderItems = checkvalues.map((orderItem, index) => ({
    Id: orderItem.Id,
    Qty: orderQtys[index],
    Amount: orderQtys[index] ? orderItem.Amount * orderQtys[index] : 0,
    index: index,
  }));

  useEffect(() => {
    orderItems;
  }, [orderItems]);

  const totalOrderQty = orderQtys.reduce((sum, orderQty) => sum + Number(orderQty), 0);
  const totalAmount = orderItems.reduce((sum, orderItem) => sum + orderItem.Amount, 0);

  // selling Items
  const sellProducts = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const products = checkvalues.map((sendItem, index) => ({
        WarehouseProductId: sendItem.Id,
        ProductId: sendItem.ProductId,
        Qty: orderQtys[index],
      }));

      const warehouseId = checkvalues.length > 0 ? checkvalues[0].WarehouseId : null;

      await apiInstance.post("/SaleOrder/Save", {
        Qty: totalOrderQty,
        Amount: totalAmount,
        UserType: "Warehouse",
        WarehouseId: warehouseId,
        OrderDate: date,
        Products: products,
      });
      notify("Products sold successfully", "success");
    } catch (error) {
      console.error("Error selling products", error);
      notify("Error occured while selling", "error");
    }
    setLoading(false);
    setCheckValues([]);
    setOrderQtys([]);
    setSellModal(false);
    setSellModalOpen(false);
    fetchwarehouseStocks();
  };

  const allInputsFilled = orderQtys.every((value) => value === "" || value == undefined);

  return (
    <>
      {loading && <Loader />}
      <Modal
        isOpen={sellModal}
        toggle={sellProductsModal}
        className="modal-xl warehouse_add_modal"
        centered
      >
        <ModalHeader toggle={sellProductsModal} className="userTable_header">
          <ArgonTypography color="text" fontWeight="medium" variant="d3">
            Selling Products
          </ArgonTypography>
          <div className="select_con col-md-6">
            <div>
              <div className="warehouse_date">
                {warehouseModalfields &&
                  warehouseModalfields.map((inwardField) => {
                    return <InputFeild inwardField={inwardField} key={inwardField.id} />;
                  })}
              </div>
            </div>
          </div>
        </ModalHeader>
        <form className="needs-validation new_form" id="form_details">
          <ModalBody>
            <Table className="align-items-center table-flush new_table" hover responsive>
              <TableHead tableHeadData={tableHeadData} />
              <tbody>
                {checkvalues &&
                  checkvalues.map((orderItem, index) => {
                    const orderQty = orderQtys[index];
                    const amount = orderQty ? orderItem.Amount * orderQty : 0;
                    return (
                      <tr key={orderItem.Id}>
                        <td>
                          <ArgonTypography color="text" fontWeight="medium" variant="caption">
                            {index + 1}
                          </ArgonTypography>
                        </td>
                        <td>
                          <ArgonTypography color="text" fontWeight="medium" variant="caption">
                            {orderItem.Sku}
                          </ArgonTypography>
                        </td>
                        <td>
                          <ArgonTypography
                            variant="button"
                            color="info"
                            fontWeight="bold"
                            textGradient
                          >
                            {orderItem.WarehouseName}
                          </ArgonTypography>
                        </td>
                        <td>
                          <ArgonTypography color="text" fontWeight="medium" variant="caption">
                            {orderItem.Amount}
                          </ArgonTypography>
                        </td>
                        <td>
                          <ArgonTypography color="text" fontWeight="medium" variant="caption">
                            {orderItem.Qty}
                          </ArgonTypography>
                        </td>
                        <td style={{ width: "200px" }}>
                          <ArgonInput
                            size="medium"
                            id={"sell_input" + index}
                            placeholder="Sell Qty."
                            type="number"
                            className={`form-control form-control-alternative ${
                              orderQtys[index] > checkvalues[index].Qty ? "invalid" : ""
                            }`}
                            aria-invalid={
                              orderQtys[index] > checkvalues[index].Qty ? "true" : "false"
                            }
                            value={orderQtys[index] || ""}
                            onChange={(e) => handleOrderQtyChange(e, index)}
                            required
                          />
                        </td>
                        <td>
                          <ArgonTypography color="text" fontWeight="medium" variant="caption">
                            {amount}
                          </ArgonTypography>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
              <tbody>
                <tr>
                  <td>
                    <ArgonTypography color="text" fontWeight="medium" variant="button">
                      Total:
                    </ArgonTypography>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <ArgonTypography color="text" fontWeight="medium" variant="button">
                      {totalOrderQty}
                    </ArgonTypography>
                  </td>
                  <td>
                    <ArgonTypography color="text" fontWeight="medium" variant="button">
                      {totalAmount}
                    </ArgonTypography>
                  </td>
                </tr>
              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <ArgonButton
              color="success"
              size="medium"
              type="button"
              onClick={verifyConfirmModal}
              disabled={allInputsFilled}
            >
              Sell
            </ArgonButton>
          </ModalFooter>
        </form>
      </Modal>
      <Form_Modal
        handleVerify={sellProducts}
        deleteModalOpen={sellModalOpen}
        toggleDeleteModal={verifyConfirmModal}
        modalDesc="Are you sure about Sell?"
        callToAction="Sell"
      />
    </>
  );
};

export default WarehouseSellModal;
