import axios from "axios";
import Cookies from "js-cookie";

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_AXIOS_API,
});

apiInstance.interceptors.request.use((config) => {
  const theKey = Cookies.get("theKey");
  if (theKey) {
    config.headers["Authorization"] = theKey;
  }
  return config;
});

export { apiInstance };
