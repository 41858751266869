import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Cookies from "js-cookie";

// @mui material components
import Card from "@mui/material/Card";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

function Header() {
  const fullName = Cookies.get("FullName");
  return (
    <ArgonBox position="relative">
      <DashboardNavbar absolute light />
      <ArgonBox height="55px" />
      <Card
        sx={{
          py: 2,
          px: 2,
          boxShadow: ({ boxShadows: { md } }) => md,
        }}
        style={{ maxWidth: "500px", marginInline: "auto" }}
      >
        <ArgonBox textAlign="center">
          <ArgonTypography variant="subtitle1" fontWeight="medium" className="Profile_Name">
            {fullName}
          </ArgonTypography>
        </ArgonBox>
      </Card>
    </ArgonBox>
  );
}

export default Header;
