import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import NewPagination from "../../../components/Pagination/NewPagination";
import Loader from "../../../components/loader/Loader";
import LoadingSkeleton from "../../../components/loader/LoadingSkeleton";
import TableHead from "../../../components/Table/TableHead";
import ArgonTypography from "components/ArgonTypography";
import Notification, { notify } from "../../../components/notification/Notification";
import { Card, CardFooter, Container, Table, CardBody } from "reactstrap";
import { apiInstance } from "../../../axios/index";
import CardHead from "../../../components/Card/CardHead";
import { useNavigate } from "react-router-dom";

const tableHeadData = [
  {
    id: 1,
    header: "No.",
  },
  {
    id: 2,
    header: "Sku",
  },
  {
    id: 3,
    header: "Name",
  },
  {
    id: 4,
    header: "Color",
  },
  {
    id: 5,
    header: "Qty",
  },
  {
    id: 6,
    header: "Mrp",
  },
  {
    id: 7,
    header: "Category Name",
  },
  {
    id: 8,
    header: "Damage Qty",
  },
  {
    id: 9,
    header: "Min Qty. Alert",
  },
];

const MinStockAlert = () => {
  const [loading, setLoading] = useState(false);
  const [minQtyAlerts, setMinQtyAlerts] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchQtyAlerts();
  }, []);

  const navigate = useNavigate();

  // search Functionality
  const handleSearch = (e) => {
    const searchString = e.target.value.toLowerCase();
    const searchResults = minQtyAlerts.filter(
      ({ Sku, Name, Color, Qty, Mrp, CategoryName, DemageQty, MinQtyAlert }) =>
        (Sku ?? "").toLowerCase().includes(searchString) ||
        (Name ?? "").toLowerCase().includes(searchString) ||
        (Color ?? "").toLowerCase().includes(searchString) ||
        (Qty ? Qty.toString() : "").includes(searchString) ||
        (Mrp ? Mrp.toString() : "").includes(searchString) ||
        (CategoryName ?? "").toLowerCase().includes(searchString) ||
        (DemageQty ? DemageQty.toString() : "").includes(searchString) ||
        (MinQtyAlert ? MinQtyAlert.toString() : "").includes(searchString)
    );
    setFilterData(searchResults);
    setCurrentPage(1);
  };

  // Retrieve zones data
  const fetchQtyAlerts = async () => {
    setLoading(true);
    try {
      const response = await apiInstance.post("/report/MinStockAlert");

      if (response.data.status === true) {
        const QtyData = response.data.Data.map((alert) => ({
          Id: alert.Id,
          Sku: alert.Sku,
          Name: alert.Name,
          Color: alert.Color,
          Qty: alert.Qty,
          Mrp: alert.Mrp,
          Status: alert.Status,
          Category: alert.Category,
          CategoryName: alert.CategoryName,
          DemageQty: alert.DemageQty,
          MinQtyAlert: alert.MinQtyAlert,
        }));

        setMinQtyAlerts(QtyData);
        setFilterData(QtyData);
      } else {
        console.error("Error: " + response.data.Message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notify("Your session just expired!", "error");
        setTimeout(() => {
          navigate("/authentication/logout");
        }, 5000);
      } else {
        console.error("Error fetching Min Qty Alert:", error);
      }
    }
    setLoading(false);
  };

  const totalItems = filterData ? filterData.length : 0;

  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);
  const displayedQtyAerts = filterData.slice(startIndex - 1, endIndex);

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(parseInt(value));
    setCurrentPage(1);
  };

  return (
    <>
      <Notification />
      {loading && <Loader />}
      <DashboardLayout>
        <DashboardNavbar />
        <Container className="mt--7 minQty_con con" fluid>
          <Card className="shadow">
            <CardHead handleSearch={handleSearch} cardHeader="Min Qty. Alert" />
            <CardBody className="table_card_body">
              {loading ? (
                <LoadingSkeleton />
              ) : (
                <Table className="align-items-center table-flush new_table" hover responsive>
                  <TableHead tableHeadData={tableHeadData} />
                  <tbody>
                    {displayedQtyAerts &&
                      displayedQtyAerts.map((tableQty, i) => {
                        const tableIndex = (currentPage - 1) * itemsPerPage + i;
                        return (
                          <tr key={tableIndex}>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableIndex + 1}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableQty.Sku}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableQty.Name}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableQty.Color}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableQty.Qty}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableQty.Mrp}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography
                                variant="button"
                                color="info"
                                fontWeight="bold"
                                textGradient
                              >
                                {tableQty.CategoryName}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableQty.DemageQty}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableQty.MinQtyAlert}
                              </ArgonTypography>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              )}
            </CardBody>
            <CardFooter>
              <NewPagination
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                handleItemsPerPageChange={handleItemsPerPageChange}
                itemsPerPageLabel="Qty. Alerts Per Page:"
              />
            </CardFooter>
          </Card>
        </Container>
      </DashboardLayout>
    </>
  );
};

export default MinStockAlert;
