import React from "react";
import ArgonTypography from "components/ArgonTypography";
import { Table } from "reactstrap";
import TableHead from "components/Table/TableHead";

const tableHeadData = [
  {
    id: 1,
    header: "No.",
  },
  {
    id: 2,
    header: "Sku",
  },
  {
    id: 3,
    header: "Status",
  },
  {
    id: 4,
    header: "",
  },
];

const ImportTable = ({ verifiedData }) => {
  return (
    <>
      <Table className="table-flush new_table" hover responsive>
        {verifiedData.length > 0 ? (
          <>
            <TableHead tableHeadData={tableHeadData} />
            <tbody>
              {verifiedData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td>
                    <ArgonTypography variant="caption" color="dark">
                      {rowIndex + 1}
                    </ArgonTypography>
                  </td>
                  {Object.values(row).map((cell, cellIndex) => (
                    <td key={cellIndex}>
                      <ArgonTypography variant="caption" color="dark">
                        {cell}
                      </ArgonTypography>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </>
        ) : (
          <thead>
            <tr>
              <th>
                <ArgonTypography variant="caption" color="dark" fontWeight="bold">
                  No Data Available
                </ArgonTypography>
              </th>
            </tr>
          </thead>
        )}
      </Table>
    </>
  );
};

export default ImportTable;
