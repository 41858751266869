import React, { useState } from "react";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { green } from "@mui/material/colors";
import { IoMdTrash } from "react-icons/io";
import { FaPen, FaCheck } from "react-icons/fa6";
import { MdVideocam } from "react-icons/md";
import { apiInstance } from "../../axios/index";
import { notify } from "../../components/notification/Notification";
import Form_Modal from "../../components/Modal/Form_Modal";
import Cookies from "js-cookie";

const InwardRow = ({
  inward,
  index,
  demageQtyinput,
  receivedQtyinput,
  missingQtyinput,
  attachmentinput,
  handleDamageInputChange,
  handleQuantityInputChange,
  handleMissingInputChange,
  handleAttachmentInputChange,
  editModes,
  toggleEditRow,
  onSave,
  hasError,
}) => {
  const [verifyStatus] = useState(inward.Status === "1");
  const [verifyAttachmnetStatus] = useState(inward.AttachmentLink !== "");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  // Delete Modal
  const toggleDeleteModal = (Id) => {
    setDeleteModalOpen(!deleteModalOpen);
    Id && setDeleteItemId(Id);
  };

  // deleting a data
  const handleDelete = async (e) => {
    e.preventDefault();
    const inwardId = deleteItemId;
    await apiInstance
      .post("/Inward/DeleteInwardProduct", { id: inwardId })
      .then((response) => {
        if (response.data.Status === true) {
          notify("Inward deleted successfully", "success");
        } else {
          notify("Failed to delete Inward data", "error");
        }
      })
      .catch((error) => {
        notify("Failed to delete Inward data", "error");
        console.error("Error occurred while deleting Inward data:", error);
      });
    setDeleteModalOpen(!deleteModalOpen);
    fetchInwardData();
    setDeleteItemId(null);
  };

  // User roles
  const userCookieType = Cookies.get("Type");
  const [userType] = useState(userCookieType);

  return (
    <>
      <tr className={`inward_modal_table ${hasError[index] ? "inward-error-edit-row" : ""}`}>
        <td>
          <ArgonTypography color="dark" variant="caption">
            {index + 1}
          </ArgonTypography>
        </td>
        <td>
          <ArgonTypography color="dark" variant="caption">
            {inward.CartonNumber}
          </ArgonTypography>
        </td>
        <td>
          <ArgonTypography color="dark" variant="caption">
            {inward.Sku}
          </ArgonTypography>
        </td>
        <td>
          <ArgonTypography color="dark" variant="caption">
            {inward.Qty}
          </ArgonTypography>
        </td>
        <td className="inward_modal_input_td">
          {verifyStatus && !editModes[index] ? (
            <ArgonTypography color="dark" variant="caption">
              {inward.DemageQty}
            </ArgonTypography>
          ) : (
            <ArgonInput
              type="number"
              value={demageQtyinput[index] || ""}
              onChange={(e) => handleDamageInputChange(e, index)}
            />
          )}
        </td>
        <td className="inward_modal_input_td">
          {verifyStatus && !editModes[index] ? (
            <ArgonTypography color="dark" variant="caption">
              {inward.ReceivedQty}
            </ArgonTypography>
          ) : (
            <ArgonInput
              type="number"
              value={receivedQtyinput[index] || ""}
              onChange={(e) => handleQuantityInputChange(e, index)}
            />
          )}
        </td>
        <td className="inward_modal_input_td">
          {verifyStatus && !editModes[index] ? (
            <ArgonTypography color="dark" variant="caption">
              {inward.MissingQty}
            </ArgonTypography>
          ) : (
            <ArgonInput
              type="number"
              value={missingQtyinput[index] || ""}
              onChange={(e) => handleMissingInputChange(e, index)}
            />
          )}
        </td>
        <td className="inward_modal_input_td">
          {verifyStatus && !editModes[index] ? (
            ""
          ) : (
            <ArgonInput
              type="text"
              value={attachmentinput[index] || ""}
              onChange={(e) => handleAttachmentInputChange(e, index)}
            />
          )}
          {verifyAttachmnetStatus && !editModes[index] ? (
            <a
              href={inward.AttachmentLink}
              className=""
              target="_blank"
              rel="noreferrer"
              style={{ cursor: "pointer" }}
            >
              <MdVideocam size="1.5rem" color="black" />
            </a>
          ) : (
            ""
          )}
        </td>

        <td>
          <ArgonTypography color="dark" variant="caption">
            {inward.VerifiedBy}
          </ArgonTypography>
        </td>
        <td className="inward_modal_verifiedDate">
          <ArgonTypography color="dark" variant="caption">
            {inward.VerifyDate}
          </ArgonTypography>
        </td>
        <td>
          <ArgonTypography color="dark" variant="caption">
            {verifyStatus ? (
              <ArgonButton color="white" type="button" disabled className="inward_modal_verify_btn">
                <CheckCircleRoundedIcon fontSize="medium" sx={{ color: green[700] }} />
              </ArgonButton>
            ) : (
              ""
            )}
          </ArgonTypography>
        </td>
        <td className="inward_verify_btn">
          {userType === "admin" && (
            <button className="btn delete_btn">
              <IoMdTrash onClick={() => toggleDeleteModal(inward.Id)} color="red" size="1.3rem" />
            </button>
          )}
          {verifyStatus && (
            <>
              {editModes[index] ? (
                <button
                  className="btn save_btn"
                  onClick={() => {
                    onSave(index, inward.Id, inward.Sku, inward.Qty);
                    toggleEditRow(index);
                  }}
                >
                  <FaCheck size="1.1rem" color="green" />
                </button>
              ) : (
                <button className="btn delete_btn" onClick={() => toggleEditRow(index)}>
                  <FaPen size="1.1rem" color="green" />
                </button>
              )}
            </>
          )}
        </td>
      </tr>
      {deleteModalOpen && (
        <Form_Modal
          handleDelete={handleDelete}
          deleteModalOpen={deleteModalOpen}
          toggleDeleteModal={toggleDeleteModal}
          modalDesc="Are you sure you want to Delete?"
          callToAction="Delete"
        />
      )}
    </>
  );
};

export default InwardRow;
