import React, { useEffect, useState } from "react";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import TableHead from "../../../components/Table/TableHead";
import Loader from "../../../components/loader/Loader";
import { Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";
import { notify } from "../../../components/notification/Notification";
import { apiInstance } from "../../../axios/index";
import Form_Modal from "components/Modal/Form_Modal";

const tableHeadData = [
  {
    id: 1,
    header: "No.",
  },
  {
    id: 2,
    header: "SKU",
  },
  {
    id: 3,
    header: "Warehouse",
  },
  {
    id: 4,
    header: "Amount",
  },
  {
    id: 5,
    header: "Qty",
  },
  {
    id: 6,
    header: "Return Qty.",
  },
  {
    id: 7,
    header: "Total",
  },
];

const WarehouseReturnModal = ({
  returnModal,
  setReturnModal,
  checkvalues,
  setCheckValues,
  returnProductsModal,
  fetchwarehouseStocks,
}) => {
  const [loading, setLoading] = useState(false);
  const [returnModalOpen, setReturnModalOpen] = useState(false);
  const [orderQtys, setOrderQtys] = useState(Array(checkvalues.length).fill(""));

  useEffect(() => {
    setOrderQtys(Array(checkvalues.length).fill(""));
  }, [returnModal]);

  const verifyConfirmModal = () => {
    setReturnModalOpen(!returnModalOpen);
  };

  // order Quantity
  const handleOrderQtyChange = (e, index) => {
    const newValue = e.target.value;
    const maxQty = checkvalues[index].Qty;
    const updatedOrderQtys = [...orderQtys];

    if (newValue === "" || (Number(newValue) >= 0 && Number(newValue) <= maxQty)) {
      updatedOrderQtys[index] = newValue === "" ? "" : Number(newValue);
      setOrderQtys(updatedOrderQtys);
    }
  };

  const orderItems = checkvalues.map((orderItem, index) => ({
    Id: orderItem.Id,
    Qty: orderQtys[index],
    Amount: orderQtys[index] ? orderItem.Amount * orderQtys[index] : 0,
    index: index,
  }));

  useEffect(() => {
    orderItems;
  }, [orderItems]);

  // Returning Items
  const returnProducts = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const products = checkvalues.map((sendItem, index) => ({
        Id: sendItem.Id,
        ProductId: sendItem.ProductId,
        Qty: orderQtys[index],
      }));

      await apiInstance.post("/Warehouse/Return", {
        Products: products,
      });
      notify("Products returned successfully", "success");
    } catch (error) {
      console.error("Error returning products", error);
      notify("Error occured while returning", "error");
    }
    setLoading(false);
    setCheckValues([]);
    setOrderQtys([]);
    setReturnModal(false);
    setReturnModalOpen(false);
    fetchwarehouseStocks();
  };

  const allInputsFilled = orderQtys.every((value) => value === "" || value == undefined);

  const totalOrderQty = orderQtys.reduce((sum, orderQty) => sum + Number(orderQty), 0);
  const totalAmount = orderItems.reduce((sum, orderItem) => sum + orderItem.Amount, 0);

  return (
    <>
      {loading && <Loader />}
      <Modal
        isOpen={returnModal}
        toggle={returnProductsModal}
        className="modal-xl warehouse_return_modal"
        centered
      >
        <ModalHeader toggle={returnProductsModal} className="userTable_header">
          <ArgonTypography color="text" fontWeight="medium" variant="d3">
            Returning Products
          </ArgonTypography>
        </ModalHeader>
        <form className="needs-validation new_form" id="form_details">
          <ModalBody>
            <Table className="align-items-center table-flush new_table" hover responsive>
              <TableHead tableHeadData={tableHeadData} />
              <tbody>
                {checkvalues &&
                  checkvalues.map((orderItem, index) => {
                    const orderQty = orderQtys[index];
                    const amount = orderQty ? orderItem.Amount * orderQty : 0;
                    return (
                      <tr key={orderItem.Id}>
                        <td>
                          <ArgonTypography color="text" fontWeight="medium" variant="caption">
                            {index + 1}
                          </ArgonTypography>
                        </td>
                        <td>
                          <ArgonTypography color="text" fontWeight="medium" variant="caption">
                            {orderItem.Sku}
                          </ArgonTypography>
                        </td>
                        <td>
                          <ArgonTypography
                            variant="button"
                            color="info"
                            fontWeight="bold"
                            textGradient
                          >
                            {orderItem.WarehouseName}
                          </ArgonTypography>
                        </td>
                        <td>
                          <ArgonTypography color="text" fontWeight="medium" variant="caption">
                            {orderItem.Amount}
                          </ArgonTypography>
                        </td>
                        <td>
                          <ArgonTypography color="text" fontWeight="medium" variant="caption">
                            {orderItem.Qty}
                          </ArgonTypography>
                        </td>
                        <td style={{ width: "200px" }}>
                          <ArgonInput
                            size="medium"
                            id={"return_input" + index}
                            placeholder="Return Qty."
                            type="number"
                            className={`form-control form-control-alternative ${
                              orderQtys[index] > checkvalues[index].Qty ? "invalid" : ""
                            }`}
                            aria-invalid={
                              orderQtys[index] > checkvalues[index].Qty ? "true" : "false"
                            }
                            value={orderQtys[index] || ""}
                            onChange={(e) => handleOrderQtyChange(e, index)}
                            required
                          />
                        </td>
                        <td>
                          <ArgonTypography color="text" fontWeight="medium" variant="caption">
                            {amount}
                          </ArgonTypography>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
              <tbody>
                <tr>
                  <td>
                    <ArgonTypography color="text" fontWeight="medium" variant="button">
                      Total:
                    </ArgonTypography>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <ArgonTypography color="text" fontWeight="medium" variant="button">
                      {totalOrderQty}
                    </ArgonTypography>
                  </td>
                  <td>
                    <ArgonTypography color="text" fontWeight="medium" variant="button">
                      {totalAmount}
                    </ArgonTypography>
                  </td>
                </tr>
              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <ArgonButton
              color="error"
              size="medium"
              type="button"
              onClick={verifyConfirmModal}
              disabled={allInputsFilled}
            >
              Return
            </ArgonButton>
          </ModalFooter>
        </form>
      </Modal>
      <Form_Modal
        handleVerify={returnProducts}
        deleteModalOpen={returnModalOpen}
        toggleDeleteModal={verifyConfirmModal}
        modalDesc="Are you sure about Return?"
        callToAction="Return"
      />
    </>
  );
};

export default WarehouseReturnModal;
