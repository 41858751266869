import React, { useState, useEffect, useCallback } from "react";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import NewPagination from "../../components/Pagination/NewPagination";
import Loader from "../../components/loader/Loader";
import { apiInstance } from "../../axios/index";
import Notification, { notify } from "../../components/notification/Notification";

import { Card, Container, CardBody, CardFooter } from "reactstrap";
import InwardTable from "./InwardTable";
import InwardModal from "./InwardModal";
import CardHead from "../../components/Card/CardHead";
import { useNavigate } from "react-router-dom";

const Inward = () => {
  const [inwardData, setInwardData] = useState([]);
  const [inwardCartoonData, setInwardCartoonData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedModalId, setSelectedModalId] = useState(null);
  const [selectedImportNumber, setSelectedImportNumber] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [modalFilterData, setModalFilterData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchInwardData();
  }, []);

  const navigate = useNavigate();

  // search functionality
  const handleSearch = (e) => {
    const searchString = e.target.value.toLowerCase();
    const searchResults = inwardData.filter(
      ({ ImportNumber, Description, InwardDate, Status }) =>
        (ImportNumber ?? "").toLowerCase().includes(searchString) ||
        (Description ?? "").toLowerCase().includes(searchString) ||
        (InwardDate ?? "").toLowerCase().includes(searchString) ||
        (Status ?? "").toLowerCase().includes(searchString)
    );
    setFilterData(searchResults);
    setCurrentPage(1);
  };

  const toggleVerifyModal = useCallback((Id, ImportNumber, InwardDate) => {
    setSelectedModalId(Id);
    setSelectedImportNumber(ImportNumber);
    setSelectedDate(InwardDate);
    setModal((prevModal) => !prevModal);
  }, []);

  const closeModal = useCallback(() => {
    setModal(false);
    setSelectedModalId(null);
    setSelectedImportNumber(null);
    setSelectedDate(null);
  }, []);

  useEffect(() => {
    fetchInwardCartoonData();
  }, [selectedModalId]);

  // Fetch Inward Data
  const fetchInwardData = async () => {
    setLoading(true);
    try {
      const response = await apiInstance.post("/Inward/GetInward", {
        id: 0,
      });
      if (response.data.status === true) {
        const inwardData = response.data.Data.map((fetchInward) => ({
          Id: fetchInward.Id,
          ImportNumber: fetchInward.ImportNumber,
          Description: fetchInward.Description,
          InwardDate: fetchInward.InwardDate,
          Status: fetchInward.Status,
        }));
        setFilterData(inwardData);
        setInwardData(inwardData);
      } else {
        console.error("Error: " + response.data.Message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notify("Your session just expired!", "error");
        setTimeout(() => {
          navigate("/authentication/logout");
        }, 5000);
      } else {
        console.error(error);
      }
    }
    setLoading(false);
  };

  // fetch Inward Cartoon Box Data
  const fetchInwardCartoonData = async () => {
    setLoading(true);
    try {
      const response = await apiInstance.post("/Inward/GetInwardCartoonBox", {
        inwardId: selectedModalId,
      });
      if (response.data.status === true) {
        const inwardCartoonData = response.data.Data.map((fetchCartoon) => ({
          Id: fetchCartoon.Id,
          InwardId: fetchCartoon.InwardId,
          CartonNumber: fetchCartoon.CartonNumber,
          Sku: fetchCartoon.Sku,
          Color: fetchCartoon.Color,
          Qty: fetchCartoon.Qty,
          Weight: fetchCartoon.Weight,
          Size: fetchCartoon.Size,
          Remark: fetchCartoon.Remark,
          OrderNumber: fetchCartoon.OrderNumber,
          DemageQty: fetchCartoon.DemageQty,
          ReceivedQty: fetchCartoon.ReceivedQty,
          MissingQty: fetchCartoon.MissingQty,
          VerifiedBy: fetchCartoon.VerifiedBy,
          VerifyDate: fetchCartoon.VerifyDate,
          Status: fetchCartoon.Status,
          Price: fetchCartoon.Price,
          AttachmentLink: fetchCartoon.AttachmentLink,
        }));
        setModalFilterData(inwardCartoonData);
        setInwardCartoonData(inwardCartoonData);
      } else {
        console.error("Error: " + response.data.Message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notify("Your session just expired!", "error");
        setTimeout(() => {
          navigate("/authentication/logout");
        }, 5000);
      } else {
        console.error(error);
      }
    }
    setLoading(false);
  };

  // for Pagination
  const totalItems = filterData ? filterData.length : 0;

  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);
  const inwardFilterData = filterData.slice(startIndex - 1, endIndex);

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(parseInt(value));
    setCurrentPage(1);
  };

  return (
    <>
      <Notification />
      {loading && <Loader />}
      <DashboardLayout>
        <DashboardNavbar />
        <Container className="mt--7 inward_list_con con" fluid>
          <Card className="shadow">
            <CardHead handleSearch={handleSearch} cardHeader="Inward" />
            <CardBody className="table_card_body">
              <InwardTable
                inwardFilterData={inwardFilterData}
                toggleVerifyModal={toggleVerifyModal}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                fetchInwardData={fetchInwardData}
              />
            </CardBody>
            <CardFooter>
              <NewPagination
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                handleItemsPerPageChange={handleItemsPerPageChange}
                itemsPerPageLabel="Inward Items Per Page:"
              />
            </CardFooter>
          </Card>
        </Container>
      </DashboardLayout>
      {modal && (
        <InwardModal
          modal={modal}
          inwardCartoonData={inwardCartoonData}
          closeModal={closeModal}
          selectedImportNumber={selectedImportNumber}
          selectedDate={selectedDate}
          fetchInwardCartoonData={fetchInwardCartoonData}
          modalFilterData={modalFilterData}
          setModalFilterData={setModalFilterData}
          selectedModalId={selectedModalId}
        />
      )}
    </>
  );
};

export default Inward;
