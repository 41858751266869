import { useState } from "react";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import { apiInstance } from "../../../axios/index";
import Notification, { notify } from "../../../components/notification/Notification";
import { CardHeader, CardFooter, CardBody } from "reactstrap";
import DownloadIcon from "@mui/icons-material/Download";

import ArgonBox from "../../../components/ArgonBox";
import ArgonTypography from "../../../components/ArgonTypography";
import ArgonButton from "../../../components/ArgonButton";

import { Card, Container, Row } from "reactstrap";
import Loader from "../../../components/loader/Loader";
import ImportTable from "./ImportTable";
const ImportProduct = () => {
  const [verifiedData, setVerifiedData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  // verify Excel
  const handleVerification = () => {
    if (selectedFile) {
      setLoading(true);
      const formData = new FormData();
      formData.append("upload", selectedFile);

      apiInstance
        .post("/api/VerifyImportProduct", formData)
        .then((response) => {
          const { status, Message, data } = response.data;
          if (status === true) {
            setVerifiedData(data);
            notify(Message, "success");
          } else {
            console.error(Message);
            notify(Message, "error");
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          notify(error, "error");
          setLoading(false);
        });
    }
  };

  // import Product Data
  const handleDataSubmission = () => {
    if (verifiedData.length > 0) {
      setLoading(true);

      const formData = new FormData();
      formData.append("upload", selectedFile);

      apiInstance
        .post("/api/ImportProduct", formData)
        .then((response) => {
          const { status, Message } = response.data;
          if (status === true) {
            notify(Message, "success");
            window.location.reload(true);
            setVerifiedData([]);
          } else {
            console.error(Message);
            notify(Message, "error");
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          notify(error, "error");
        });
    } else {
      notify("No verified data available to save", "error");
    }
  };

  const handleFileDownload = () => {
    apiInstance
      .get("/ImportFormat/product.xlsx", {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "product.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error(error);
        notify("Failed to download the file", "error");
      });
  };

  return (
    <>
      <Notification />
      {loading && <Loader />}
      <DashboardLayout>
        <DashboardNavbar />
        <Container className="mt--7 con importProduct_con" fluid>
          <Card className="shadow">
            <CardHeader>
              <ArgonBox
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                py={3}
                px={0.5}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ArgonTypography variant="subtitle2" color="secondary">
                    Import Products from Excel file
                  </ArgonTypography>
                  <ArgonBox className="product_button_con">
                    <div className="product_excel_buttons">
                      <input type="file" onChange={handleFileChange} />
                      <ArgonButton
                        color="success"
                        size="medium"
                        type="button"
                        onClick={handleVerification}
                        disabled={!selectedFile}
                      >
                        Validate
                      </ArgonButton>
                    </div>
                  </ArgonBox>
                </div>
                <div>
                  <ArgonButton
                    color="info"
                    size="medium"
                    type="button"
                    onClick={handleFileDownload}
                    className="download_file_btn"
                  >
                    <DownloadIcon style={{ marginRight: "10px" }} /> Download Import Format
                  </ArgonButton>
                </div>
              </ArgonBox>
            </CardHeader>
            <CardBody className="inward_card_body">
              <ImportTable verifiedData={verifiedData} />
            </CardBody>
            <CardFooter className="card-footer">
              {verifiedData.length > 0 && (
                <ArgonButton
                  color="success"
                  size="medium"
                  type="button"
                  className="import_button"
                  onClick={handleDataSubmission}
                >
                  Import Product Data
                </ArgonButton>
              )}
            </CardFooter>
          </Card>
        </Container>
      </DashboardLayout>
    </>
  );
};

export default ImportProduct;
