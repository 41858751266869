import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import "./css/index1.css";
import PrivateRoute from "./components/PrivateRoute";
import Dashboard from "./layouts/dashboard";
import Profile from "./layouts/profile";
import SignIn from "./layouts/authentication/sign-in/index";
import Logout from "./layouts/authentication/Logout";
import Zone from "./layouts/master/Zone/Zone";
import Seller from "./layouts/master/Seller/Seller";
import User from "./layouts/master/User/User";
import Product from "./layouts/Product/Product";
import ImportProduct from "./layouts/Product/Import Product/ImportProduct";
import ImportFile from "./layouts/Inward/Import File/ImportFile";
import Inward from "./layouts/Inward/Inward";
import Category from "./layouts/master/product-category/Category";
import ProductSeller from "./layouts/productSeller/ProductSeller";
import Order from "./layouts/productSeller/Order/Order";
import Warehouse from "./layouts/master/warehouse/Warehouse";
import Warehouse_add from "./layouts/warehouse/warehouse-add/Warehouse_add";
import Entry from "./layouts/warehouse/entry/Entry";
import Warehouse_Stock from "./layouts/warehouse/warehouse-stock/Warehouse_Stock";
import Return from "./layouts/warehouse-return/return/Return";
import ReturnEntry from "./layouts/warehouse-return/entry/ReturnEntry";
import MinStockAlert from "./layouts/report/minStockAlert/MinStockAlert";
import AdminAddOrder from "./layouts/adminOrder/addOrder/AdminAddOrder";
import AdminOrder from "./layouts/adminOrder/orderList/AdminOrder";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "./examples/Sidenav";

import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import { useArgonController, setMiniSidenav } from "context";
import brand from "assets/images/logo.png";
import "assets/css/nucleo-icons.css";
import "assets/css/nucleo-svg.css";

export default function App() {
  const [controller, dispatch] = useArgonController();
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  const { miniSidenav, direction, layout, sidenavColor, darkMode } = controller;
  const theKey = Cookies.get("theKey");

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      // setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body elements
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // User roles
  const userCookieType = Cookies.get("Type");
  const [userType] = useState(userCookieType);

  return (
    <div className="main_page_div">
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline classes="extra_sidebar_upper" />
        {layout === "dashboard" && theKey && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={brand}
              // onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
              onClick={handleOnMouseEnter}
              userType={userType}
            />
          </>
        )}
        <Routes>
          <Route path="/" element={<Navigate to="/authentication/sign-in" />} />
          <Route exact path="/authentication/sign-in" element={<SignIn />} />
          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            {userType === "admin" && <Route path="/master/zone" element={<Zone />} />}
            {userType === "admin" && <Route path="/master/user" element={<User />} />}
            {userType === "admin" && <Route path="/master/seller" element={<Seller />} />}
            {userType === "admin" && (
              <Route path="/master/product-category" element={<Category />} />
            )}
            {(userType === "admin" || userType === "warehouse") && (
              <Route path="/master/warehouse" element={<Warehouse />} />
            )}
            {userType === "admin" && <Route path="/product" element={<Product />} />}
            {userType === "seller" && <Route path="/seller-products" element={<ProductSeller />} />}
            {userType === "admin" && <Route path="/import-product" element={<ImportProduct />} />}
            {(userType === "admin" || userType === "warehouse") && (
              <Route path="/inward" element={<Inward />} />
            )}
            {userType === "admin" && <Route path="/import-file" element={<ImportFile />} />}
            {(userType === "admin" || userType === "warehouse") && (
              <Route path="/warehouse-add" element={<Warehouse_add />} />
            )}
            {(userType === "admin" || userType === "warehouse") && (
              <Route path="/warehouse-entry" element={<Entry />} />
            )}
            {(userType === "admin" || userType === "warehouse") && (
              <Route path="/warehouse-stock" element={<Warehouse_Stock />} />
            )}
            {(userType === "admin" || userType === "warehouse") && (
              <Route path="/warehouse-return" element={<Return />} />
            )}
            {(userType === "admin" || userType === "warehouse") && (
              <Route path="/warehouse-return-entry" element={<ReturnEntry />} />
            )}
            {(userType === "admin" || userType === "warehouse") && (
              <Route path="/min-qty-alert" element={<MinStockAlert />} />
            )}
            {userType === "seller" && <Route path="/order" element={<Order />} />}
            {userType === "admin" && <Route path="order/add-order" element={<AdminAddOrder />} />}
            {userType === "admin" && <Route path="order/order-list" element={<AdminOrder />} />}
            <Route path="/profile" element={<Profile />} />
            <Route path="/authentication/logout" element={<Logout />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </div>
  );
}
