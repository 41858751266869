import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import NewPagination from "../../../components/Pagination/NewPagination";
import Loader from "../../../components/loader/Loader";
import { apiInstance } from "../../../axios/index";
import Notification, { notify } from "../../../components/notification/Notification";
import ArgonTypography from "../../../components/ArgonTypography";
import TableHead from "../../../components/Table/TableHead";
import Form_Modal from "../../../components/Modal/Form_Modal";

import { Card, Container, CardBody, CardFooter, Table } from "reactstrap";
import CardHead from "../../../components/Card/CardHead";
import { useNavigate } from "react-router-dom";
import { IoMdTrash, IoMdEye } from "react-icons/io";
import { RiFileExcel2Line } from "react-icons/ri";
import ReturnEntryModal from "./ReturnEntryModal";

const tableHeadData = [
  {
    id: 1,
    header: "No.",
  },
  {
    id: 2,
    header: "Return No.",
  },
  {
    id: 3,
    header: "Description",
  },
  {
    id: 4,
    header: "Created By",
  },
  {
    id: 5,
    header: "Transaction Date",
  },
  {
    id: 6,
    header: "",
  },
];

const ReturnEntry = () => {
  const [entryData, setEntryData] = useState([]);
  const [entryModalData, setEntryModalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedModalId, setSelectedModalId] = useState(0);
  const [selectedModalReturnNo, setSelectedModalReturnNo] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [modalFilterData, setModalFilterData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchEntryData();
  }, []);

  const navigate = useNavigate();

  // search functionality
  const handleSearch = (e) => {
    const searchString = e.target.value.toLowerCase();
    const searchResults = entryData.filter(
      ({ ReturnNumber, Description, CreatedByName, TransactionDate }) =>
        (ReturnNumber ?? "").toLowerCase().includes(searchString) ||
        (TransactionDate ?? "").includes(searchString) ||
        (Description ?? "").toLowerCase().includes(searchString) ||
        (CreatedByName ?? "").toLowerCase().includes(searchString)
    );
    setFilterData(searchResults);
    setCurrentPage(1);
  };

  // Fetch entry Data
  const fetchEntryData = async () => {
    setLoading(true);
    try {
      const response = await apiInstance.post("/Warehouse/GetWarehouseReturnEntry");

      if (response.data.status === true) {
        const entryData = response.data.Data.map((entry) => ({
          Id: entry.Id,
          ReturnNumber: entry.ReturnNumber,
          ReturnDate: entry.ReturnDate,
          Description: entry.Description,
          Filename: entry.Filename,
          CreatedByName: entry.CreatedByName,
          TransactionDate: entry.TransactionDate,
        }));
        setFilterData(entryData);
        setEntryData(entryData);
      } else {
        console.error("Error: " + response.data.Message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notify("Your session just expired!", "error");
        setTimeout(() => {
          navigate("/authentication/logout");
        }, 5000);
      } else {
        console.error(error);
      }
    }
    setLoading(false);
  };

  const toggleModal = (Id, ReturnNumber) => {
    setSelectedModalId(Id);
    setSelectedModalReturnNo(ReturnNumber);
    setModal(!modal);
  };

  useEffect(() => {
    fetchEntryModalData();
  }, [selectedModalId]);

  // Fetch entry modal data
  const fetchEntryModalData = async () => {
    setLoading(true);
    try {
      const response = await apiInstance.post("/Warehouse/GetWarehouseReturnProducts", {
        id: selectedModalId,
      });

      if (response.data.status === true) {
        const entryData = response.data.Data.map((entry) => ({
          Id: entry.Id,
          ReturnId: entry.ReturnId,
          RequestDate: entry.RequestDate,
          OrderId: entry.OrderId,
          ShipmentDate: entry.ShipmentDate,
          Sku: entry.Sku,
          Fnsku: entry.Fnsku,
          Disposition: entry.Disposition,
          Qty: entry.Qty,
          Carrier: entry.Carrier,
          TrackingNumber: entry.TrackingNumber,
          ShipmentStatus: entry.ShipmentStatus,
          OrderType: entry.OrderType,
          isVerify: entry.isVerify,
          VerifyQty: entry.VerifyQty,
          DemageQty: entry.DemageQty,
          MissingQty: entry.MissingQty,
          VerifyType: entry.VerifyType,
          VerifyByName: entry.VerifyByName,
          VerifyDatetime: entry.VerifyDatetime,
        }));
        setEntryModalData(entryData);
        setModalFilterData(entryData);
      } else {
        console.error("Error: " + response.data.Message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notify("Your session just expired!", "error");
        setTimeout(() => {
          navigate("/authentication/logout");
        }, 5000);
      } else {
        console.error(error);
      }
    }
    setLoading(false);
  };

  // for Pagination
  const totalItems = filterData ? filterData.length : 0;

  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);
  const entriesFilterData = filterData.slice(startIndex - 1, endIndex);

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(parseInt(value));
    setCurrentPage(1);
  };

  return (
    <>
      <Notification />
      {loading && <Loader />}
      <DashboardLayout>
        <DashboardNavbar />
        <Container className="mt--7 inward_list_con con" fluid>
          <Card className="shadow">
            <CardHead handleSearch={handleSearch} cardHeader="Warehouse Return Entry" />
            <CardBody className="table_card_body">
              <Table className="table-flush new_table" responsive hover>
                <TableHead tableHeadData={tableHeadData} />
                <tbody>
                  {entriesFilterData?.map((entry, index) => {
                    const tableIndex = (currentPage - 1) * itemsPerPage + index;
                    return (
                      <tr key={entry.Id}>
                        <td>
                          <ArgonTypography color="dark" variant="caption">
                            {tableIndex + 1}
                          </ArgonTypography>
                        </td>
                        <td>
                          <ArgonTypography color="dark" variant="caption">
                            {entry.ReturnNumber}
                          </ArgonTypography>
                        </td>
                        <td>
                          <ArgonTypography color="dark" variant="caption">
                            {entry.Description}
                          </ArgonTypography>
                        </td>
                        <td>
                          <ArgonTypography color="dark" variant="caption">
                            {entry.CreatedByName}
                          </ArgonTypography>
                        </td>
                        <td>
                          <ArgonTypography color="dark" variant="caption">
                            {entry.TransactionDate}
                          </ArgonTypography>
                        </td>
                        <td className="inward_verify_btn">
                          <button
                            onClick={() => toggleModal(entry.Id, entry.ReturnNumber)}
                            className="btn delete_btn"
                          >
                            <IoMdEye size="1.5rem" />
                          </button>
                          <button className="btn delete_btn">
                            <IoMdTrash
                              // onClick={() => toggleDeleteModal(entry.Id)}
                              color="red"
                              size="1.4rem"
                            />
                          </button>
                          <button
                            // onClick={() => handleExportClick(entry.Id)}
                            className="btn delete_btn"
                          >
                            <RiFileExcel2Line size="1.5rem" color="green" />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </CardBody>
            <CardFooter>
              <NewPagination
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                handleItemsPerPageChange={handleItemsPerPageChange}
                itemsPerPageLabel="Entries Per Page:"
              />
            </CardFooter>
          </Card>
        </Container>
      </DashboardLayout>
      {modal && (
        <ReturnEntryModal
          modal={modal}
          toggleModal={toggleModal}
          entryModalData={entryModalData}
          fetchEntryModalData={fetchEntryModalData}
          modalFilterData={modalFilterData}
          setModalFilterData={setModalFilterData}
          selectedModalReturnNo={selectedModalReturnNo}
        />
      )}
    </>
  );
};

export default ReturnEntry;
