import React, { useState, useEffect } from "react";
import Notification, { notify } from "../../../components/notification/Notification";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import NewPagination from "../../../components/Pagination/NewPagination";
import Loader from "../../../components/loader/Loader";
import LoadingSkeleton from "../../../components/loader/LoadingSkeleton";
import TableHead from "../../../components/Table/TableHead";
import CardHead from "../../../components/Card/CardHead";
import { apiInstance } from "../../../axios/index";

import { Card, CardFooter, Container, Table, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import WarehouseTableRow from "./WarehouseTableRow";
import WarehouseSendModal from "./WarehouseSendModal";

import { RiSendPlaneFill } from "react-icons/ri";
import { TbFileExport } from "react-icons/tb";
import { MdIndeterminateCheckBox } from "react-icons/md";

const Warehouse_add = () => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [checkvalues, setCheckValues] = useState([]);
  const [warehouseModal, setWarehouseModal] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState("");

  useEffect(() => {
    fetchProducts();
  }, []);

  const navigate = useNavigate();

  // search functionality
  const handleSearch = (e) => {
    const searchString = e.target.value.toLowerCase();
    const searchResults = products.filter(
      ({ Sku, Name, Color, Qty, Mrp, CategoryName, Status }) =>
        (Sku ?? "").toLowerCase().includes(searchString) ||
        (Name ?? "").toLowerCase().includes(searchString) ||
        (Color ?? "").toLowerCase().includes(searchString) ||
        (CategoryName ?? "").toLowerCase().includes(searchString) ||
        (Status ?? "").toLowerCase().includes(searchString) ||
        (Qty ? Qty.toString() : "").includes(searchString) ||
        (Mrp ? Mrp.toString() : "").includes(searchString)
    );
    setFilterData(searchResults);
    setCurrentPage(1);
  };

  // checkbox value
  const handleOrderchange = (Id, Sku, Name, Qty, Mrp, checked) => {
    const newItem = { Id, Sku, Name, Qty, Mrp };

    if (checked) {
      setCheckValues([...checkvalues, newItem]);
    } else {
      setCheckValues((prev) => prev.filter((item) => item.Id !== newItem.Id));
    }
  };

  const resetAllCheckboxes = () => {
    setCheckValues([]);
  };

  // Send Modal
  const orderProductsModal = () => {
    setWarehouseModal(!warehouseModal);
  };

  // Retrieve product data
  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await apiInstance.post("/api/GetProduct", {
        id: 0,
      });

      if (response.data.status === true) {
        const allProducts = response.data.Data.map((fetchProduct) => ({
          Id: fetchProduct.Id,
          Sku: fetchProduct.Sku,
          Name: fetchProduct.Name,
          Color: fetchProduct.Color,
          Qty: fetchProduct.Qty,
          BuyPrice: fetchProduct.BuyPrice,
          Mrp: fetchProduct.Mrp,
          Status: fetchProduct.Status,
          CategoryName: fetchProduct.CategoryName,
          DemageQty: fetchProduct.DemageQty,
          MinQtyAlert: fetchProduct.MinQtyAlert,
          ImagePath: fetchProduct.ImagePath,
        }));
        const productsWithQtyMoreThanZero = allProducts.filter((product) => product.Qty > 0);
        const productsWithQtyZeroOrLess = allProducts.filter((product) => product.Qty <= 0);

        const sortedProducts = productsWithQtyMoreThanZero.concat(productsWithQtyZeroOrLess);

        setProducts(sortedProducts);
        setFilterData(sortedProducts);
      } else {
        console.error("Error: " + response.data.Message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notify("Your session just expired!", "error");
        setTimeout(() => {
          navigate("/authentication/logout");
        }, 5000);
      } else {
        console.error(error);
      }
    }
    setLoading(false);
  };

  // for Pagination
  const totalItems = filterData ? filterData.length : 0;

  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);
  const displayedProducts = filterData.slice(startIndex - 1, endIndex);

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(parseInt(value));
    setCurrentPage(1);
  };

  const tableHeadData = [
    {
      id: 1,
      header: (
        <MdIndeterminateCheckBox
          size="1.6rem"
          style={{ cursor: "pointer", marginLeft: "-2.4px" }}
          onClick={resetAllCheckboxes}
        />
      ),
    },
    {
      id: 2,
      header: "No.",
    },
    {
      id: 3,
      header: "Sku",
    },
    {
      id: 4,
      header: "Name",
    },
    {
      id: 5,
      header: "Color",
    },
    {
      id: 6,
      header: "Category",
    },
    {
      id: 7,
      header: "Qty",
    },
    {
      id: 8,
      header: "Damage Qty",
    },
    {
      id: 9,
      header: "Min Qty. Alert",
    },
    {
      id: 10,
      header: "Amount",
    },
    {
      id: 11,
      header: "Status",
    },
    {
      id: 12,
      header: "",
    },
  ];

  const warehosueAddExport = () => {
    const baseApiUrl = apiInstance.defaults.baseURL;
    const exportPath = `Api/InventoryExport`;

    const fullUrl = `${baseApiUrl}${exportPath}`;

    window.location.href = fullUrl;
  };

  return (
    <>
      <Notification />
      {loading && <Loader />}
      <DashboardLayout>
        <DashboardNavbar />
        <Container className="mt--7 warehouse_add_con con" fluid>
          <Card className="shadow">
            <CardHead
              orderProductsModal={orderProductsModal}
              handleSearch={handleSearch}
              checkvalues={checkvalues}
              cardHeader="Inventory"
              warehosueAddExport={warehosueAddExport}
              buttonTitle={
                <>
                  Send <RiSendPlaneFill size="1.4rem" className="warehouse_send_icon" />
                </>
              }
              buttonTitle2={
                <>
                  Export <TbFileExport size="1.4rem" className="warehouse_send_icon" />
                </>
              }
            />
            <CardBody className="table_card_body">
              {loading ? (
                <LoadingSkeleton />
              ) : (
                <Table className="align-items-center table-flush new_table" responsive hover>
                  <TableHead tableHeadData={tableHeadData} />
                  <tbody>
                    {displayedProducts &&
                      displayedProducts.map((tableProduct, i) => {
                        const index = (currentPage - 1) * itemsPerPage + i;
                        return (
                          <WarehouseTableRow
                            index={index}
                            tableProduct={tableProduct}
                            key={tableProduct.Id}
                            handleOrderchange={handleOrderchange}
                            checkvalues={checkvalues}
                            fetchProducts={fetchProducts}
                          />
                        );
                      })}
                  </tbody>
                </Table>
              )}
            </CardBody>
            <CardFooter>
              <NewPagination
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                handleItemsPerPageChange={handleItemsPerPageChange}
                itemsPerPageLabel="Products Per Page:"
              />
            </CardFooter>
          </Card>
        </Container>
      </DashboardLayout>
      <WarehouseSendModal
        warehouseModal={warehouseModal}
        setWarehouseModal={setWarehouseModal}
        orderProductsModal={orderProductsModal}
        checkvalues={checkvalues}
        setCheckValues={setCheckValues}
        selectedWarehouse={selectedWarehouse}
        setSelectedWarehouse={setSelectedWarehouse}
        fetchProducts={fetchProducts}
      />
    </>
  );
};

export default Warehouse_add;
