import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import InputFeild from "../../../components/Modal/InputFeild";

import { Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const UserModal = ({
  newModal,
  toggleAddModal,
  type,
  setType,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  password,
  setPassword,
  email,
  setEmail,
  mobile,
  setMobile,
  handleSubmit,
}) => {
  const userModalfields = [
    {
      id: "user_firstname",
      label: "First Name",
      type: "text",
      value: firstName,
      onChange: (e) => setFirstName(e.target.value),
      required: true,
    },
    {
      id: "user_lastname",
      label: "Last Name",
      type: "text",
      value: lastName,
      onChange: (e) => setLastName(e.target.value),
      required: true,
    },
    {
      id: "user_password",
      label: "Password",
      type: "password",
      value: password,
      onChange: (e) => setPassword(e.target.value),
      required: false,
    },
    {
      id: "user_email",
      label: "Email",
      type: "email",
      value: email,
      onChange: (e) => setEmail(e.target.value),
      required: true,
    },
    {
      id: "user_mobile",
      label: "Mobile",
      type: "number",
      value: mobile,
      onChange: (e) => setMobile(e.target.value),
      required: true,
    },
  ];
  return (
    <>
      <Modal isOpen={newModal} toggle={toggleAddModal} className="modal-lg new_modal" centered>
        <ModalHeader toggle={toggleAddModal} className="userTable_header">
          <ArgonTypography color="text" fontWeight="medium" variant="d2">
            Add / Edit User
          </ArgonTypography>
        </ModalHeader>

        <form className="needs-validation new_form" id="form_details" onSubmit={handleSubmit}>
          <ModalBody>
            <div className="form-row seller_modal">
              <Row>
                <div className="select_con col-md-6">
                  <ArgonTypography color="text" fontWeight="medium" variant="caption">
                    Type
                  </ArgonTypography>
                  <select
                    className="pagination_select"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value="Admin">Admin</option>
                    <option value="Warehouse ">Warehouse</option>
                    <option value="GM">GM</option>
                    <option value="Accounting">Accounting</option>
                    <option value="Support">Support</option>
                  </select>
                </div>
                {userModalfields &&
                  userModalfields.map((field) => {
                    return <InputFeild field={field} key={field.id} />;
                  })}
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <ArgonButton color="success" size="medium" type="submit" className="">
              Save
            </ArgonButton>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default UserModal;
