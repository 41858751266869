import React from "react";
import ArgonTypography from "../../../components/ArgonTypography";
import ArgonInput from "../../../components/ArgonInput";
import ArgonButton from "../../../components/ArgonButton";

import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ReturnVerifyModal = ({
  verifyModal,
  toggleVerifyModal,
  selectedAction,
  setSelectedAction,
  verifyQty,
  setVerifyQty,
  handleUpdateProduct,
  selectedVerifyModalSku,
  selectedVerifyModalQty,
}) => {
  const handleActionChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedAction(selectedValue);
  };

  return (
    <Modal
      isOpen={verifyModal}
      toggle={toggleVerifyModal}
      className="modal-l inward_modal return_entry_verify_modal"
      centered
    >
      <ModalHeader toggle={toggleVerifyModal}>
        <ArgonTypography color="text" fontWeight="medium" variant="d3">
          Warehouse Retrun Verify
        </ArgonTypography>
      </ModalHeader>
      <ModalBody>
        <div style={{ textAlign: "center", marginBlock: "5px" }}>
          <ArgonTypography color="dark" fontWeight="medium" variant="d3">
            Sku: {selectedVerifyModalSku}
          </ArgonTypography>
          <div style={{ textAlign: "center", marginBlock: "5px" }}>
            <ArgonTypography color="dark" fontWeight="medium" variant="d3">
              Qty: {selectedVerifyModalQty}
            </ArgonTypography>
          </div>
        </div>
        <div className="select_con">
          <label htmlFor="warehouse_select">
            <ArgonTypography color="text" fontWeight="medium" variant="caption">
              Product Action
            </ArgonTypography>
          </label>
          <select
            className="pagination_select"
            id="warehouse_select"
            value={selectedAction}
            onChange={handleActionChange}
            required
          >
            <option value="" disabled>
              -- Select Action --
            </option>
            <option value="Usable">Usable</option>
            <option value="Demage">Damage</option>
            <option value="Missing">Missing</option>
          </select>
        </div>
        <div>
          <label htmlFor="return_verify_modal_qty">
            <ArgonTypography color="text" fontWeight="medium" variant="caption">
              Qty.
            </ArgonTypography>
          </label>
          <ArgonInput
            size="medium"
            id="return_verify_modal_qty"
            placeholder="Qty."
            type="number"
            className="form-control form-control-alternative"
            aria-invalid="false"
            value={verifyQty}
            onChange={(e) => setVerifyQty(e.target.value)}
            error={verifyQty > selectedVerifyModalQty}
            required
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <ArgonButton
          color="success"
          size="medium"
          type="button"
          onClick={handleUpdateProduct}
          disabled={!selectedAction}
        >
          Verify
        </ArgonButton>
      </ModalFooter>
    </Modal>
  );
};

export default ReturnVerifyModal;
