import ArgonTypography from "../../../components/ArgonTypography";
import { Checkbox } from "@mui/material";

const AddOrderTableRow = ({ tableIndex, tableProducts, handleOrderchange, checkvalues }) => {
  const isChecked = checkvalues.some((item) => item.Id == tableProducts.Id);
  const isQtyZero = tableProducts.Qty === 0;

  return (
    <>
      <tr
        key={tableProducts.Id}
        className={`${isChecked ? "rowSelected" : ""} ${isQtyZero ? "rowOpacity" : ""}`}
      >
        <td style={{ width: "70px" }}>
          <Checkbox
            label="Outlined"
            variant="outlined"
            value={tableProducts.Id}
            checked={isChecked}
            disabled={isQtyZero}
            onChange={(e) =>
              handleOrderchange(
                tableProducts.Id,
                tableProducts.Sku,
                tableProducts.Mrp,
                tableProducts.Qty,
                tableProducts.Color,
                tableProducts.CategoryName,
                tableProducts.BuyPrice,
                e.target.checked
              )
            }
          />
        </td>
        <td>
          <ArgonTypography variant="caption" color="dark">
            {tableIndex + 1}
          </ArgonTypography>
        </td>
        <td>
          <ArgonTypography variant="caption" color="dark">
            {tableProducts.Sku}
          </ArgonTypography>
        </td>
        <td>
          <ArgonTypography variant="caption" color="dark">
            {tableProducts.Name}
          </ArgonTypography>
        </td>

        <td>
          <ArgonTypography variant="caption" color="dark">
            {tableProducts.Color}
          </ArgonTypography>
        </td>
        <td>
          <ArgonTypography variant="button" color="info" fontWeight="bold" textGradient>
            {tableProducts.CategoryName}
          </ArgonTypography>
        </td>
        <td>
          <ArgonTypography variant="caption" color="dark">
            {tableProducts.Qty}
          </ArgonTypography>
        </td>
        <td>
          <ArgonTypography variant="caption" color="dark">
            {tableProducts.BuyPrice}
          </ArgonTypography>
        </td>
        <td>
          <ArgonTypography variant="caption" color="dark">
            {tableProducts.Mrp}
          </ArgonTypography>
        </td>
      </tr>
    </>
  );
};

export default AddOrderTableRow;
