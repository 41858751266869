// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// Overview page components
import Header from "layouts/profile/components/Header";

function Overview() {
  return (
    <DashboardLayout
      sx={{
        backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
          `${linearGradient(rgba(gradients.info.main, 0.6), rgba(gradients.info.state, 0.6))}`,
        backgroundPositionY: "50%",
      }}
    >
      <Header />
      {/* <ArgonBox mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} xl={4}>
            <Card>
              <ArgonBox pt={2} px={2}>
                <ArgonBox mb={0.5}>
                  <ArgonTypography variant="d3" fontWeight="medium">
                    Projects
                  </ArgonTypography>
                </ArgonBox>
                <ArgonBox mb={1}>
                  <ArgonTypography variant="button" fontWeight="regular" color="text">
                    Architects design houses
                  </ArgonTypography>
                </ArgonBox>
              </ArgonBox>
            </Card>
          </Grid>
        </Grid>
      </ArgonBox> */}
    </DashboardLayout>
  );
}

export default Overview;
