import React from "react";
import { CardHeader } from "reactstrap";
import { FcSearch } from "react-icons/fc";

import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import OrderFilter from "components/filter/OrderFilter";

const CardHead = ({
  handleSearch,
  toggleAddModal,
  cardHeader,
  orderProductsModal,
  checkvalues,
  buttonTitle,
  buttonTitle2,
  returnProductsModal,
  sellProductsModal,
  openFilter,
  handleFilterClick,
  anchorEl,
  setAnchorEl,
  filterData,
  setFilterData,
  fetchOrderData,
  warehosueAddExport,
}) => {
  return (
    <CardHeader>
      <ArgonBox display="flex" justifyContent="space-between" alignItems="center" py={3} px={0.5}>
        <div className="heading_title_con">
          <ArgonTypography variant="d2" color="secondary" fontWeight="bold">
            {cardHeader}
          </ArgonTypography>
          {handleFilterClick && (
            <OrderFilter
              openFilter={openFilter}
              handleFilterClick={handleFilterClick}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              filterData={filterData}
              setFilterData={setFilterData}
              fetchOrderData={fetchOrderData}
            />
          )}
        </div>
        <ArgonBox className="heading_input_con">
          <ArgonInput
            size="small"
            placeholder="Search"
            type="search"
            name="Search"
            className="heading_searchbar heading_input_child"
            aria-invalid="false"
            onChange={handleSearch}
            autoComplete="off"
            startAdornment={<FcSearch style={{ marginRight: "10px" }} size="1.5rem" />}
          />
          {toggleAddModal && (
            <ArgonButton
              color="info"
              size="medium"
              type="button"
              onClick={toggleAddModal}
              className="heading_add_button heading_input_child"
            >
              Add New +
            </ArgonButton>
          )}
          {orderProductsModal && (
            <ArgonButton
              color="info"
              size="medium"
              type="button"
              disabled={checkvalues.length < 1}
              onClick={orderProductsModal}
              className="heading_add_button heading_input_child"
            >
              {buttonTitle}
            </ArgonButton>
          )}
          {returnProductsModal && (
            <ArgonButton
              color="error"
              size="medium"
              type="button"
              disabled={checkvalues.length < 1}
              onClick={returnProductsModal}
              className="heading_add_button heading_input_child warehouse_return_btn"
            >
              {buttonTitle}
            </ArgonButton>
          )}
          {sellProductsModal && (
            <ArgonButton
              color="success"
              size="medium"
              type="button"
              disabled={checkvalues.length < 1}
              onClick={sellProductsModal}
              className="heading_add_button heading_input_child"
            >
              {buttonTitle2}
            </ArgonButton>
          )}
          {warehosueAddExport && (
            <ArgonButton
              color="info"
              size="medium"
              type="button"
              onClick={warehosueAddExport}
              className="heading_add_button heading_input_child"
            >
              {buttonTitle2}
            </ArgonButton>
          )}
        </ArgonBox>
      </ArgonBox>
    </CardHeader>
  );
};

export default CardHead;
