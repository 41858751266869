import React, { useState, useEffect } from "react";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import { apiInstance } from "../../axios/index";

import { Modal, ModalHeader, ModalBody, Table, Card, CardBody, CardFooter } from "reactstrap";
import InwardRow from "./InwardRow";
import TableHead from "components/Table/TableHead";
import Form_Modal from "../../components/Modal/Form_Modal";
import NewPagination from "../../components/Pagination/NewPagination";
import { notify } from "../../components/notification/Notification";
import ArgonInput from "components/ArgonInput";
import { FcSearch } from "react-icons/fc";
import * as XLSX from "xlsx";
import { BsArrowClockwise } from "react-icons/bs";

const InwardModal = React.memo(
  ({
    modal,
    inwardCartoonData,
    closeModal,
    selectedImportNumber,
    selectedDate,
    fetchInwardCartoonData,
    modalFilterData,
    setModalFilterData,
    selectedModalId,
  }) => {
    const [demageQtyinput, setDemageQtyinput] = useState(Array(modalFilterData.length).fill(""));
    const [receivedQtyinput, setReceivedQtyinput] = useState(
      Array(modalFilterData.length).fill("")
    );
    const [missingQtyinput, setMissingQtyinput] = useState(Array(modalFilterData.length).fill(""));
    const [attachmentinput, setAttachmentinput] = useState(Array(modalFilterData.length).fill(""));
    const [rowErrors, setRowErrors] = useState(Array(modalFilterData.length).fill(false));
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [totalDamaged, setTotalDamaged] = useState(0);
    const [totalReceived, setTotalReceived] = useState(0);
    const [totalMissing, setTotalMissing] = useState(0);
    const [submitModalOpen, setSubmitModalOpen] = useState(false);
    const [allRowsVerified, setAllRowsVerified] = useState(false);
    // const [itemsPerPage, setItemsPerPage] = useState(25);
    // const [inwardCurrentPage, setInwardCurrentPage] = useState(1);
    const [selectedInwardFile, setSelectedInwardFile] = useState(null);
    const [editModes, setEditModes] = useState(Array(modalFilterData.length).fill(false));
    const [hasError, setHasError] = useState(Array(modalFilterData.length).fill(false));

    // search functionality
    const handleSearch = (e) => {
      const searchString = e.target.value.toLowerCase();
      const searchResults = inwardCartoonData.filter(
        ({ Sku, VerifiedBy, VerifyDate, Qty }) =>
          (Sku ?? "").toLowerCase().includes(searchString) ||
          (VerifiedBy ?? "").toLowerCase().includes(searchString) ||
          (VerifyDate ?? "").toLowerCase().includes(searchString) ||
          (Qty ? Qty.toString() : "").includes(searchString)
      );
      setDemageQtyinput(Array(searchResults.length).fill(""));
      setReceivedQtyinput(Array(searchResults.length).fill(""));
      setHasError(Array(modalFilterData.length).fill(false));
      setModalFilterData(searchResults);
      // setInwardCurrentPage(1);
    };

    // for Pagination
    // const totalItems = modalFilterData ? modalFilterData.length : 0;

    // const startIndex = (inwardCurrentPage - 1) * itemsPerPage + 1;
    // const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);
    // const inwardFilterData = modalFilterData.slice(startIndex - 1, endIndex);

    // const handleItemsPerPageChange = (value) => {
    //   setItemsPerPage(parseInt(value));
    //   setInwardCurrentPage(1);
    // };

    const verifyConfirmModal = () => {
      setSubmitModalOpen(!submitModalOpen);
    };

    useEffect(() => {
      const calculateTotalQuantity = () => {
        const totalQtyFunc = modalFilterData.reduce((totalQty, inward) => totalQty + inward.Qty, 0);
        setTotalQuantity(totalQtyFunc);

        const totalDamagedFunc = modalFilterData.reduce(
          (totalDamaged, inward) => totalDamaged + Number(inward.DemageQty),
          0
        );
        setTotalDamaged(totalDamagedFunc);

        const totalReceivedFunc = modalFilterData.reduce(
          (totalReceived, inward) => totalReceived + Number(inward.ReceivedQty),
          0
        );
        setTotalReceived(totalReceivedFunc);

        const totalMissingFunc = modalFilterData.reduce(
          (totalMissing, inward) => totalMissing + Number(inward.MissingQty),
          0
        );
        setTotalMissing(totalMissingFunc);

        const allRowsVerified = inwardCartoonData.every((inward) => inward.Status === "1");
        setAllRowsVerified(allRowsVerified);
      };
      calculateTotalQuantity();
    }, [fetchInwardCartoonData]);

    const handleCloseModal = () => {
      closeModal();
      setReceivedQtyinput(Array(modalFilterData.length).fill(""));
      setDemageQtyinput(Array(modalFilterData.length).fill(""));
      setMissingQtyinput(Array(modalFilterData.length).fill(""));
    };

    const filteredArray = modalFilterData
      .map((inward, index) => {
        const receivedQty = receivedQtyinput[index];
        if (receivedQty !== null && receivedQty !== undefined && receivedQty !== "") {
          return {
            id: inward.Id,
            demageQty: demageQtyinput[index] || 0,
            missingQty: missingQtyinput[index] || 0,
            attachment: attachmentinput[index],
            receivedQty: receivedQty || 0,
            sku: inward.Sku || "",
          };
        }
        return null;
      })
      .filter((row) => row !== null);

    console.log(filteredArray);

    const isValid = filteredArray.every((inward, index) => {
      const receivedQty = Number(receivedQtyinput[index]) || 0;
      const demageQty = Number(demageQtyinput[index]) || 0;
      const missingQty = Number(missingQtyinput[index]) || 0;

      return receivedQty + demageQty + missingQty === inward.Qty;
    });

    let selectedQty = 0;
    let selectedDamageQty = 0;
    let selectedReceivedQty = 0;
    let selectedMissingQty = 0;

    const filteredArray2 = modalFilterData
      .map((inward, index) => {
        const receivedQty = receivedQtyinput[index];
        if (receivedQty !== null && receivedQty !== undefined && receivedQty !== "") {
          return {
            id: inward.Id,
            Qty: inward.Qty,
            demageQty: demageQtyinput[index] || 0,
            missingQty: missingQtyinput[index] || 0,
            receivedQty: receivedQty || 0,
            sku: inward.Sku || "",
          };
        }
        return null;
      })
      .filter((row) => row !== null);

    filteredArray2.forEach((item) => {
      selectedQty += Number(item.Qty);
      selectedDamageQty += Number(item.demageQty);
      selectedReceivedQty += Number(item.receivedQty);
      selectedMissingQty += Number(item.missingQty);
    });

    const handleVerify = () => {
      if (!isValid) {
        apiInstance
          .post("/inward/VerifyCartoonBox", filteredArray)
          .then((response) => {
            if (response.data.status === true) {
              console.log(response.data.Message);
              notify(response.data.Message, "success");
              fetchInwardCartoonData();
            } else {
              console.log("Verification failed: " + response.data.Message);
              notify(response.data.Message, "error");
            }
          })
          .catch((error) => {
            console.error(error);
            notify("There was an error during verify", "error");
          });
      } else {
        notify(
          "Total of demageQty, missingQty, and receivedQty must equal Qty for each row.",
          "error"
        );
      }

      setReceivedQtyinput(Array(modalFilterData.length).fill(""));
      setDemageQtyinput(Array(modalFilterData.length).fill(""));
      setMissingQtyinput(Array(modalFilterData.length).fill(""));
      setAttachmentinput(Array(modalFilterData.length).fill(""));
      setSubmitModalOpen(!submitModalOpen);
    };

    const handleDamageInputChange = (e, index) => {
      const newValue = e.target.value;
      const updatedDemageQty = [...demageQtyinput];
      updatedDemageQty[index] = newValue;

      setDemageQtyinput(updatedDemageQty);
      setRowErrors(Array(modalFilterData.length).fill(false));
      setHasError(Array(modalFilterData.length).fill(false));
    };

    const handleQuantityInputChange = (e, index) => {
      const newValue = e.target.value;
      const updatedReceivedQty = [...receivedQtyinput];
      updatedReceivedQty[index] = newValue;

      setReceivedQtyinput(updatedReceivedQty);
      setHasError(Array(modalFilterData.length).fill(false));
    };

    const handleMissingInputChange = (e, index) => {
      const newValue = e.target.value;
      const updatedMissingQty = [...missingQtyinput];
      updatedMissingQty[index] = newValue;

      setMissingQtyinput(updatedMissingQty);
      setHasError(Array(modalFilterData.length).fill(false));
    };

    const handleAttachmentInputChange = (e, index) => {
      const newValue = e.target.value;
      const updatedAttachment = [...attachmentinput];
      updatedAttachment[index] = newValue;

      setAttachmentinput(updatedAttachment);
      setHasError(Array(modalFilterData.length).fill(false));
    };

    const handleExportClick = () => {
      const baseApiUrl = apiInstance.defaults.baseURL;
      const exportPath = `/Inward/CartonBoxExport?inwardId=${selectedModalId}`;

      const fullUrl = `${baseApiUrl}${exportPath}`;

      window.location.href = fullUrl;
    };

    const handleRowSave = (rowIndex, Id, Sku, Qty) => {
      const editedDemageQty = demageQtyinput[rowIndex] || 0;
      const editedReceivedQty = receivedQtyinput[rowIndex];
      const editedMissingQty = missingQtyinput[rowIndex] || 0;
      const editedAttachment = attachmentinput[rowIndex];

      const demageQty1 = parseFloat(editedDemageQty);
      const receivedQty1 = parseFloat(editedReceivedQty);
      const missingQty1 = parseFloat(editedMissingQty);

      const data = {
        id: Id,
        sku: Sku,
        demageQty: editedDemageQty,
        receivedQty: editedReceivedQty,
        missingQty: editedMissingQty,
        attachment: editedAttachment,
      };

      if (demageQty1 + receivedQty1 + missingQty1 !== Qty) {
        notify(
          "Total of demageQty, missingQty, and receivedQty must equal Qty for each row.",
          "error"
        );
        const updatedHasError = [...hasError];
        updatedHasError[rowIndex] = true;
        setHasError(updatedHasError);
      } else {
        apiInstance
          .post("/inward/VerifyCartoonBoxUpdate", data)
          .then((response) => {
            if (response.data.status === true) {
              console.log("Data updated successfully:", response.data.Message);
              notify("Data updated successfully.", "success");
            } else {
              console.log("Data update failed:", response.data.Message);
              notify("Data update failed:", "error");
            }
          })
          .catch((error) => {
            console.error("Error occurred while updating data:", error);
            notify("Error occurred while updating data:", "error");
          });
        setReceivedQtyinput(Array(modalFilterData.length).fill(""));
        setDemageQtyinput(Array(modalFilterData.length).fill(""));
        setMissingQtyinput(Array(modalFilterData.length).fill(""));
        setAttachmentinput(Array(modalFilterData.length).fill(""));
      }
    };

    const toggleEditRow = (rowIndex) => {
      const updatedEditModes = [...editModes];
      updatedEditModes[rowIndex] = !updatedEditModes[rowIndex];
      setEditModes(updatedEditModes);
    };

    const handleFileChange = (e) => {
      const file = e.target.files[0];
      setSelectedInwardFile(file);
    };

    const handleImportExcel = () => {
      if (selectedInwardFile) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          const skuToDataMap = {};
          jsonData.slice(1).forEach((row) => {
            const cartonNumber = row[0];
            const sku = row[1];
            const key = `${sku}-${cartonNumber}`;
            skuToDataMap[key] = {
              demageQty: row[4] || 0,
              receivedQty: row[5] || 0,
              missingQty: row[6] || 0,
            };
          });

          // Update state arrays with data from Excel based on SKU matching
          const updatedDemageQtyinput = modalFilterData.map(
            (row) => skuToDataMap[`${row.Sku}-${row.CartonNumber}`]?.demageQty
          );
          const updatedReceivedQtyinput = modalFilterData.map(
            (row) => skuToDataMap[`${row.Sku}-${row.CartonNumber}`]?.receivedQty
          );
          const updatedMissingQtyinput = modalFilterData.map(
            (row) => skuToDataMap[`${row.Sku}-${row.CartonNumber}`]?.missingQty
          );

          setDemageQtyinput(updatedDemageQtyinput);
          setReceivedQtyinput(updatedReceivedQtyinput);
          setMissingQtyinput(updatedMissingQtyinput);
        };

        reader.readAsBinaryString(selectedInwardFile);
      }
    };

    const clearInputFields = () => {
      setReceivedQtyinput(Array(modalFilterData.length).fill(""));
      setDemageQtyinput(Array(modalFilterData.length).fill(""));
      setMissingQtyinput(Array(modalFilterData.length).fill(""));
      setAttachmentinput(Array(modalFilterData.length).fill(""));
      setHasError(Array(modalFilterData.length).fill(""));
      setEditModes(Array(modalFilterData.length).fill(""));
      setRowErrors(Array(modalFilterData.length).fill(""));
      setSelectedInwardFile();
    };

    const tableHeadData = [
      {
        id: 1,
        header: "No.",
      },
      {
        id: 2,
        header: "Cartoon No.",
      },
      {
        id: 3,
        header: "SKU",
      },
      {
        id: 4,
        header: "Qty",
      },
      {
        id: 5,
        header: "Damage Qty",
      },
      {
        id: 6,
        header: "Received Qty",
      },
      {
        id: 7,
        header: "Missing Qty",
      },
      {
        id: 8,
        header: "Attachment",
      },
      {
        id: 9,
        header: "Verified By",
      },
      {
        id: 10,
        header: "Verified Date",
      },
      {
        id: 11,
        header: "Status",
      },
      {
        id: 12,
        header: (
          <BsArrowClockwise
            className="inward_modal_clear_icon"
            size="1.4rem"
            onClick={clearInputFields}
          />
        ),
      },
    ];

    return (
      <>
        <Modal
          isOpen={modal}
          toggle={handleCloseModal}
          className="modal-xl inward_modal inward_verify_modal"
          centered
          style={{ minWidth: "90%" }}
        >
          <Card>
            <ModalHeader toggle={handleCloseModal} className="inwardModal_header">
              <span style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                <ArgonTypography color="dark" variant="button" fontWeight="medium">
                  Import Number: {selectedImportNumber}
                </ArgonTypography>
                <ArgonTypography color="dark" variant="button" fontWeight="medium">
                  Date: {selectedDate}
                </ArgonTypography>
              </span>
              <ArgonInput
                size="small"
                placeholder="Search"
                type="search"
                name="Search"
                className="heading_searchbar heading_input_child"
                aria-invalid="false"
                onChange={handleSearch}
                startAdornment={<FcSearch style={{ marginRight: "10px" }} size="1.5rem" />}
              />
              <div className="inportfile_inputFile">
                <ArgonInput
                  type="file"
                  size="small"
                  id="inward_excel_file"
                  onChange={handleFileChange}
                  required
                />
              </div>
              <ArgonButton
                color="info"
                type="button"
                onClick={handleImportExcel}
                disabled={!selectedInwardFile}
              >
                Import from Excel
              </ArgonButton>
              <div className="inward_modal_export_btn">
                <ArgonButton color="info" type="button" onClick={handleExportClick}>
                  Export
                </ArgonButton>
              </div>
            </ModalHeader>
            <CardBody className="inward_modal_card_body inward_modal">
              <ModalBody>
                <Table className="table-flush new_table" responsive hover>
                  {modalFilterData.length > 0 ? (
                    <>
                      <TableHead tableHeadData={tableHeadData} />
                      <tbody style={{ maxHeight: "80vh", overflowY: "auto" }}>
                        {modalFilterData.map((inward, index) => {
                          return (
                            <InwardRow
                              key={inward.Id}
                              inward={inward}
                              index={index}
                              demageQtyinput={demageQtyinput}
                              receivedQtyinput={receivedQtyinput}
                              missingQtyinput={missingQtyinput}
                              attachmentinput={attachmentinput}
                              handleDamageInputChange={handleDamageInputChange}
                              handleQuantityInputChange={handleQuantityInputChange}
                              handleMissingInputChange={handleMissingInputChange}
                              handleAttachmentInputChange={handleAttachmentInputChange}
                              editModes={editModes}
                              toggleEditRow={toggleEditRow}
                              onSave={handleRowSave}
                              hasError={hasError}
                            />
                          );
                        })}
                      </tbody>
                    </>
                  ) : (
                    <thead>
                      <tr>
                        <td style={{ textAlign: "center" }}>
                          <ArgonTypography variant="button" color="dark" fontWeight="bold">
                            No Data Available
                          </ArgonTypography>
                        </td>
                      </tr>
                    </thead>
                  )}
                </Table>
              </ModalBody>
            </CardBody>
            <CardFooter>
              <div className="inward_modal_footer_details">
                <div className="inward_modal_footer_details_inner">
                  {/* <ArgonTypography color="secondary" fontWeight="medium" variant="button">
                    Total: <span style={{ color: "black" }}>{totalItems}</span>
                  </ArgonTypography> */}
                  <ArgonTypography color="secondary" fontWeight="medium" variant="button">
                    Total Qty: <span style={{ color: "black" }}>{totalQuantity}</span>
                  </ArgonTypography>
                  <ArgonTypography color="secondary" fontWeight="medium" variant="button">
                    Total Damaged: <span style={{ color: "black" }}>{totalDamaged}</span>
                  </ArgonTypography>
                  <ArgonTypography color="secondary" fontWeight="medium" variant="button">
                    Total Received: <span style={{ color: "black" }}>{totalReceived}</span>
                  </ArgonTypography>
                  <ArgonTypography color="secondary" fontWeight="medium" variant="button">
                    Total Missing: <span style={{ color: "black" }}>{totalMissing}</span>
                  </ArgonTypography>
                </div>
                <div className="inward_modal_verify_btn_td">
                  <div className="inward_modal_verify_details">
                    <ArgonTypography color="secondary" fontWeight="medium" variant="button">
                      Qty: <span style={{ color: "black" }}>{selectedQty}</span>
                    </ArgonTypography>
                    <ArgonTypography color="secondary" fontWeight="medium" variant="button">
                      Damaged: <span style={{ color: "black" }}>{selectedDamageQty}</span>
                    </ArgonTypography>
                    <ArgonTypography color="secondary" fontWeight="medium" variant="button">
                      Received: <span style={{ color: "black" }}>{selectedReceivedQty}</span>
                    </ArgonTypography>
                    <ArgonTypography color="secondary" fontWeight="medium" variant="button">
                      Missing: <span style={{ color: "black" }}>{selectedMissingQty}</span>
                    </ArgonTypography>
                  </div>
                  {allRowsVerified ? (
                    ""
                  ) : (
                    <div>
                      <ArgonButton color="success" type="button" onClick={verifyConfirmModal}>
                        Verify
                      </ArgonButton>
                    </div>
                  )}
                </div>
              </div>
              {/* <div className="inward_modal_pagination">
                <NewPagination
                  totalItems={totalItems}
                  itemsPerPage={itemsPerPage}
                  inwardCurrentPage={inwardCurrentPage}
                  setInwardCurrentPage={setInwardCurrentPage}
                  handleItemsPerPageChange={handleItemsPerPageChange}
                  itemsPerPageLabel="Inward Items Per Page:"
                  modalFilterData={modalFilterData}
                  setReceivedQtyinput={setReceivedQtyinput}
                  setDemageQtyinput={setDemageQtyinput}
                  setMissingQtyinput={setMissingQtyinput}
                />
              </div> */}
            </CardFooter>
          </Card>
        </Modal>
        <Form_Modal
          handleVerify={handleVerify}
          deleteModalOpen={submitModalOpen}
          toggleDeleteModal={verifyConfirmModal}
          modalDesc="Are you sure about Verify?"
          callToAction="Verify"
        />
      </>
    );
  }
);

export default InwardModal;
