import React, { useState, useEffect } from "react";
import ArgonTypography from "../../../components/ArgonTypography";
import ArgonBox from "../../../components/ArgonBox";
import ArgonInput from "../../../components/ArgonInput";
import ArgonButton from "../../../components/ArgonButton";
import { notify } from "../../../components/notification/Notification";

import Form_Modal from "../../../components/Modal/Form_Modal";
import { apiInstance } from "../../../axios/index";
import { useNavigate } from "react-router-dom";
import TableHead from "../../../components/Table/TableHead";
import { Row, Modal, ModalHeader, ModalBody, CardBody, Table } from "reactstrap";

const tableHeadData = [
  {
    id: 1,
    header: "No.",
  },
  {
    id: 2,
    header: "Qty",
  },
  {
    id: 3,
    header: "Entry Date",
  },
  {
    id: 4,
    header: "Email",
  },
];

const InventoryEditModal = ({
  editModal,
  toggleEditModal,
  damageQty,
  setdamageQty,
  minQty,
  setMinQty,
  sku,
  name,
  color,
  qty,
  demageQty,
  minQtyAlert,
  category,
  modalId,
  fetchProducts,
}) => {
  const [minQtyModalOpen, setMinQtyModalOpen] = useState(false);
  const [damageQtyModalOpen, setDamageQtyModalOpen] = useState(false);
  const [damageQtyTableData, setDamageQtyTableData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getDamageQtyHistory();
  }, []);

  // Confirm Modal
  const toggleMinQtyConfirmModal = () => {
    setMinQtyModalOpen(!minQtyModalOpen);
  };
  const toggleDamageQtyConfirmModal = () => {
    setDamageQtyModalOpen(!damageQtyModalOpen);
  };

  const getDamageQtyHistory = async () => {
    try {
      const response = await apiInstance.post("/api/GetDemageProductHistory", {
        productId: modalId,
      });

      if (response.data.status === true) {
        const damageData = response.data.Data.map((damage) => ({
          Qty: damage.Qty,
          EntryDate: damage.EntryDate,
          Email: damage.Email,
        }));

        setDamageQtyTableData(damageData);
      } else {
        console.error("Error: " + response.data.Message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notify("Your session just expired!", "error");
        setTimeout(() => {
          navigate("/authentication/logout");
        }, 5000);
      } else {
        console.error("Error fetching Damage Qty:", error);
      }
    }
  };

  // edit min Qty.
  const editMinQty = async () => {
    const damageQtyData = {
      productId: modalId,
      type: "MinQty",
      qty: minQty,
    };
    await apiInstance
      .post("/api/SaveProductQty", damageQtyData)
      .then((response) => {
        if (response.data.status === true) {
          notify("Min Qty. Updated successfully", "success");
        } else {
          notify("Failed to edit Min Qty.", "error");
        }
      })
      .catch((error) => {
        notify("Failed to edit Min Qty.", "error");
        console.error("Error occurred while editing Min Qty:", error);
      });
    setMinQty("");
    setMinQtyModalOpen(false);
    fetchProducts();
    getDamageQtyHistory();
  };

  // edit damage Qty.
  const editDamageQty = async () => {
    const damageQtyData = {
      productId: modalId,
      type: "DemageQty",
      qty: damageQty,
    };
    await apiInstance
      .post("/api/SaveProductQty", damageQtyData)
      .then((response) => {
        if (response.data.status === true) {
          notify("Damage Qty. Updated successfully", "success");
        } else {
          notify("Failed to edit Damage Qty.", "error");
        }
      })
      .catch((error) => {
        notify("Failed to edit Damage Qty.", "error");
        console.error("Error occurred while editing Damage Qty:", error);
      });
    setdamageQty("");
    setDamageQtyModalOpen(false);
    fetchProducts();
    getDamageQtyHistory();
  };

  return (
    <>
      <Modal isOpen={editModal} toggle={toggleEditModal} className="modal-xl new_modal" centered>
        <ModalHeader toggle={toggleEditModal} className="userTable_header">
          <ArgonTypography color="text" fontWeight="medium" variant="d2">
            Edit Inventory
          </ArgonTypography>
        </ModalHeader>
        <ModalBody>
          <div className="form-row warehouse_inventory_edit_modal">
            <Row
              style={{ display: "flex", gap: "10px", marginBottom: "25px" }}
              className="warehouse_inventory_edit_modal_description"
            >
              <ArgonTypography color="text" fontWeight="regular" variant="button">
                SKU: <b>{sku}</b>
              </ArgonTypography>
              <ArgonTypography color="text" fontWeight="regular" variant="button">
                Name: <b>{name}</b>
              </ArgonTypography>
              <ArgonTypography color="text" fontWeight="regular" variant="button">
                Color: <b>{color}</b>
              </ArgonTypography>
              <ArgonTypography color="text" fontWeight="regular" variant="button">
                Qty: <b>{qty}</b>
              </ArgonTypography>
              <ArgonTypography color="text" fontWeight="regular" variant="button">
                Category: <b>{category}</b>
              </ArgonTypography>
              <ArgonTypography color="text" fontWeight="regular" variant="button">
                Damage Qty: <b>{demageQty}</b>
              </ArgonTypography>
              <ArgonTypography color="text" fontWeight="regular" variant="button">
                Min Qty. Alert: <b>{minQtyAlert}</b>
              </ArgonTypography>
            </Row>
            <hr />
            <Row>
              <div className={`col-md-4 warehouse_inventory_edit_damage`}>
                <ArgonBox mb={2}>
                  <label htmlFor="warehouse_inventory_edit_min">
                    <ArgonTypography color="text" fontWeight="medium" variant="caption">
                      Min Qty. Alert
                    </ArgonTypography>
                  </label>
                  <ArgonInput
                    size="medium"
                    id="warehouse_inventory_edit_min"
                    placeholder="Min Qty. Alert"
                    type="number"
                    className="form-control form-control-alternative"
                    aria-invalid="false"
                    value={minQty}
                    onChange={(e) => setMinQty(e.target.value)}
                    disabled={qty === 0}
                  />
                </ArgonBox>
              </div>
              <div className="warehouse_inventory_edit_damage_btn">
                <ArgonButton
                  color="success"
                  size="medium"
                  type="button"
                  disabled={!minQty}
                  style={{ marginBottom: "-15px" }}
                  onClick={() => toggleMinQtyConfirmModal()}
                >
                  Save
                </ArgonButton>
              </div>
            </Row>
            <Row>
              <div className={`col-md-4 warehouse_inventory_edit_damage`}>
                <ArgonBox mb={2}>
                  <label htmlFor="warehouse_inventory_edit_damage">
                    <ArgonTypography color="text" fontWeight="medium" variant="caption">
                      Damage Qty.
                    </ArgonTypography>
                  </label>
                  <ArgonInput
                    size="medium"
                    id="warehouse_inventory_edit_damage"
                    placeholder="Damage Qty."
                    type="number"
                    className="form-control form-control-alternative"
                    aria-invalid="false"
                    value={damageQty}
                    onChange={(e) => setdamageQty(e.target.value)}
                    disabled={qty === 0}
                  />
                </ArgonBox>
              </div>
              <div className="warehouse_inventory_edit_damage_btn">
                <ArgonButton
                  color="success"
                  size="medium"
                  type="button"
                  disabled={!damageQty}
                  onClick={() => toggleDamageQtyConfirmModal()}
                  style={{ marginBottom: "-15px" }}
                >
                  Save
                </ArgonButton>
              </div>
            </Row>
          </div>
          <hr />
          {damageQtyTableData.length > 0 ? (
            <div className="warehouse_inventory_edit_modal_table">
              <CardBody className="table_card_body">
                <Table className="align-items-center table-flush new_table" hover responsive>
                  <TableHead tableHeadData={tableHeadData} />
                  <tbody>
                    {damageQtyTableData &&
                      damageQtyTableData.map((tableDamage, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {index + 1}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableDamage.Qty}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableDamage.EntryDate}
                              </ArgonTypography>
                            </td>
                            <td>
                              <ArgonTypography variant="caption" color="dark">
                                {tableDamage.Email}
                              </ArgonTypography>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </CardBody>
            </div>
          ) : (
            <div style={{ textAlign: "center", paddingBlock: "30px" }}>
              <ArgonTypography variant="button" color="dark" fontWeight="medium">
                No Damage Qty. history available
              </ArgonTypography>
            </div>
          )}
        </ModalBody>
      </Modal>
      {minQtyModalOpen && (
        <Form_Modal
          handleVerify={editMinQty}
          deleteModalOpen={minQtyModalOpen}
          toggleDeleteModal={toggleMinQtyConfirmModal}
          modalDesc="Are you sure you want to Save Min Qty?"
          callToAction="Save"
        />
      )}
      {damageQtyModalOpen && (
        <Form_Modal
          handleVerify={editDamageQty}
          deleteModalOpen={damageQtyModalOpen}
          toggleDeleteModal={toggleDamageQtyConfirmModal}
          modalDesc="Are you sure you want to Save Damage Qty?"
          callToAction="Save"
        />
      )}
    </>
  );
};

export default InventoryEditModal;
