import React from "react";
import { Skeleton, Stack, Box } from "@mui/material";

const LoadingSkeleton = () => {
  const numRows = 5;

  const renderTableRow = () => {
    const tableRows = [];
    for (let i = 0; i < numRows; i++) {
      tableRows.push(
        <Box
          key={i}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingInline: "10px",
          }}
        >
          <Skeleton
            variant="rectangular"
            width="5%"
            height={20}
            sx={{ marginRight: "10px", borderRadius: "6px", marginBlock: "8px" }}
          />
          <Skeleton
            variant="rectangular"
            width="25%"
            height={20}
            sx={{ marginRight: "10px", borderRadius: "6px", marginBlock: "8px" }}
          />
          <Skeleton
            variant="rectangular"
            width="30%"
            height={20}
            sx={{ marginRight: "10px", borderRadius: "6px", marginBlock: "8px" }}
          />
          <Skeleton
            variant="rectangular"
            width="40%"
            height={20}
            sx={{ borderRadius: "6px", marginBlock: "8px" }}
          />
        </Box>
      );
    }
    return tableRows;
  };

  return (
    <Stack spacing={1} style={{ width: "100%", height: "100%" }}>
      <Box>
        <Skeleton
          variant="rectangular"
          height={25}
          sx={{
            borderRadius: "6px",
            marginBlock: "8px",
            marginInline: "10px",
          }}
        />
      </Box>
      {renderTableRow()}
    </Stack>
  );
};

export default LoadingSkeleton;
