import React, { useState, useEffect } from "react";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import NewPagination from "../../components/Pagination/NewPagination";
import Loader from "../../components/loader/Loader";
import { apiInstance } from "../../axios/index";
import Notification, { notify } from "../../components/notification/Notification";

import { Card, CardFooter, Container, Table, CardBody } from "reactstrap";
import ProductSellerTableRow from "./ProductSellerTableRow";
import OrderItemsModal from "./OrderItemsModal";
import TableHead from "components/Table/TableHead";
import CardHead from "../../components/Card/CardHead";
import { useNavigate } from "react-router-dom";
import { MdIndeterminateCheckBox } from "react-icons/md";

const ProductSeller = () => {
  const [loading, setLoading] = useState(false);
  const [sellerProducts, setSellerProducts] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [orderModal, setOrderModal] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [checkvalues, setCheckValues] = useState([]);

  useEffect(() => {
    fetchSellerProducts();
  }, []);

  const navigate = useNavigate();

  // search functionality
  const handleSearch = (e) => {
    const searchString = e.target.value.toLowerCase();
    const searchResults = sellerProducts.filter(
      ({ Sku, Name, Color, Qty, Mrp }) =>
        (Sku ?? "").toLowerCase().includes(searchString) ||
        (Name ?? "").toLowerCase().includes(searchString) ||
        (Color ?? "").toLowerCase().includes(searchString) ||
        (Qty ? Qty.toString() : "").includes(searchString) ||
        (Mrp ? Mrp.toString() : "").includes(searchString)
    );
    setFilterData(searchResults);
    setCurrentPage(1);
  };

  // checkbox value
  const handleOrderchange = (Id, Sku, Name, Qty, Mrp, checked) => {
    const newItem = { Id, Sku, Name, Qty, Mrp };

    if (checked) {
      setCheckValues([...checkvalues, newItem]);
    } else {
      setCheckValues((prev) => prev.filter((item) => item.Id !== newItem.Id));
    }
  };

  const resetAllCheckboxes = () => {
    setCheckValues([]);
  };

  // Order Modal
  const orderProductsModal = () => {
    setOrderModal(!orderModal);
  };

  // Retrieve Seller Product data
  const fetchSellerProducts = async () => {
    setLoading(true);
    try {
      const response = await apiInstance.post("/api/GetProductForSeller", {
        id: 0,
      });

      if (response.data.status === true) {
        const productData = response.data.Data.map((fetchProduct) => ({
          Id: fetchProduct.Id,
          Sku: fetchProduct.Sku,
          Name: fetchProduct.Name,
          Color: fetchProduct.Color,
          Qty: fetchProduct.Qty,
          Mrp: fetchProduct.Mrp,
          ImageCode: fetchProduct.ImageCode,
          Image: fetchProduct.Image,
        }));
        setSellerProducts(productData);
        setFilterData(productData);
      } else {
        console.error("Error: " + response.data.Message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notify("Your session just expired!", "error");
        setTimeout(() => {
          navigate("/authentication/logout");
        }, 5000);
      } else {
        console.error(error);
      }
    }
    setLoading(false);
  };

  // for Pagination
  const totalItems = filterData ? filterData.length : 0;

  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);
  const displayedProducts = filterData.slice(startIndex - 1, endIndex);

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(parseInt(value));
    setCurrentPage(1);
  };

  const tableHeadData = [
    {
      id: 1,
      header: (
        <MdIndeterminateCheckBox
          size="1.6rem"
          style={{ cursor: "pointer", marginLeft: "-2.4px" }}
          onClick={resetAllCheckboxes}
        />
      ),
    },
    {
      id: 2,
      header: "No.",
    },
    {
      id: 3,
      header: "Sku",
    },
    {
      id: 4,
      header: "Name",
    },
    {
      id: 5,
      header: "Color",
    },
    {
      id: 6,
      header: "Qty",
    },
    {
      id: 7,
      header: "MRP",
    },
    {
      id: 9,
      header: "Image",
    },
  ];

  return (
    <>
      <Notification />
      {loading && <Loader />}
      <DashboardLayout>
        <DashboardNavbar />
        <Container className="mt--7 productSeller_con con" fluid>
          <div className="col">
            <Card className="shadow">
              <CardHead
                orderProductsModal={orderProductsModal}
                handleSearch={handleSearch}
                checkvalues={checkvalues}
                cardHeader="Product"
                buttonTitle="Order"
              />

              <CardBody className="table_card_body">
                <Table className="align-items-center table-flush new_table" responsive hover>
                  <TableHead tableHeadData={tableHeadData} />
                  <tbody>
                    {displayedProducts &&
                      displayedProducts.map((tableProduct, i) => {
                        const index = (currentPage - 1) * itemsPerPage + i;
                        return (
                          <ProductSellerTableRow
                            index={index}
                            tableProduct={tableProduct}
                            handleOrderchange={handleOrderchange}
                            key={tableProduct.Id}
                            checkvalues={checkvalues}
                          />
                        );
                      })}
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter>
                <NewPagination
                  totalItems={totalItems}
                  itemsPerPage={itemsPerPage}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  handleItemsPerPageChange={handleItemsPerPageChange}
                  itemsPerPageLabel="Products Per Page:"
                />
              </CardFooter>
            </Card>
            <OrderItemsModal
              orderModal={orderModal}
              orderProductsModal={orderProductsModal}
              checkvalues={checkvalues}
              setOrderModal={setOrderModal}
              setCheckValues={setCheckValues}
            />
          </div>
        </Container>
      </DashboardLayout>
    </>
  );
};

export default ProductSeller;
