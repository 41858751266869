import React from "react";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import InputFeild from "../../../components/Modal/InputFeild";

import { Modal, ModalHeader, ModalBody, ModalFooter, Row } from "reactstrap";

const CategoryModal = ({
  newModal,
  toggleAddModal,
  handleSubmit,
  name,
  setName,
  status,
  setStatus,
}) => {
  const categoryModalfields = [
    {
      id: "category_name",
      label: "Category Name",
      type: "text",
      value: name,
      onChange: (e) => setName(e.target.value),
      required: true,
    },
  ];

  const handleStatusChange = (e) => {
    setStatus(e.target.value || "Enable");
  };

  return (
    <>
      <Modal isOpen={newModal} toggle={toggleAddModal} className="modal-md new_modal" centered>
        <ModalHeader toggle={toggleAddModal} className="userTable_header">
          <ArgonTypography color="text" fontWeight="medium" variant="d2">
            Add / Edit Category
          </ArgonTypography>
        </ModalHeader>
        <form className="needs-validation new_form" id="form_details" onSubmit={handleSubmit}>
          <ModalBody>
            <div className="form-row seller_modal">
              <Row>
                <div style={{ marginTop: 0, marginBottom: "20px" }} className="select_con">
                  <label htmlFor="prdocut_status">
                    <ArgonTypography color="text" fontWeight="medium" variant="caption">
                      Status
                    </ArgonTypography>
                  </label>
                  <select
                    id="prdocut_status"
                    className="pagination_select"
                    value={status}
                    onChange={handleStatusChange}
                  >
                    <option value="" disabled>
                      -- Select Status --
                    </option>
                    <option value="Enable">Enable</option>
                    <option value="Disable">Disable</option>
                  </select>
                </div>
                {categoryModalfields &&
                  categoryModalfields.map((categoryField) => {
                    return <InputFeild zoneField={categoryField} key={categoryField.id} />;
                  })}
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <ArgonButton color="success" size="medium" type="submit" className="">
              Save
            </ArgonButton>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default CategoryModal;
