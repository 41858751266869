import React, { useState } from "react";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import { apiInstance } from "../../../axios";
import { notify } from "../../../components/notification/Notification";
import Form_Modal from "../../../components/Modal/Form_Modal";

const AdminOrderModalRow = ({
  index,
  order,
  fetchOrderProductData,
  orderProductData,
  submitModalOpen,
  verifyConfirmModal,
  setSubmitModalOpen,
  handleInputChange,
  qtyinput,
  setQtyinput,
}) => {
  const handleVerify = (e) => {
    e.preventDefault();

    const verifyAllData = orderProductData.map((data, index) => ({
      ProductId: data.Id,
      Qty: qtyinput[index],
    }));

    apiInstance
      .post("/SaleOrder/ApprovedOrderProduct", verifyAllData)
      .then((response) => {
        if (response.data.Status === true) {
          console.log(response.data.Message);
          notify("Products have been successfully verified!", "success");
          fetchOrderProductData();
        } else {
          console.log("Verification failed: " + response.data.Message);
          notify("An Error occurred while verifying the Products", "error");
        }
      })
      .catch((error) => {
        console.error(error);
        notify("An error occurred while verifying the Products", "error");
      });
    setQtyinput([]);
    setSubmitModalOpen(false);
  };

  return (
    <tr key={order.Id} className="inward_modal_table">
      <td>
        <ArgonTypography color="dark" variant="caption">
          {index + 1}
        </ArgonTypography>
      </td>
      <td>
        <ArgonTypography color="dark" variant="caption">
          {order.Sku}
        </ArgonTypography>
      </td>
      <td>
        <ArgonTypography color="dark" variant="caption">
          {order.ProductName}
        </ArgonTypography>
      </td>
      <td>
        <ArgonTypography color="dark" variant="caption">
          {order.Qty}
        </ArgonTypography>
      </td>
      <td>
        <ArgonTypography color="dark" variant="caption">
          {order.Price}
        </ArgonTypography>
      </td>
      <td>
        <ArgonTypography color="dark" variant="caption">
          {order.Amount}
        </ArgonTypography>
      </td>
      <td className="inward_modal_input_td">
        {order.ApprovedQty > 0 ? (
          <ArgonTypography color="dark" variant="caption">
            {order.ApprovedQty}
          </ArgonTypography>
        ) : (
          <ArgonInput
            type="number"
            value={qtyinput[index] || ""}
            onChange={(e) => handleInputChange(e, index)}
          />
        )}
      </td>
      <Form_Modal
        handleVerify={handleVerify}
        deleteModalOpen={submitModalOpen}
        toggleDeleteModal={verifyConfirmModal}
        modalDesc="Are you sure about Approve?"
        callToAction="Approve"
      />
    </tr>
  );
};

export default AdminOrderModalRow;
