import { useState } from "react";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Notification, { notify } from "../../../components/notification/Notification";
import Loader from "../../../components/loader/Loader";
import { apiInstance } from "../../../axios/index";
import DownloadIcon from "@mui/icons-material/Download";

import { CardHeader, CardFooter, CardBody, Card, Container, Row } from "reactstrap";
import ArgonBox from "../../../components/ArgonBox";
import ArgonTypography from "../../../components/ArgonTypography";
import ArgonInput from "../../../components/ArgonInput";
import ArgonButton from "../../../components/ArgonButton";
import InputFeild from "../../../components/Modal/InputFeild";

const ImportFile = () => {
  const [importNumber, setImportNumber] = useState("");
  const [date, setDate] = useState("");
  const [note, setNote] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleFileDownload = () => {
    apiInstance
      .get("/ImportFormat/box.xlsx", {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "box.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error(error);
        notify("Failed to download the file", "error");
      });
  };

  // Save File Data
  const handleDataSubmission = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("upload", selectedFile);
    formData.append("importNumber", importNumber);
    formData.append("date", date);
    formData.append("description", note);

    apiInstance
      .post("/Inward/CartoonImport", formData)
      .then((response) => {
        const { status, Message } = response.data;
        if (status === true) {
          notify(Message, "success");
        } else {
          console.error(Message);
          notify(Message, "error");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        notify(error, "error");
      });
  };

  const inwardModalfields = [
    {
      id: "importFile_Number",
      label: "Import Number",
      type: "text",
      value: importNumber,
      onChange: (e) => setImportNumber(e.target.value),
      required: true,
    },
    {
      id: "importfile_date",
      label: "Date",
      type: "date",
      value: date,
      onChange: (e) => setDate(e.target.value),
      required: true,
    },
    {
      id: "importfile_note",
      label: "Description",
      type: "text",
      value: note,
      onChange: (e) => setNote(e.target.value),
      required: true,
    },
  ];

  return (
    <>
      <Notification />
      {loading && <Loader />}
      <DashboardLayout>
        <DashboardNavbar />
        <Container className="mt--7 con importFile_con" fluid>
          <Card className="shadow">
            <CardHeader>
              <ArgonBox
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                py={3}
                px={0.5}
              >
                <ArgonTypography variant="subtitle2" color="secondary">
                  Import file from Excel file
                </ArgonTypography>
                <ArgonButton
                  color="info"
                  size="medium"
                  type="button"
                  onClick={handleFileDownload}
                  className="download_file_btn"
                >
                  <DownloadIcon style={{ marginRight: "10px" }} /> Download Import Format
                </ArgonButton>
              </ArgonBox>
            </CardHeader>
            <form
              className="needs-validation new_form"
              id="form_details"
              onSubmit={handleDataSubmission}
            >
              <CardBody>
                <div className="form-row seller_modal">
                  <Row>
                    {inwardModalfields &&
                      inwardModalfields.map((inwardField) => {
                        return <InputFeild inwardField={inwardField} key={inwardField.id} />;
                      })}
                    <div className="col-md-6">
                      <ArgonBox className="">
                        <label htmlFor="importfile_file">
                          <ArgonTypography color="text" fontWeight="medium" variant="caption">
                            Import File
                          </ArgonTypography>
                        </label>
                        <div className="inportfile_inputFile">
                          <ArgonInput
                            type="file"
                            size="small"
                            id="importfile_file"
                            onChange={handleFileChange}
                            required
                          />
                        </div>
                      </ArgonBox>
                    </div>
                  </Row>
                </div>
              </CardBody>
              <CardFooter className="card-footer">
                <ArgonButton color="success" size="medium" type="submit" className="import_button">
                  Save Import
                </ArgonButton>
              </CardFooter>
            </form>
          </Card>
        </Container>
      </DashboardLayout>
    </>
  );
};

export default ImportFile;
