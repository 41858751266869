import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import NewPagination from "../../../components/Pagination/NewPagination";
import Loader from "../../../components/loader/Loader";
import { apiInstance } from "../../../axios/index";
import Notification, { notify } from "../../../components/notification/Notification";
import ArgonTypography from "../../../components/ArgonTypography";
import TableHead from "../../../components/Table/TableHead";
import Form_Modal from "../../../components/Modal/Form_Modal";

import { Card, Container, CardBody, CardFooter, Table } from "reactstrap";
import CardHead from "../../../components/Card/CardHead";
import { useNavigate } from "react-router-dom";
import { IoMdTrash, IoMdEye } from "react-icons/io";
import { RiFileExcel2Line } from "react-icons/ri";
import EntryModal from "./EntryModal";

const tableHeadData = [
  {
    id: 1,
    header: "No.",
  },
  {
    id: 2,
    header: "Warehouse Name",
  },
  {
    id: 3,
    header: "Shipment No.",
  },
  {
    id: 4,
    header: "Order Date",
  },
  {
    id: 5,
    header: "Created By",
  },
  {
    id: 6,
    header: "",
  },
];

const Entry = () => {
  const [entryData, setEntryData] = useState([]);
  const [entryModalData, setEntryModalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedModalId, setSelectedModalId] = useState(0);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchEntryData();
  }, []);

  const navigate = useNavigate();

  // search functionality
  const handleSearch = (e) => {
    const searchString = e.target.value.toLowerCase();
    const searchResults = entryData.filter(
      ({ WarehouseName, OrderDate, CreatedByName, ShipmentNumber }) =>
        (WarehouseName ?? "").toLowerCase().includes(searchString) ||
        (OrderDate ?? "").includes(searchString) ||
        (CreatedByName ?? "").toLowerCase().includes(searchString) ||
        (ShipmentNumber ?? "").toLowerCase().includes(searchString)
    );
    setFilterData(searchResults);
    setCurrentPage(1);
  };

  const toggleModal = (Id) => {
    setSelectedModalId(Id);
    setModal(!modal);
  };

  const toggleDeleteModal = (Id) => {
    setDeleteModalOpen(!deleteModalOpen);
    Id && setDeleteItemId(Id);
  };

  // Fetch entry Data
  const fetchEntryData = async () => {
    setLoading(true);
    try {
      const response = await apiInstance.post("/Warehouse/GetWarehouseEntry");

      if (response.data.status === true) {
        const entryData = response.data.Data.map((entry) => ({
          Id: entry.Id,
          WarehouseName: entry.WarehouseName,
          OrderDate: entry.OrderDate,
          CreatedByName: entry.CreatedByName,
          Status: entry.Status,
          ShipmentNumber: entry.ShipmentNumber,
        }));
        setFilterData(entryData);
        setEntryData(entryData);
      } else {
        console.error("Error: " + response.data.Message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notify("Your session just expired!", "error");
        setTimeout(() => {
          navigate("/authentication/logout");
        }, 5000);
      } else {
        console.error(error);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchEntryModalData();
  }, [selectedModalId]);

  // Fetch entry modal data
  const fetchEntryModalData = async () => {
    setLoading(true);
    try {
      const response = await apiInstance.post("/Warehouse/GetProduct", { id: selectedModalId });

      if (response.data.status === true) {
        const entryData = response.data.Data.map((entry) => ({
          Id: entry.Id,
          ProductId: entry.ProductId,
          Sku: entry.Sku,
          Qty: entry.Qty,
          WarehouseName: entry.WarehouseName,
          Amount: entry.Amount,
          Color: entry.Color,
        }));
        setEntryModalData(entryData);
      } else {
        console.error("Error: " + response.data.Message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        notify("Your session just expired!", "error");
        setTimeout(() => {
          navigate("/authentication/logout");
        }, 5000);
      } else {
        console.error(error);
      }
    }
    setLoading(false);
  };

  // deleting a data
  const handleDelete = async (e) => {
    e.preventDefault();
    const entryId = deleteItemId;
    await apiInstance
      .post("/Warehouse/Delete", { id: entryId })
      .then((response) => {
        if (response.data.Status === true) {
          notify("Entry deleted successfully", "success");
        } else {
          notify("Failed to delete Entry data", "error");
        }
      })
      .catch((error) => {
        notify("Failed to delete Entry data", "error");
        console.error("Error occurred while deleting Entry data:", error);
      });
    setDeleteModalOpen(!deleteModalOpen);
    fetchEntryData();
    setDeleteItemId(null);
  };

  const handleExportClick = (Id) => {
    const baseApiUrl = apiInstance.defaults.baseURL;
    const exportPath = `Warehouse/WarehouseStockExport?stockId=${Id}`;

    const fullUrl = `${baseApiUrl}${exportPath}`;

    window.location.href = fullUrl;
  };

  // for Pagination
  const totalItems = filterData ? filterData.length : 0;

  const startIndex = (currentPage - 1) * itemsPerPage + 1;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems);
  const entriesFilterData = filterData.slice(startIndex - 1, endIndex);

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(parseInt(value));
    setCurrentPage(1);
  };

  return (
    <>
      <Notification />
      {loading && <Loader />}
      <DashboardLayout>
        <DashboardNavbar />
        <Container className="mt--7 inward_list_con con" fluid>
          <Card className="shadow">
            <CardHead handleSearch={handleSearch} cardHeader="Warehouse Entry" />
            <CardBody className="table_card_body">
              <Table className="table-flush new_table" responsive hover>
                <TableHead tableHeadData={tableHeadData} />
                <tbody>
                  {entriesFilterData?.map((entry, index) => {
                    const tableIndex = (currentPage - 1) * itemsPerPage + index;
                    return (
                      <tr key={entry.Id}>
                        <td>
                          <ArgonTypography color="dark" variant="caption">
                            {tableIndex + 1}
                          </ArgonTypography>
                        </td>
                        <td>
                          <ArgonTypography color="dark" variant="caption">
                            {entry.WarehouseName}
                          </ArgonTypography>
                        </td>
                        <td>
                          <ArgonTypography color="dark" variant="caption">
                            {entry.ShipmentNumber}
                          </ArgonTypography>
                        </td>
                        <td>
                          <ArgonTypography color="dark" variant="caption">
                            {entry.OrderDate}
                          </ArgonTypography>
                        </td>
                        <td>
                          <ArgonTypography color="dark" variant="caption">
                            {entry.CreatedByName}
                          </ArgonTypography>
                        </td>
                        <td className="inward_verify_btn">
                          <button onClick={() => toggleModal(entry.Id)} className="btn delete_btn">
                            <IoMdEye size="1.5rem" />
                          </button>
                          <button className="btn delete_btn">
                            <IoMdTrash
                              onClick={() => toggleDeleteModal(entry.Id)}
                              color="red"
                              size="1.4rem"
                            />
                          </button>
                          <button
                            onClick={() => handleExportClick(entry.Id)}
                            className="btn delete_btn"
                          >
                            <RiFileExcel2Line size="1.5rem" color="green" />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </CardBody>
            <CardFooter>
              <NewPagination
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                handleItemsPerPageChange={handleItemsPerPageChange}
                itemsPerPageLabel="Entries Per Page:"
              />
            </CardFooter>
          </Card>
        </Container>
      </DashboardLayout>
      {deleteModalOpen && (
        <Form_Modal
          handleDelete={handleDelete}
          deleteModalOpen={deleteModalOpen}
          toggleDeleteModal={toggleDeleteModal}
          modalDesc="Are you sure you want to Delete?"
          callToAction="Delete"
        />
      )}
      {modal && (
        <EntryModal modal={modal} toggleModal={toggleModal} entryModalData={entryModalData} />
      )}
    </>
  );
};

export default Entry;
