import { useState } from "react";
import ArgonBox from "../../../components/ArgonBox";
import ArgonTypography from "../../../components/ArgonTypography";
import ArgonBadge from "../../../components/ArgonBadge";
import { Checkbox } from "@mui/material";
import { FaPen } from "react-icons/fa6";
import { BsImages } from "react-icons/bs";
import Cookies from "js-cookie";
import InventoryEditModal from "./InventoryEditModal";
import { Modal, ModalBody } from "reactstrap";
import Slider from "../../../components/Swiper/Slider";

const WarehouseTableRow = ({
  index,
  tableProduct,
  handleOrderchange,
  checkvalues,
  fetchProducts,
}) => {
  const [editModal, setEditModal] = useState(false);
  const [damageQty, setdamageQty] = useState("");
  const [minQty, setMinQty] = useState("");
  const [modalId, setModalId] = useState("");
  const [sku, setSku] = useState("");
  const [name, setName] = useState("");
  const [color, setColor] = useState("");
  const [qty, setQty] = useState("");
  const [demageQty, setDemageQty] = useState("");
  const [minQtyAlert, setMinQtyAlert] = useState("");
  const [category, setCategory] = useState("");
  const [productImage, setProductImage] = useState("");
  const [imageModal, setImageModal] = useState(false);

  const isChecked = checkvalues.some((item) => item.Id === tableProduct.Id);
  const isQtyZero = tableProduct.Qty === 0;

  const userCookieType = Cookies.get("Type");
  const [userType] = useState(userCookieType);

  const handleInventoryEdit = (tableProduct) => {
    setEditModal(true);
    setSku(tableProduct.Sku);
    setModalId(tableProduct.Id);
    setName(tableProduct.Name);
    setColor(tableProduct.Color);
    setQty(tableProduct.Qty);
    setDemageQty(tableProduct.DemageQty);
    setMinQtyAlert(tableProduct.MinQtyAlert);
    setCategory(tableProduct.CategoryName);
  };

  // opening or closeing a modal
  const toggleEditModal = () => {
    setdamageQty("");
    setMinQty("");
    setEditModal(!editModal);
  };

  // image modal
  const imagePopUp = (tableProduct) => {
    setImageModal(tableProduct.Id);
    setProductImage(tableProduct.ImagePath);
    setImageModal(!imageModal);
  };

  return (
    <>
      <tr className={`${isChecked ? "rowSelected" : ""} ${isQtyZero ? "rowOpacity" : ""}`}>
        <td style={{ width: "70px" }}>
          <Checkbox
            label="Outlined"
            variant="outlined"
            value={tableProduct.Id}
            checked={isChecked}
            disabled={isQtyZero}
            onChange={(e) =>
              handleOrderchange(
                tableProduct.Id,
                tableProduct.Sku,
                tableProduct.Name,
                tableProduct.Qty,
                tableProduct.Mrp,
                e.target.checked
              )
            }
          />
        </td>
        <td>
          <ArgonTypography variant="caption" color="dark">
            {index + 1}
          </ArgonTypography>
        </td>
        <td>
          <ArgonTypography variant="caption" color="dark">
            {tableProduct.Sku}
          </ArgonTypography>
        </td>
        <td>
          <ArgonTypography variant="caption" color="dark">
            {tableProduct.Name}
          </ArgonTypography>
        </td>
        <td>
          <ArgonTypography variant="caption" color="dark">
            {tableProduct.Color}
          </ArgonTypography>
        </td>
        <td>
          <ArgonTypography variant="button" color="info" fontWeight="bold" textGradient>
            {tableProduct.CategoryName}
          </ArgonTypography>
        </td>
        <td>
          <ArgonTypography variant="caption" color="dark">
            {tableProduct.Qty}
          </ArgonTypography>
        </td>
        <td>
          <ArgonTypography variant="caption" color="dark">
            {tableProduct.DemageQty}
          </ArgonTypography>
        </td>
        <td>
          <ArgonTypography variant="caption" color="dark">
            {tableProduct.MinQtyAlert}
          </ArgonTypography>
        </td>
        <td>
          <ArgonTypography variant="caption" color="dark">
            {tableProduct.Mrp}
          </ArgonTypography>
        </td>
        <td>
          <ArgonBox className="order_badge">
            {tableProduct.Status === "Enable" && (
              <ArgonBadge
                variant="gradient"
                badgeContent="Enable"
                color="success"
                size="xs"
                container
              />
            )}
            {tableProduct.Status === "Disable" && (
              <ArgonBadge
                variant="gradient"
                badgeContent="Disable"
                color="error"
                size="xs"
                container
              />
            )}
          </ArgonBox>
        </td>
        <td>
          <button
            className="btn edit_btn"
            disabled={
              tableProduct.ImagePath === "" ||
              tableProduct.ImagePath === null ||
              tableProduct.ImagePath === undefined
            }
          >
            <BsImages size="1.3rem" color="purple" onClick={() => imagePopUp(tableProduct)} />
          </button>
          {userType === "warehouse" && (
            <button className="btn edit_btn">
              <FaPen onClick={() => handleInventoryEdit(tableProduct)} size="1rem" color="green" />
            </button>
          )}
        </td>
      </tr>
      {editModal && (
        <InventoryEditModal
          editModal={editModal}
          toggleEditModal={toggleEditModal}
          damageQty={damageQty}
          setdamageQty={setdamageQty}
          minQty={minQty}
          setMinQty={setMinQty}
          sku={sku}
          name={name}
          color={color}
          qty={qty}
          demageQty={demageQty}
          minQtyAlert={minQtyAlert}
          category={category}
          modalId={modalId}
          fetchProducts={fetchProducts}
        />
      )}
      {imageModal && (
        <Modal
          isOpen={imageModal}
          toggle={imagePopUp}
          className="image_modal"
          centered
          backdrop
          fade
        >
          <ModalBody>
            <Slider productImage={productImage} />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default WarehouseTableRow;
