// reactstrap components
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import ArgonButton from "../../components/ArgonButton";
import ArgonTypography from "components/ArgonTypography";

const Form_Modal = ({
  toggleDeleteModal,
  deleteModalOpen,
  handleDelete,
  modalDesc,
  callToAction,
  handleVerify,
  handleLogout,
  handleApproveOrder,
  handlePendingOrder,
  handleDeleteOrder,
  orderId,
}) => {
  return (
    <>
      <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal} className="delete_modal" centered>
        <ModalHeader toggle={toggleDeleteModal}>
          <ArgonTypography color="text" fontWeight="medium" variant="d3">
            Confirmation
          </ArgonTypography>
        </ModalHeader>
        <ModalBody>
          <ArgonTypography color="text" fontWeight="medium" variant="button">
            {modalDesc}
          </ArgonTypography>
        </ModalBody>
        <ModalFooter>
          <ArgonButton color="info" size="medium" type="button" onClick={toggleDeleteModal}>
            Cancel
          </ArgonButton>
          {handleDelete && (
            <ArgonButton
              color="error"
              size="medium"
              type="button"
              onClick={handleDelete}
              className="logout_btn"
            >
              {callToAction}
            </ArgonButton>
          )}
          {handleVerify && (
            <ArgonButton
              color="success"
              size="medium"
              type="button"
              onClick={handleVerify}
              className="logout_btn"
            >
              {callToAction}
            </ArgonButton>
          )}
          {handleLogout && (
            <ArgonButton
              color="error"
              size="medium"
              type="button"
              onClick={handleLogout}
              className="logout_btn"
            >
              {callToAction}
            </ArgonButton>
          )}
          {handleApproveOrder && (
            <ArgonButton
              color="success"
              size="medium"
              type="button"
              onClick={() => handleApproveOrder(orderId)}
              className="logout_btn"
            >
              Complete
            </ArgonButton>
          )}
          {handlePendingOrder && (
            <ArgonButton
              color="warning"
              size="medium"
              type="button"
              onClick={() => handlePendingOrder(orderId)}
              className="logout_btn"
            >
              Pending
            </ArgonButton>
          )}
          {handleDeleteOrder && (
            <ArgonButton
              color="error"
              size="medium"
              type="button"
              onClick={() => handleDeleteOrder(orderId)}
              className="logout_btn"
            >
              Cancel
            </ArgonButton>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Form_Modal;
