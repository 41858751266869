import React from "react";

import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

const TableHead = ({ tableHeadData }) => {
  return (
    <thead className="thead-light">
      <tr className="table_header_con">
        {tableHeadData.map((head) => {
          return (
            <th key={head.id}>
              <ArgonBox>
                <ArgonTypography color="dark" variant="button" fontWeight="bold" className="table_head_text">
                  {head.header}
                </ArgonTypography>
              </ArgonBox>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHead;
